<template>
<div class="assets-content">
  <Search></Search>
  <div class="device-content" v-loading="loading">
    <device-left :assetsData="assetsData" v-if="!loading"></device-left>
    <device-right :assetsData="assetsData" :class="loading ? 'device-set' : ''"></device-right>
  </div>
</div>
</template>

<script>
import Search from './Search'
import DeviceLeft from './DeviceLeft'
import DeviceRight from './DeviceRight'
import Bus from '@/common/vueBus'

export default {
  name: 'Index',
  components: {
    Search,
    DeviceLeft,
    DeviceRight
  },
  data () {
    return {
      assetsData: [],
      loading: false
    }
  },
  created () {
    Bus.$on('assetsSearch', val => {
      this.getAssetsData(val)
    })
  },
  methods: {
    getAssetsData (val) {
      // const url = `/rental-forecasting/asset/statistic?assetOwner=${val.assetOwner}&type=${val.type}&rentalStatus=${val.rentalStatus}&ticketId=${val.ticketId}&peerId=${val.peerId}&withTicket=${val.withTicket}&startDate=${val.startDate}&endDate=${val.endDate}`
      const params = {
        assetOwner: val.assetOwner,
        type: val.type,
        rentalStatusList: val.rentalStatus,
        ticketId: val.ticketId,
        peerId: val.peerId,
        withTicket: val.withTicket,
        startDate: val.startDate,
        endDate: val.endDate
      }
      this.loading = true
      this.$axios
        .post('/rental-forecasting/asset/statistic', params)
        .then(res => {
          if (res.data.errorInfo && res.data.errorInfo.includes('权限不足')) {
            this.$message.error(res.data.errorInfo)
            return
          }
          this.assetsData = res.data
          this.loading = false
          Bus.$emit('updateView')
        })
    }
  }
}
</script>

<style scoped lang="less">
.assets-content {
  width: 100%;
  height: calc(100vh - 64px);
  overflow: hidden;
}

.device-content {
  min-height: calc(100vh - 120px);
  display: flex;
}

.device-set {
  margin-left: 324px;
}
</style>
