<!-- 头部时间 -->
<template>
  <div class="HeaderTime">
    <div id="clock-container">
      <div
        class="display-container"
        v-for="(item, index) in 6"
        :key="index"
        :class="[`display-container${index}`,`display-no-${TimeClass[index]}`]">
        <div class="segment-b segment-1"></div>
        <div class="segment-l segment-2"></div>
        <div class="segment-l segment-3"></div>
        <div class="segment-t segment-4"></div>
        <div class="segment-r segment-5"></div>
        <div class="segment-r segment-6"></div>
        <div class="segment-h segment-7"><span class="segment-border"></span></div>
      </div>
      <div class="colon"></div>
      <div class="colon colon1"></div>
    </div>
    <div class="FormatTimeZone">
      {{this.Format}} {{this.timeZone}}
    </div>
  </div>
</template>

<script>
// import Outil from '@/assets/js/utils.js'
export default {
  data () {
    return {
      // 时间类名
      TimeClass: [0, 0, 0, 0, 0, 0, 0],
      // AM,PM
      Format: '',
      // 时区
      timeZone: '',
      // 定时器
      getCurrentTime: null
    }
  },
  created () {

  },
  mounted () {
    this.setdisplays()
    if (this.getCurrentTime) {
      clearInterval(this.getCurrentTime)
    }
    this.getCurrentTime = setInterval(this.setdisplays, 100)
  },
  methods: {
    // 设置每个显示数字几
    setdisplays () {
      const d = new Date()
      const time = this.transitionHourly(d)
      const TimeClass = time.split('')
      this.TimeClass = TimeClass
    },
    // 24小时制转12小时制
    transitionHourly (time) {
      if (time) {
        const type = ''
        let hour = time.getHours()
        let min = time.getMinutes()
        let s = time.getSeconds()
        // const TimeFormat = Outil.getDateTimeFormat()
        // if (!TimeFormat.broadcastTimeFormat) {
        //   if (hour >= 0 && hour <= 11) {
        //     type = 'AM'
        //     hour = hour == 0 ? 12 : hour
        //   } else {
        //     type = 'PM'
        //     hour = hour == 12 ? hour : hour - 12
        //   }
        // }
        hour = hour >= 0 && hour < 10 ? `0${hour}` : hour
        min = min >= 0 && min < 10 ? `0${min}` : min
        s = s >= 0 && s < 10 ? `0${s}` : s
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
        this.Format = type
        this.timeZone = timeZone
        return `${hour}${min}${s}`
      }
    }
  },
  beforeDestroy () {
    clearInterval(this.getCurrentTime)
  }
}
</script>

<style scoped lang="less">
.flex-column-end {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.HeaderTime{
  display: flex;
  align-items: center;
  #clock-container{
    display: flex;
    align-items: center;
    position: relative;
  }
  .FormatTimeZone{
    color: #8C8C8C;
    font-size: 13px;
  }
  .colon {
    position: absolute;
    left: 36px;
    top: 0px;
  }
  .colon1 {
    left: 80px;
  }
  .colon::after{
    content: '';
    width: 4px;
    height: 4px;
    background: #D39F39;
    position: absolute;
    top: 6px;
    left: 0px;
  }
  .colon::before{
    content: '';
    width: 4px;
    height: 4px;
    background: #D39F39;
    position: absolute;
    top: 16px;
    left: 0px;
  }
  .display-container {
    position: relative;
    margin-right: 2px;
    width: 16px;
    height: 26px;
    div {
      position: absolute;
    }
    .segment-b,.segment-border {
      width: 11px;
      border-top: 3px solid #D39F39;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
    }
    .segment-t,.segment-h {
      width: 11px;
      border-bottom: 3px solid #D39F39;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
    }
    .segment-l {
      height: 11px;
      width: 0;
      border-right: 3px solid #D39F39;
      border-top: 3px solid transparent;
      border-bottom: 3px solid transparent;
    }
    .segment-r {
      height: 11px;
      width: 0;
      border-left: 3px solid #D39F39;
      border-top: 3px solid transparent;
      border-bottom: 3px solid transparent;
    }
    .segment-h{
      width: 9px;
      border-bottom: 2px solid #D39F39;
    }
    .segment-border {
      width: 9px;
      top: 2px;
      left: -3px;
      border-top: 2px solid #D39F39;
    }
    .segment-1 {
      top: 0;
      left: 1px;
    }
    .segment-2 {
      top: 1px;
      left: 10px;
    }
    .segment-3 {
      top: 14px;
      left: 10px;
    }
    .segment-4 {
      top: 23px;
      left: 1px;
    }
    .segment-5 {
      top: 14px;
      left: 0px;
    }
    .segment-6 {
      top: 2px;
      left: 0px;
    }
    .segment-7 {
      top: 11px;
      left: 2px;
    }
    .segment-x,.segment-y {
      position: absolute;
    }
    .segment-border {
      display: block;
      position: absolute;
    }
  }
  .display-container1 {
    margin-right: 10px;
  }
  .display-container3 {
    margin-right: 10px;
  }
  .display-no-1 .segment-1,
  .display-no-1 .segment-4,
  .display-no-1 .segment-5,
  .display-no-1 .segment-6,
  .display-no-1 .segment-7 {
    display: none;
    //opacity: 0.1;
    filter: alpha(opacity=10);
  }
  .display-no-2 .segment-3,
  .display-no-2 .segment-6 {
    display: none;
    //opacity: 0.1;
  }
  .display-no-3 .segment-5,
  .display-no-3 .segment-6 {
    display: none;
    //opacity: 0.1;
  }
  .display-no-4 .segment-1,
  .display-no-4 .segment-4,
  .display-no-4 .segment-5 {
    display: none;
    //opacity: 0.1;
  }
  .display-no-5 .segment-2,
  .display-no-5 .segment-5 {
    display: none;
    //opacity: 0.1;
  }
  .display-no-6 .segment-2 {
    display: none;
    //opacity: 0.1;
  }
  .display-no-7 .segment-4,
  .display-no-7 .segment-5,
  .display-no-7 .segment-6,
  .display-no-7 .segment-7 {
    display: none;
    //opacity: 0.1;
  }
  .display-no-8 {}

  .display-no-9 .segment-5 {
    display: none;
    //opacity: 0.1;
  }
  .display-no-0 .segment-7 {
    display: none;
    //opacity: 0.1;
  }
  #display-2, #display-4 {
    margin-right: 60px;
  }
}
</style>
