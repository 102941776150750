<template>
<div class="deal-stage">
  <div
    v-for="(item, index) in dealStageColorList"
    :key="index">
    <span class="deal-icon" :style="{ background: item.color}"></span>
    <span class="deal-name">{{item.name}}</span>
    <el-popover
        placement="bottom"
        width="450"
        @show="showTips(item.name)"
        trigger="hover">
      <div class="stage-box">
        <div class="name">{{statusObject.name}}</div>
        <div class="title">{{statusObject.title}}</div>
        <div v-if="statusObject.subTitle" class="sub-title">
          <b>Historical: </b>
          <span>{{statusObject.subTitle}}</span>
        </div>
        <div v-if="statusObject.threeTitle" class="sub-title">
          <b>Forecasting: </b>
          <span>{{statusObject.threeTitle}}</span>
        </div>
        <div v-if="statusObject.statusList" class="status-item">
          <div v-for="(item, index) in statusObject.statusList" :key="index">{{item}}</div>
        </div>
      </div>
      <i slot="reference" class="el-icon-warning-outline icon-tips"></i>
    </el-popover>
  </div>
</div>
</template>

<script>
export default {
  name: 'dealStageColor',
  data () {
    return {
      dealStageColorList: [],
      statusText: [
        {
          name: 'RED Total',
          title: 'Total Asset count of the RED Pool'
        },
        {
          name: 'Available',
          title: 'Assets confirmed to be available',
          subTitle: 'When Rental Asset Status equal to “Available”.',
          threeTitle: 'RED Total minus Assets committed or declared in the following deal stages.',
          statusList: [
            'Qualification',
            'Scope and Pricing',
            'Discovery',
            'Negotiating',
            'Selected / Reaching Agreement',
            'Closed Won',
            'Evaluation Confirmed'
          ]
        },
        {
          name: 'Likely Available',
          title: 'Assets that have a decent chance of being unavailable',
          subTitle: 'When Rental Asset Status equal to “QC Pending”.',
          threeTitle: 'Assets declared in the following deal stages',
          statusList: [
            'Qualification',
            'Scope and Pricing',
            'Discovery',
            'Negotiating',
            'Selected / Reaching Agreement'
          ]
        },
        {
          name: 'Likely Unavailable',
          title: 'Assets that have a high chance of being unavailable',
          subTitle: 'When Rental Asset Status equal to “QC Failed”.',
          threeTitle: 'Assets declared in the following deal stages',
          statusList: [
            'Closed Won, but not yet executed',
            'Evaluation Confirmed'
          ]
        },
        {
          name: 'Unavailable',
          title: 'Assets confirmed to be unavailable',
          subTitle: 'When Rental Asset Status equal to “committed” or “in rental” or “rental over”.',
          threeTitle: 'Assets declared in the following deal stages',
          statusList: [
            'Closed Won, and being executed'
          ]
        },
        {
          name: 'Unknown',
          title: 'Assets with unknown statistics. Data has not been properly entered yet.'
        }
      ],
      statusObject: {}
    }
  },
  created () {
    const dealStageColor = localStorage.getItem('dealStageColorMap') && JSON.parse(localStorage.getItem('dealStageColorMap'))
    this.dealStageColorList = Object.keys(dealStageColor).map(item => {
      return {
        color: dealStageColor[item],
        name: item
      }
    })
  },
  methods: {
    showTips (name) {
      this.statusObject = this.statusText.find(item => item.name === name)
    }
  }
}
</script>

<style scoped lang="less">
.deal-stage {
  position: absolute;
  display: flex;
  padding-left: 12px;
  padding-top: 26px;
  div {
    display: flex;
    align-items: center;
    margin-right: 40px;
  }
  .deal-icon {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 6px;
  }
  .deal-name {
    color: var(--gray-f-0-f-0-f-0, #F0F0F0);
    font-size: 14px;
  }
  .icon-tips {
    color: #999999;
    font-size: 16px;
    margin-left: 4px;
    margin-top: 3px;
    cursor: pointer;
  }
  .icon-tips:hover {
    color: #44A4FD;
  }
}
.stage-box {
  //font-size: 12px;
  color: #444444;
  .name {
    font-weight: 700;
    font-size: 16px;
    color: #333333;
    margin-bottom: 12px;
  }
  .title {
    margin-bottom: 10px;
    word-break: keep-all;
  }
  .sub-title {
    margin-bottom: 4px;
    b::before {
      content: "• ";
    }
    span {
      word-break: keep-all;
    }
  }
  .status-item {
    div {
      margin-bottom: 2px;
      margin-left: 10px;
    }
    div::before {
      content: "• ";
    }
  }
}
</style>
