<template>
<div class="choose-products" ref="pdfProducts">
  <div class="over-h">
    <div class="products" :class="{'active-border': checkedProduct1}" style="margin-right: 32px;" @click="checkedProductItem(1)">
      <div @click.prevent="numberChange" style="height: 18px;">
        <el-checkbox v-model="checkedProduct1"></el-checkbox>
      </div>
      <img src="../../assets/images/TVUPack1.png" alt="" class="products-img">
      <div class="products-info">
        <p>TVU Pack, 1 Input</p>
        <div @click.stop="numberChange">
          <el-input-number v-model="chooseNumber1" :min="1"></el-input-number>
        </div>
      </div>
    </div>
    <div class="products" :class="{'active-border': checkedProduct2}" @click="checkedProductItem(2)">
      <div @click.prevent="numberChange" style="height: 18px;">
        <el-checkbox v-model="checkedProduct2"></el-checkbox>
      </div>
      <img src="../../assets/images/TVUPack2.png" alt="" class="products-img">
      <div class="products-info">
        <p>TVU Pack, 4 Input</p>
        <div @click.stop="numberChange">
          <el-input-number v-model="chooseNumber2" :min="1"></el-input-number>
        </div>
      </div>
    </div>
  </div>
  <div class="over-h require-info">
    <div class="products" :class="{'active-border': checkedProduct3}" style="margin-right: 32px;" @click="checkedProductItem(3)">
      <div @click.prevent="numberChange" style="height: 18px;">
        <el-checkbox v-model="checkedProduct3"></el-checkbox>
      </div>
      <img src="../../assets/images/receiver.png" alt="" class="products-img" style="margin-bottom: 39px;">
      <div class="products-info">
        <p>TVU Receiver, 2 Channels</p>
        <div @click.stop="numberChange">
          <el-input-number v-model="chooseNumber3" :min="1"></el-input-number>
        </div>
      </div>
    </div>
    <div class="products" :class="{'active-border': checkedRequire}" @click="checkedRequire = !checkedRequire">
      <div @click.prevent="numberChange" style="height: 18px;">
        <el-checkbox v-model="checkedRequire"></el-checkbox>
      </div>
      <img src="../../assets/images/new-logo.png" width="64" alt="" class="products-img" style="height: 64px;">
      <div class="products-info">
        <p class="bottom_12">Additional Product Requirements</p>
        <div @click.stop="numberChange">
          <el-input type="textarea" :rows="4" v-model="specialRentalProductRequest" placeholder="Enter your additional product requirements"></el-input>
        </div>
      </div>
    </div>
  </div>
  <div class="text_right">
    <el-button type="primary" @click="onNext" :disabled="!checkedProduct1 && !checkedProduct2 && !checkedProduct3 && !checkedRequire">Next</el-button>
  </div>
</div>
</template>

<script>
import Bus from '../../common/vueBus'
import { getUrlParam } from '@/assets/js/utils'

export default {
  name: 'ChooseProducts',
  data () {
    return {
      checkedProduct1: false,
      checkedProduct2: false,
      checkedProduct3: false,
      checkedRequire: false,
      chooseNumber1: 1,
      chooseNumber2: 1,
      chooseNumber3: 1,
      specialRentalProductRequest: ''
    }
  },
  mounted () {
    let chooseProducts = {}
    if (this.$route.path === '/print') {
      chooseProducts = JSON.parse(getUrlParam('chooseProducts') || '{}')
    } else {
      chooseProducts = JSON.parse(localStorage.getItem('chooseProducts') || '{}')
    }
    const {
      checkedProduct1,
      checkedProduct2,
      checkedProduct3,
      checkedRequire,
      chooseNumber1,
      chooseNumber2,
      chooseNumber3,
      specialRentalProductRequest
    } = chooseProducts
    this.checkedProduct1 = checkedProduct1 || this.checkedProduct1
    this.checkedProduct2 = checkedProduct2 || this.checkedProduct2
    this.checkedProduct3 = checkedProduct3 || this.checkedProduct3
    this.checkedRequire = checkedRequire || this.checkedRequire
    this.chooseNumber1 = chooseNumber1 || this.chooseNumber1
    this.chooseNumber2 = chooseNumber2 || this.chooseNumber2
    this.chooseNumber3 = chooseNumber3 || this.chooseNumber3
    this.specialRentalProductRequest = specialRentalProductRequest || this.specialRentalProductRequest
  },
  methods: {
    numberChange () {
      this.setProducts()
      Bus.$emit('cartNumChange')
    },
    checkedProductItem (num) {
      const propName = `checkedProduct${num}`
      // eslint-disable-next-line no-prototype-builtins
      if (this.hasOwnProperty(propName)) {
        this[propName] = !this[propName]
      }
      this.setProducts()
      Bus.$emit('cartNumChange')
    },
    setProducts () {
      const chooseProducts = {
        checkedProduct1: this.checkedProduct1,
        checkedProduct2: this.checkedProduct2,
        checkedProduct3: this.checkedProduct3,
        checkedRequire: this.checkedRequire,
        chooseNumber1: this.chooseNumber1,
        chooseNumber2: this.chooseNumber2,
        chooseNumber3: this.chooseNumber3,
        specialRentalProductRequest: this.specialRentalProductRequest
      }
      localStorage.setItem('chooseProducts', JSON.stringify(chooseProducts))
    },
    onNext () {
      this.setProducts()
      this.$emit('productNext')
    }
  }
}
</script>

<style scoped lang="less">
.choose-products {
  //overflow: hidden;
  .products {
    width: 545px;
    float: left;
    display: flex;
    padding: 32px 24px;
    border-radius: 4px;
    border: 1px solid #DBDBDB;
    background: #FAFAFA;
    cursor: pointer;
  }

  .products-img {
    margin-left: 24px;
    margin-right: 20px;
  }

  .products-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    p {
      color: #333;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .require-info {
    margin-top: 32px;
    margin-bottom: 36px;
  }

  /deep/.el-input.is-disabled, .el-input__inner {
    background-color: #FAFAFA;
  }

  /deep/.el-input .el-input__inner {
    background-color: #FAFAFA;
    border: 1px solid #C6C6C6;
    color: #333333;
  }

  /deep/.el-input-number {
    .el-input-number__increase, .el-input-number__decrease {
      background-color: #FAFAFA!important;
    }
  }

  /deep/.el-textarea {
    width: 378px;

    .el-textarea__inner {
      background: #FAFAFA;
      border-color: #DBDBDB;
      font-size: 14px;
    }

    .el-textarea__inner::placeholder {
      color: #DBDBDB;
    }
  }

  .active-border {
    border: 1px solid #69AD48;
  }
}
</style>
