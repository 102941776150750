<template>
<div class="search">
  <div>
    <el-select
        clearable
        v-model="service"
        size="small"
        filterable
        @change="searchData"
        class="m_r_15">
      <el-option
          v-for="(item, index) in serviceOptions"
          :key="index"
          :label="item"
          :value="item">
      </el-option>
    </el-select>
    <el-select
        style="width: 275px;"
        clearable
        multiple
        collapse-tags
        v-model="status"
        placeholder="Asset Status"
        size="small"
        filterable
        @change="searchData"
        class="m_r_15">
      <el-option
          v-for="(item, index) in statusOptions"
          :key="index"
          :label="item"
          :value="item">
      </el-option>
    </el-select>
    <el-select
        clearable
        v-model="type"
        size="small"
        placeholder="Device Type"
        @change="searchData"
        class="m_r_15">
      <el-option
          v-for="(item, index) in typeList"
          :key="index"
          :label="item"
          :value="item">
      </el-option>
    </el-select>
    <el-input
        clearable
        v-model="searchValue"
        size="small"
        style="width: 260px;"
        class="m_r_15 search-input"
        @change="searchFilter"
        suffix-icon="el-icon-search"
        placeholder="Search by Device or Ticket ID">
    </el-input>
  </div>
  <div class="setting" v-if="false">
    <i class="el-icon-setting"></i>
    Manage Status Group
  </div>
</div>
</template>

<script>
import Bus from '@/common/vueBus'
const moment = require('moment')
export default {
  name: 'Search',
  data () {
    return {
      service: '',
      serviceOptions: [],
      type: '',
      typeList: ['T', 'R'],
      status: [],
      statusOptions: [],
      device: '',
      ticketId: '',
      searchTicket: '',
      withTicket: '0',
      searchValue: '',
      startDate: moment().startOf('week').format('YYYY-MM-DD'),
      endDate: moment().endOf('week').format('YYYY-MM-DD')
    }
  },
  created () {
    this.getAllOwner()
    Bus.$on('changesSearch', val => {
      this.ticketId = val.ticketId
      this.searchData()
    })
    Bus.$on('ticketChange', val => {
      this.withTicket = val
      this.searchData()
    })
    Bus.$on('dateChange', val => {
      this.startDate = val.startDate
      this.endDate = val.endDate
      this.searchData()
    })
  },
  methods: {
    isNumeric (input) {
      const re = /^[0-9]+$/
      return re.test(input)
    },
    searchFilter () {
      const value = this.isNumeric(this.searchValue)
      if (value) {
        this.searchTicket = this.searchValue
        this.device = ''
      } else {
        this.device = this.searchValue
        this.searchTicket = ''
      }
      this.searchData()
    },
    searchData () {
      localStorage.setItem('ownerSelect', this.service)
      const params = {
        assetOwner: this.service,
        type: this.type,
        rentalStatus: this.status,
        ticketId: this.searchTicket ? this.searchTicket : this.ticketId,
        peerId: this.device,
        withTicket: Number(this.withTicket),
        startDate: this.startDate,
        endDate: this.endDate
      }
      Bus.$emit('assetsSearch', params)
    },
    getAllOwner () {
      this.$axios
        .get('/rental-forecasting/hubSpot/getAllOption')
        .then(res => {
          this.serviceOptions = res.data.result.ownerList
          this.service = localStorage.getItem('ownerSelect') || ''
          this.statusOptions = res.data.result.assetRentalStatusList
          localStorage.setItem('statusList', JSON.stringify(res.data.result.assetRentalStatusColorMap))
          localStorage.setItem('linkMap', JSON.stringify(res.data.result.linkMap))
          this.searchData()
          localStorage.setItem('withTicket', '0')
        })
        .catch((error) => {
          this.$message.error(error)
        })
    }
  }
}
</script>

<style scoped lang="less">
.search {
  padding: 12px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /deep/.el-tag.el-tag--info {
    background-color: #444;
    border-color: #444;
    color: #dbdbdb;
  }
  /deep/.el-select .el-input__inner {
    border-color: #444444;
    background: #252525;
    border-radius: 4px;
    color: #DBDBDB;
  }
  /deep/.el-input .el-input__inner {
    border-color: #444444;
    background-color: #252525;
  }
  .setting {
    font-size: 14px;
    padding-right: 30px;
    cursor: pointer;
  }
  /deep/.el-input__suffix {
    cursor: pointer;
  }
  .search-input {
    /deep/.el-icon-search, /deep/.el-input__inner::placeholder {
      color: #666666!important;
    }
    /deep/.el-icon-search {
      font-weight: bold;
    }
  }
}
</style>
