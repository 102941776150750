import axios from 'axios'
axios.defaults.withCredentials = true
axios.defaults.timeout = 30000
const loginUrl = localStorage.getItem('loginUrl')
// http request 请求拦截器
axios.defaults.withCredentials = true
// 用于连续多次点击时，取消之前的请求，只保留最后一次的请求状态
const pending = [] // 声明一个数组用于存储每个正在pending的状态请求
const cancelToken = axios.CancelToken
const removePending = (config, str) => {
  for (const i in pending) {
    if (pending[i].u === config.url + '&' + config.method) {
      // 当当前请求在数组中存在时执行函数体
      pending[i].f() // 执行取消操作
      pending.splice(i, 1)
    }
  }
}
axios.interceptors.request.use(
  config => {
    if (config.isCancelToken) {
      removePending(config, 'request') // 在一个axios发送前执行一下取消操作
      // eslint-disable-next-line new-cap
      config.cancelToken = new cancelToken(cancel => {
        // 这里的axios标识用请求地址&请求方式拼接的字符串
        pending.push({ u: config.url + '&' + config.method, f: cancel })
      })
    }
    config.headers['Content-Type'] = 'application/json;charset=UTF-8'
    return config
  },
  err => {
    return Promise.reject(err)
  }
)

// http response 服务器响应拦截器，这里拦截401错误，并重新跳入登页重新获取token
axios.interceptors.response.use(
  response => {
    if (response.config && response.config.isCancelToken) {
      removePending(response.config, 'response') // 在一个axios响应后再执行一下取消操作，把已经完成的请求从pending中移除
    }
    return response
  },
  error => {
    // 需要跳转到登录
    if (error.response.status === 401) {
      sessionStorage.setItem('redirectRouter', window.location.hash.split('#/')[1])
      window.location.href = loginUrl || window.location.origin
    }
    return Promise.reject(error)
  }
)
export default axios
