<template>
<div class="deal">
  <header-search searchType="assets1"></header-search>
  <vxe-grid
      v-loading="loading"
      size="small"
      align="center"
      class="reverse-table mytable-scrollbar"
      :row-config="{isCurrent: true, isHover: true}"
      :empty-render="{name: 'NotData'}"
      v-bind="gridOptions">
  </vxe-grid>
</div>
</template>

<script>
import Bus from '@/common/vueBus'
import HeaderSearch from '../../components/HeaderSearch'
import _ from 'lodash'
export default {
  name: 'Deal',
  components: {
    HeaderSearch
  },
  data () {
    return {
      loading: false,
      gridOptions: {
        border: true,
        showOverflow: true,
        showHeader: false,
        columns: [],
        data: []
      },
      myColumns: [],
      myData: [],
      type: '',
      dealList: []
    }
  },
  created () {
    Bus.$off('changeSearch')
    Bus.$on('changeSearch', (val) => {
      this.type = localStorage.getItem('assetType') || 'T'
      this.getData(val)
    })
  },
  methods: {
    // 反转表头
    reverseTable () {
      this.gridOptions.data = []
      this.gridOptions.columns = []
      if (this.myColumns.length <= 1) return
      const buildData = this.myColumns.map(column => {
        const item = { col0: column.title }
        this.myData.forEach((row, index) => {
          item[`col${index + 1}`] = row[column.field]
        })
        return item
      })
      const buildColumns = [{
        field: 'col0',
        fixed: 'left',
        width: 210,
        slots: {
          // 使用 JSX 渲染
          default: ({ row }) => {
            if (row.col0 === 'Deal name / Date') {
              return [
                <span>{row.col0}</span>
              ]
            } else {
              return [
                <span style="border-bottom: 1px solid #33ab4f; cursor: pointer;" onClick={ () => this.renderWebsite(row) }>{row.col0}</span>
              ]
            }
          }
        }
      }]
      this.myData.forEach((item, index) => {
        buildColumns.push({
          field: `col${index + 1}`,
          minWidth: 90
        })
      })
      this.gridOptions.data = buildData
      this.gridOptions.columns = buildColumns
    },
    getData (val) {
      const params = {
        startDate: val.startTime,
        endDate: val.endTime,
        assetOwner: val.service,
        dealStage: val.dealStage
      }
      this.loading = true
      this.$axios
        .post('/rental-forecasting/deal/rentalDailyStatic', params)
        .then(res => {
          if (res.data) {
            this.myData = []
            this.myColumns = []
            const titleList = []
            res.data.forEach(item => {
              const newObj = { date: item.date }
              item.dealList.forEach(deal => {
                titleList.push(deal.dealName + '?' + deal.dealId)
                newObj[deal.dealName] = this.type === 'R' ? deal.receiverCount : deal.transmitterCount
              })
              this.myData.push(newObj)
            })
            this.dealList = _.uniq(titleList)
            // 添加表头
            this.dealList.forEach(key => {
              if (key !== 'date') {
                this.myColumns.push({ field: key.split('?')[0], title: key.split('?')[0], id: key.split('?')[1] })
              }
            })
            this.myColumns.push({ field: 'date', title: 'Deal name / Date' })
            this.reverseTable()
          } else {
            this.$message.error('Error')
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    renderWebsite (row) {
      if (!row.col0) return
      const dealId = this.dealList.find(item => item.includes(row.col0)).split('?')[1]
      const url = `${JSON.parse(localStorage.getItem('linkMap')).deal}${dealId}`
      window.open(url)
    }
  }
}
</script>

<style scoped lang="less">
.reverse-table {
  width: 100%;
  margin-top: 15px;
}
</style>
