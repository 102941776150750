<template>
<div class="hs-deal">
  <h4>HS Deal Data</h4>
  <el-table
      style="width: 100%;cursor: pointer;box-shadow:0px 2px 6px 0px rgba(0,0,0,0.2);"
      class="listTable dynamic_column"
      :data="tableData"
      element-loading-background="rgba(0, 0, 0, 0.6)"
      stripe
  >
    <el-table-column
        label="Deal Stages"
        prop="stages"
        show-overflow-tooltip
    >
      <template slot-scope="scope">
        <span
          class="stages"
          @click="goDetail(scope.row)">{{scope.row.stages}}</span>
      </template>
    </el-table-column>
    <el-table-column
        label="Count"
        prop="dealCount"
        show-overflow-tooltip
    >
    </el-table-column>
    <el-table-column
        label="T Count"
        prop="tCount"
        show-overflow-tooltip
    >
    </el-table-column>
    <el-table-column
        label="R Count"
        prop="rCount"
        show-overflow-tooltip
    >
    </el-table-column>
  </el-table>
</div>
</template>

<script>
export default {
  name: 'HSDeal',
  props: {
    optionData: Array
  },
  data () {
    return {
      tableData: this.optionData
    }
  },
  watch: {
    optionData (newVal) {
      this.tableData = newVal
    }
  },
  methods: {
    goDetail (val) {
      this.$emit('changeDetail', val)
    }
  }
}
</script>

<style scoped lang="less">
.hs-deal {
  width: 50%;
}
.stages {
  text-decoration: underline;
}
.stages:hover {
  color: #5cbc72;
}
</style>
