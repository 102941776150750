<template>
  <div class="add-product">
    <el-button type="primary" @click="openAdd">+ New Product</el-button>
    <el-dialog
        v-loading="pageLoading"
        title="Add Product"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose">
      <div class="product-content" v-if="productList.length > 0">
        <div v-for="(item, index) in productList" :key="index">
          <span>{{item.label}}：</span>
          <el-radio-group v-model="radioValues[item.name]" @change="(val) => changeActiveRadio(val, item.name)">
            <el-radio-button v-for="(secItem, secIndex) in item.value" :key="secIndex" :label="secItem.accessoryValue"></el-radio-button>
          </el-radio-group>
          <br><br>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="info" @click="handleClose">Cancel</el-button>
        <el-button :loading="loading" type="primary" @click="handleSubmit">Sure</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  name: 'AddProductCPQ',
  props: {
    accessoryList: Array
  },
  data () {
    return {
      dialogVisible: false,
      productList: [],
      radioValues: {},
      loading: false,
      pageLoading: false
    }
  },
  created () {
    // this.getNewProduct(this.accessoryList[0].name)
    this.productList = []
    this.radioValues = {}
  },
  methods: {
    getNewProduct (accessoryName, params) {
      const payload = {
        accessoryName,
        ...params
      }
      this.pageLoading = true
      this.$axios
        .post('/rental-forecasting/public/accessory/list', payload)
        .then((res) => {
          const idx = _.findIndex(this.accessoryList, { name: accessoryName })
          const currentObj = this.accessoryList[idx]
          // 空数组
          if (_.isEmpty(res.data) && idx + 1 < this.accessoryList.length) {
            const nextAccessory = this.accessoryList[idx + 1]
            this.getNewProduct(nextAccessory.name, this.formatFilter(nextAccessory))
            return
          }
          // 有值
          this.productList.push({ ...currentObj, value: res.data })
          this.pageLoading = false
        })
    },
    changeActiveRadio (val, name) {
      const currentIdx = _.findIndex(this.productList, { name })
      this.productList = this.productList.slice(0, currentIdx + 1)
      const keyList = _.map(this.productList, 'name')
      this.radioValues = _.pick(this.radioValues, keyList)
      this.radioValues[name] = val
      const idx = _.findIndex(this.accessoryList, { name })
      if (idx + 1 >= this.accessoryList.length) {
        return
      }
      const nextAccessory = this.accessoryList[idx + 1]
      this.getNewProduct(nextAccessory.name, this.formatFilter(nextAccessory))
    },
    formatFilter (data) {
      const { filter } = data
      const newFilter = filter.split(',')
      const params = {}
      newFilter.forEach((item) => {
        params[item] = this.radioValues[item]
      })
      return params
    },
    openAdd () {
      this.dialogVisible = true
      this.getNewProduct(this.accessoryList[0].name)
      this.productList = []
      this.radioValues = {}
    },
    submitCheck () {
      const keyList = _.map(this.productList, 'name')
      return _.every(keyList, (item) => this.radioValues[item])
    },
    handleClose () {
      this.dialogVisible = false
    },
    handleSubmit () {
      // 需要把配件选择完成之后才能提交
      if (!this.submitCheck()) {
        return this.$message.error('Please continue selecting accessories')
      }
      this.loading = true
      this.$axios
        .post('/rental-forecasting/public/hubSpot/product/create', this.radioValues)
        .then((res) => {
          if (res.data.errorCode === '0x0') {
            this.$emit('addProduct', res.data.result)
            this.dialogVisible = false
          } else {
            this.$message.error(res.data.errorInfo)
          }
          this.loading = false
        }).catch(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped lang="less">
/deep/.el-dialog__wrapper {
  .el-dialog {
    background-color: #FFFFFF;
    color: #333333!important;
  }
  .el-dialog__title {
    color: #333333!important;
    font-size: 16px!important;
  }
  .el-dialog .el-dialog__header .el-dialog__headerbtn .el-icon-close:before {
    color: #555;
    font-weight: 600;
    font-size: 20px;
  }
  .product-content {
    color: #333333;
  }
}
@media (max-width: 760px) {
  /deep/.el-dialog__wrapper {
    .el-dialog {
      width: 90% !important;
    }
  }
}
</style>
