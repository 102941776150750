<template>
<div class="header">
  <el-tooltip placement="bottom" popper-class="header-pop">
    <div slot="content">
      <p>Frontend: {{ rentalFrontVersion }}</p>
      <p>Backend: {{ rentalBackendVersion }}</p>
    </div>
    <div class="logo">
      <img src="../assets/images/new-logo.png" alt="" width="32">
      <span>Rental Management Center</span>
    </div>
  </el-tooltip>
  <div class="tab-text" v-if="showCenter">Shipment Device</div>
  <div class="header-name">
    <header-time></header-time>
    <el-dropdown placement="bottom" v-if="false">
        <span class="el-dropdown-link">
          <div class="user-name">{{username.substr(0,2)}}</div>
        </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item>username: {{username}}</el-dropdown-item>
        <el-dropdown-item
            divided
            @click.native="logout">Sign Out</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</div>
</template>

<script>
import HeaderTime from './HeaderTime'

export default {
  name: 'Header',
  props: {
    showCenter: Boolean
  },
  components: { HeaderTime },
  data () {
    return {
      username: 'ad',
      rentalBackendVersion: '',
      rentalFrontVersion: ''
    }
  },
  mounted () {
    this.rentalBackendVersion = localStorage.getItem('rentalBackendVersion')
    this.rentalFrontVersion = localStorage.getItem('rentalFrontVersion')
  },
  methods: {
    logout () {}
  }
}
</script>

<style scoped lang="less">
.header {
  height: 64px;
  line-height: 64px;
  background: #000;
  //padding: 0 16px;
  padding: 0 100px 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .logo {
    display: flex;
    align-items: center;
    cursor: pointer;
    span {
      font-size: 16px;
      font-weight: 700;
      color: #DBDBDB;
      margin-left: 16px;
    }
  }
  .tab-text {
    text-align: center;
    color: #69AD48;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
    //margin-left: 33%;
  }
  .header-name {
    display: flex;
    align-items: center;
  }
  .user-name {
    margin-left: 16px;
    width: 28px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    background: #70A819;
    border-radius: 50%;
    color: #ffffff;
    font-weight: 700;
    font-size: 14px;
    cursor: pointer;
  }
}
</style>
