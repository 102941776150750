<template>
  <div id="app">
    <div class="service-jump" v-if="showServiceJupm && isRenderHeader">
      <tvu-service-jump-collect></tvu-service-jump-collect>
    </div>
    <Header :showCenter="false" v-if="isRenderHeader"></Header>
    <div class="main">
      <Menu v-if="isRenderHeader"></Menu>
      <router-view />
    </div>
  </div>
</template>
<script>
import Header from './components/Header'
import Menu from './components/Menu'
export default {
  data () {
    return {
      showServiceJupm: false
    }
  },
  components: {
    Menu,
    Header
  },
  computed: {
    isRenderHeader () {
      const excludedPaths = ['/simpleRental', '/simpleRentalCPQ', '/printPage', '/redBullRental', '/print']
      return !excludedPaths.includes(this.$route.path)
    }
  },
  created () {
    this.getInitData()
    this.getVersion()
  },
  methods: {
    getVersion () {
      this.$axios
        .get('/rental-forecasting/base/version')
        .then(res => {
          localStorage.setItem('rentalBackendVersion', res.data)
        })
        .catch((error) => {
          this.$message.error(error)
        })
    },
    getInitData () {
      this.$axios
        .get('/rental-forecasting/base/initConfig')
        .then(res => {
          localStorage.setItem('loginUrl', res.data.loginPageUrl)
          this.$TVU.userAuthUrl = res.data.userAuthUrl
          this.showServiceJupm = true
        })
        .catch((error) => {
          this.$message.error(error)
        })
    }
  }
}
</script>
<style lang="less" scoped>
#app {
  //padding: 20px 0;
}
.service-jump {
  position: absolute;
  right: 20px;
  top: 18px;
}
.main {
  display: flex;
  width: 100%;
  height: 100%;
}
</style>
