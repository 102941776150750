<template>
<div class="ticket-content">
  <div id="ticket"></div>
  <div class="ticket-icon">
    <el-tooltip class="item" effect="dark" content="Switch to Stacked Aera Chart" placement="top">
      <i class="iconfont icon-a-statisticalviewlinechart-fill" :class="{'active': isShowArea}" @click="switchChart"></i>
    </el-tooltip>
    <el-tooltip class="item" effect="dark" content="Switch to Line Chart" placement="top">
      <i class="iconfont icon-Line-chart" :class="{'active': !isShowArea}" @click="switchChart"></i>
    </el-tooltip>
  </div>
  <div class="ticket-list" v-if="showTable">
    <div class="title-box">
      <span>Ticket List ({{currentSeriesName}}, {{currentData}})</span>
      <i class="iconfont icon-close" @click="closeTable"></i>
    </div>
    <el-table
        :data="tableData"
        v-loading="tableLoading"
        border
        max-height="780"
        style="width: 98%">
      <el-table-column
          type="index"
          label="#"
          width="50">
      </el-table-column>
      <el-table-column
          sortable
          prop="ticketName"
          label="Ticket Name"
          width="160">
        <template slot-scope="scope">
          <span
              class="stages"
              @click="openLink(scope.row.ticketId)" :title="scope.row.ticketName">{{scope.row.ticketName}}</span>
        </template>
      </el-table-column>
      <el-table-column
          sortable
          width="140"
          prop="deliveryDate"
          label="Delivery Date">
      </el-table-column>
      <el-table-column
          sortable
          width="135"
          prop="returnDate"
          label="Return Date">
      </el-table-column>
      <el-table-column
          class-name="ticket-count"
          prop="associatedAssets"
          min-width="170"
          label="Associated Assets">
        <template slot-scope="scope">
          <div :class="{'assets-list': scope.row.associatedAssets && scope.row.associatedAssets.length > 1}" v-for="(item, index) in scope.row.associatedAssets" :key="index">
            <span v-if="scope.row.associatedAssets && scope.row.associatedAssets.length > 1" class="asset-order">{{index + 1}}</span>
            <span :title="item.peerId" class="asset-id">{{item.peerId}}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
          min-width="100"
          sortable
          class-name="ticket-count"
          prop="rCount"
          label="Platform #">
        <template slot-scope="scope">
          <div class="plat-form" :class="{'assets-list': scope.row.associatedAssets && scope.row.associatedAssets.length > 1}" v-for="(item, index) in scope.row.associatedAssets" :key="index" :title="item.platform">{{item.platform}}</div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</div>
</template>

<script>
import * as echarts from 'echarts'
import moment from 'moment'
export default {
  name: 'Ticket',
  props: {
    dataInfo: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  watch: {
    dataInfo: {
      handler () {
        this.initOptions()
        this.option && this.myChartTicket.setOption(this.option)
      },
      deep: true
    }
  },
  data () {
    return {
      tableData: [],
      tableLoading: false,
      showTable: false,
      currentData: '',
      currentSeriesName: '',
      isShowArea: true,
      myChartTicket: null,
      xAxisData: [],
      option: {
        color: ['#9B51E0', '#70A819', '#44A4FD', '#C81B28', '#42A98B', '#FF9A24', '#C115B6'],
        title: {
          left: 40,
          top: 14,
          text: 'Ticket',
          textStyle: {
            fontsize: 20,
            color: '#fff'
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          },
          backgroundColor: '#0B0E32',
          textStyle: {
            color: '#fff'
          },
          borderColor: '#0B0E32'
        },
        legend: {
          data: ['DO Generated', 'Preparation Completed', 'Rental Period', 'Overdue', 'Equipment Receiving', 'Inbound QC', 'Pending Follow Up'],
          bottom: '0',
          textStyle: {
            color: '#fff'
          },
          itemWidth: 12,
          icon: 'circle'
        },
        grid: {
          left: 20,
          right: 32,
          bottom: 32,
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: [],
            // x轴线的颜色设置
            axisLine: {
              lineStyle: {
                color: '#072264'
              }
            },
            axisLabel: {
              color: '#fff'
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            min: 0, // 保持 y 轴的起始点在 0
            splitLine: {
              lineStyle: {
                type: 'dashed',
                color: '#072264',
                width: '2'
              }
            },
            axisLabel: {
              color: '#fff'
            }
          }
        ]
      },
      nameData: ['DO Generated', 'Preparation Completed', 'Rental Period', 'Overdue', 'Equipment Receiving', 'Inbound QC', 'Pending Follow Up'],
      nameColor: [{ color1: '#9B51E0', color2: '#683598' }, { color1: '#70A819', color2: '#4F7611' }, { color1: '#44A4FD', color2: '#2B5BA7' }, { color1: '#C81B28', color2: '#80121A' }, { color1: '#42A98B', color2: '#266B57' }, { color1: '#FF9A24', color2: '#8A5415' }, { color1: '#C115B6', color2: '#790D72' }],
      areaSeries: [],
      lineChartSeries: []
    }
  },
  beforeDestroy () {
    // 离开页面必须进行移除，否则会造成内存泄漏，导致卡顿
    window.removeEventListener('resize', this.resizeFn)
  },
  mounted () {
    this.setInitSeries()
    const chartDom = document.getElementById('ticket')
    this.myChartTicket = echarts.init(chartDom)
    this.myChartTicket.showLoading('default', {
      text: '',
      textColor: '#DBDBDB',
      maskColor: 'rgba(0, 0, 0, 0.8)',
      color: '#69AD48',
      spinnerRadius: 20,
      lineWidth: 3
    })
    // this.initOptions()
    // this.option && this.myChartTicket.setOption(this.option)
    // 图表重置
    setTimeout(() => {
      window.addEventListener('resize', () => {
        this.resizeFn()
      })
    }, 100)
    this.myChartTicket.on('click', (params) => {
      // console.log(this, params)
      this.currentData = params.name
      this.currentSeriesName = params.seriesName
      this.getTickeList()
    })
    this.myChartTicket.on('legendselectchanged', function (params) {
      // 将选中状态存储在 localStorage 里
      localStorage.setItem('ticketLegends', JSON.stringify(params.selected))
    })
  },
  methods: {
    setInitSeries () {
      this.areaSeries = []
      this.lineChartSeries = []
      this.nameData.forEach((item, index) => {
        this.areaSeries.push({
          name: item,
          type: 'line',
          stack: 'Total',
          lineStyle: {
            width: 0
          },
          areaStyle: {
            opacity: 1,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: this.nameColor[index].color1
              },
              {
                offset: 1,
                color: this.nameColor[index].color2
              }
            ])
          },
          data: [],
          showSymbol: index === this.nameData.length - 1,
          label: {
            normal: {
              show: index === this.nameData.length - 1,
              position: 'top',
              color: '#DBDBDB',
              fontSize: 16,
              formatter: (params) => {
                // const { data, dataIndex, seriesIndex, seriesName, componentSubType } = params
                const { dataIndex, componentSubType } = params
                let sum = 0
                if (componentSubType === 'line') {
                  // 遍历所有系列并累加其数据，此处假设所有系列按顺序排列，有相同的data长度
                  this.option.series.forEach((series) => {
                    sum += series.data[dataIndex]
                  })
                  // 返回总和
                  return sum
                }
                return ''
              }
            }
          }
        })
        this.lineChartSeries.push({
          name: item,
          type: 'line',
          data: [],
          label: {
            normal: {
              show: true,
              position: 'top',
              color: '#DBDBDB',
              fontSize: 16,
              formatter: function (params) {
                const todayDate = moment().format('YYYY-MM-DD')
                // 仅当 xAxis 是周三时显示标签
                if (params.name === todayDate) {
                  return params.value
                }
                return ''
              }
            }
          }
        })
      })
    },
    initOptions () {
      this.myChartTicket.clear()
      // 获取缓存里图例选择的状态
      let selectedLegends = {}
      if (localStorage.getItem('ticketLegends')) {
        selectedLegends = JSON.parse(localStorage.getItem('ticketLegends'))
      }
      // 从缓存里拿图例选中的状态，设置图例
      if (JSON.stringify(selectedLegends) !== '{}') this.option.legend.selected = selectedLegends
      this.xAxisData = []
      this.option.series = []
      this.areaSeries.forEach(item => {
        item.data = []
      })
      this.lineChartSeries.forEach((item, index) => {
        item.data = []
      })
      this.option.series = this.isShowArea ? this.areaSeries : this.lineChartSeries
      this.dataInfo.forEach((item) => {
        this.xAxisData.push(item.date)
        this.option.series[0].data.push(item.ticketGenerated)
        this.option.series[1].data.push(item.preparationComplete)
        this.option.series[2].data.push(item.rentalPeriod)
        this.option.series[3].data.push(item.overdue)
        this.option.series[4].data.push(item.equipmentReceiving)
        this.option.series[5].data.push(item.equipmentQC)
        this.option.series[6].data.push(item.pendingFollowUp)
      })
      this.option.xAxis[0].data = this.xAxisData
      this.myChartTicket.hideLoading()
    },
    // 切换图表类型
    switchChart () {
      this.isShowArea = !this.isShowArea
      this.initOptions()
      this.myChartTicket.setOption(this.option)
    },
    // 获取Ticke列表数据
    getTickeList () {
      const params = {
        assetOwner: localStorage.getItem('ownerSelect') || '',
        ticketStatus: this.currentSeriesName,
        date: this.currentData,
        type: localStorage.getItem('assetType') || ''
      }
      this.tableLoading = true
      this.$axios.post('/rental-forecasting/asset/dashboard/listTicket', params).then((res) => {
        this.tableData = res.data
        this.showTable = true
        this.tableLoading = false
      })
    },
    closeTable () {
      this.showTable = false
    },
    openLink (id) {
      const url = `${JSON.parse(localStorage.getItem('linkMap')).ticket}${id}`
      window.open(url)
    },
    resizeFn () {
      return this.myChartTicket.resize()
    }
  }
}
</script>

<style scoped lang="less">
.ticket-content {
  background: #0E0A1F;
  border-radius: 8px;
  position: relative;

  .ticket-icon {
    position: absolute;
    right: 10px;
    top: 4px;
    i {
      color: #9A9DB4;
      font-size: 20px;
      cursor: pointer;
    }
    .icon-Line-chart {
      margin-left: 6px;
    }
  }
}
#ticket {
  width: 100%;
  min-height: 330px;
  padding-bottom: 30px;
}
canvas {
  width: 100%;
  padding: 20px 10px!important;
}

.ticket-list {
  border-top: 2px dashed rgba(154, 157, 180, 0.62);
  padding: 12px 0;
  margin: 0 24px;

  .title-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      color: #999999;
      font-size: 16px;
      font-weight: 700;
    }
    i {
      font-size: 24px;
      color: #DBDBDB;
      cursor: pointer;
    }
  }
}

.stages {
  text-decoration: underline;
  color: #DBDBDB;
  cursor: pointer;
  word-break: keep-all;
}

/deep/.el-table .el-table__row td.ticket-count {
  .cell {
    padding: 0!important;
  }
  .assets-list {
    border-bottom: solid 2px #1D1639;
    span {
      padding: 0 12px;
    }
    .asset-order {
      width: 45px;
      display: inline-block;
      border-right: solid 2px #1D1639;
    }
  }
  .asset-id {
    padding-left: 12px;
    display: inline-block;
    //white-space: nowrap; /* 保证文本在一行内显示 */
    //overflow: hidden; /* 超出容器部分隐藏 */
    //text-overflow: ellipsis; /* 超出部分显示省略号 */
  }
  .cell > .assets-list:last-child {
    border-bottom: 0;
  }
  .plat-form {
    padding: 0 12px;
    white-space: nowrap; /* 保证文本在一行内显示 */
    overflow: hidden; /* 超出容器部分隐藏 */
    text-overflow: ellipsis; /* 超出部分显示省略号 */
  }
}
/deep/.el-table--border {
  border-bottom: 2px solid #1D1639 !important;
}
</style>
