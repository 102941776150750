<template>
<div class="deal-content">
  <div id="deal"></div>
  <div class="deal-icon">
    <el-tooltip class="item" effect="dark" content="Switch to Stacked Aera Chart" placement="top">
      <i class="iconfont icon-a-statisticalviewlinechart-fill" :class="{'active': isShowArea}" @click="switchChart"></i>
    </el-tooltip>
    <el-tooltip class="item" effect="dark" content="Switch to Line Chart" placement="top">
      <i class="iconfont icon-Line-chart" :class="{'active': !isShowArea}" @click="switchChart"></i>
    </el-tooltip>
  </div>
  <div class="deal-list" v-if="showTable">
    <div class="title-box">
      <span>Deal List ({{currentSeriesName}}, {{currentData}})</span>
      <i class="iconfont icon-close" @click="closeTable"></i>
    </div>
    <el-table
        :data="tableData"
        v-loading="tableLoading"
        border
        max-height="780"
        style="width: 98%">
      <el-table-column
          type="index"
          label="#"
          width="50">
      </el-table-column>
      <el-table-column
          prop="dealName"
          label="Deal Name"
          sortable
          show-overflow-tooltip
          width="200">
        <template slot-scope="scope">
          <span
              class="stages"
              @click="openLink(scope.row.dealId)">{{scope.row.dealName}}</span>
        </template>
      </el-table-column>
      <el-table-column
          sortable
          prop="deliveryDate"
          show-overflow-tooltip
          label="Delivery Date">
      </el-table-column>
      <el-table-column
          sortable
          prop="returnDate"
          show-overflow-tooltip
          label="Return Date">
      </el-table-column>
      <el-table-column
          prop="tCount"
          sortable
          show-overflow-tooltip
          label="T Count">
      </el-table-column>
      <el-table-column
          prop="rCount"
          sortable
          show-overflow-tooltip
          label="R Count">
      </el-table-column>
    </el-table>
  </div>
</div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  name: 'Deal',
  props: {
    dataInfo: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  watch: {
    dataInfo: {
      handler () {
        this.initOptions()
        this.option && this.myChartDeal.setOption(this.option)
      },
      deep: true
    }
  },
  data () {
    return {
      tableData: [],
      tableLoading: false,
      showTable: false,
      currentData: '',
      currentSeriesName: '',
      isShowArea: true,
      myChartDeal: null,
      xAxisData: [],
      option: {
        color: ['#70A819', '#44A4FD', '#C81B28'],
        title: {
          left: 40,
          top: 14,
          text: 'Deal',
          textStyle: {
            fontsize: 20,
            color: '#fff'
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          },
          backgroundColor: '#0B0E32',
          textStyle: {
            color: '#fff'
          },
          borderColor: '#0B0E32'
        },
        legend: {
          data: ['Closed Won', 'Unconfirmed', 'Lost'],
          bottom: '0',
          textStyle: {
            color: '#fff'
          },
          itemWidth: 12,
          icon: 'circle'
        },
        grid: {
          left: 20,
          right: 32,
          bottom: 32,
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: [],
            // x轴线的颜色设置
            axisLine: {
              lineStyle: {
                color: '#072264'
              }
            },
            axisLabel: {
              color: '#fff'
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            min: 0, // 保持 y 轴的起始点在 0
            splitLine: {
              lineStyle: {
                type: 'dashed',
                color: '#072264',
                width: '2'
              }
            },
            axisLabel: {
              color: '#fff'
            }
          }
        ]
      },
      areaSeries: [
        {
          name: 'Closed Won',
          type: 'line',
          stack: 'Total',
          lineStyle: {
            width: 0
          },
          showSymbol: false,
          areaStyle: {
            opacity: 1,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: 'rgb(112,168,25)'
              },
              {
                offset: 1,
                color: 'rgb(79,118,17)'
              }
            ])
          },
          data: []
        },
        {
          name: 'Unconfirmed',
          type: 'line',
          stack: 'Total',
          lineStyle: {
            width: 0
          },
          showSymbol: false,
          areaStyle: {
            opacity: 1,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: 'rgb(68,164,253)'
              },
              {
                offset: 1,
                color: 'rgb(43,91,167)'
              }
            ])
          },
          data: []
        },
        {
          name: 'Lost',
          type: 'line',
          stack: 'Total',
          lineStyle: {
            width: 0
          },
          // showSymbol: false,
          label: {
            normal: {
              show: true,
              position: 'top',
              color: '#DBDBDB',
              fontSize: 16,
              formatter: (params) => {
                // const { data, dataIndex, seriesIndex, seriesName, componentSubType } = params
                const { dataIndex, componentSubType } = params
                let sum = 0
                if (componentSubType === 'line') {
                  // 遍历所有系列并累加其数据，此处假设所有系列按顺序排列，有相同的data长度
                  this.option.series.forEach((series) => {
                    sum += series.data[dataIndex]
                  })
                  // 返回总和
                  return sum
                }
                return ''
              }
            }
          },
          areaStyle: {
            opacity: 1,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: 'rgb(200,27,40)'
              },
              {
                offset: 1,
                color: 'rgb(128,18,26)'
              }
            ])
          },
          data: []
        }
      ],
      lineChartSeries: [
        {
          name: 'Closed Won',
          type: 'line',
          data: []
        },
        {
          name: 'Unconfirmed',
          type: 'line',
          data: []
        },
        {
          name: 'Lost',
          type: 'line',
          data: []
        }
      ]
    }
  },
  beforeDestroy () {
    // 离开页面必须进行移除，否则会造成内存泄漏，导致卡顿
    window.removeEventListener('resize', this.resizeFn)
  },
  mounted () {
    const chartDom = document.getElementById('deal')
    this.myChartDeal = echarts.init(chartDom)
    this.myChartDeal.showLoading('default', {
      text: '',
      textColor: '#DBDBDB',
      maskColor: 'rgba(0, 0, 0, 0.8)',
      color: '#69AD48',
      spinnerRadius: 20,
      lineWidth: 3
    })
    // this.initOptions()
    // this.option && this.myChartDeal.setOption(this.option)
    // 图表重置
    setTimeout(() => {
      window.addEventListener('resize', () => {
        this.resizeFn()
      })
    }, 100)
    this.myChartDeal.on('click', (params) => {
      // console.log(params, '000')
      this.currentData = params.name
      this.currentSeriesName = params.seriesName
      this.getDealList()
    })
    this.myChartDeal.on('legendselectchanged', function (params) {
      // 将选中状态存储在 localStorage 里
      localStorage.setItem('dealLegends', JSON.stringify(params.selected))
    })
    // ECharts 容器点击事件
    // document.getElementById('deal').addEventListener('click', (event) => {
    //   console.log(this, '000')
    //   // 获取点击位置相对于图表容器的像素坐标
    //   const pixel = [event.offsetX, event.offsetY]
    //   // 将像素坐标转换为数据坐标
    //   const data = this.myChartDeal.convertFromPixel({ seriesIndex: 0 }, pixel)
    //   console.log(data, '---data')
    //
    //   // data[0] 表示 X 轴的数据索引，data[1] 表示 Y 轴的数据值
    //   if (data[0] != null && data[1] != null) {
    //     console.log(this.option.xAxis.data[data[0]])
    //     alert('点击了区域：' + this.option.series[0].data[data[0]] + '，值为：' + data[1])
    //   } else {
    //     console.log('点击位置没有对应的数据点')
    //   }
    // })
  },
  methods: {
    initOptions () {
      this.myChartDeal.clear()
      // 获取缓存里图例选择的状态
      let selectedLegends = {}
      if (localStorage.getItem('dealLegends')) {
        selectedLegends = JSON.parse(localStorage.getItem('dealLegends'))
      }
      // 从缓存里拿图例选中的状态，设置图例
      if (JSON.stringify(selectedLegends) !== '{}') this.option.legend.selected = selectedLegends
      this.xAxisData = []
      this.option.series = []
      this.areaSeries.forEach(item => {
        item.data = []
      })
      this.lineChartSeries.forEach((item, index) => {
        item.data = []
      })
      this.option.series = this.isShowArea ? this.areaSeries : this.lineChartSeries
      this.dataInfo.forEach((item) => {
        this.xAxisData.push(item.date)
        this.option.series[0].data.push(item.closedWon)
        this.option.series[1].data.push(item.unconfirmed)
        this.option.series[2].data.push(item.lost)
      })
      this.option.xAxis[0].data = this.xAxisData
      this.myChartDeal.hideLoading()
    },
    // 切换图表类型
    switchChart () {
      this.isShowArea = !this.isShowArea
      this.initOptions()
      this.myChartDeal.setOption(this.option)
    },
    // 获取deal列表数据
    getDealList () {
      const params = {
        assetOwner: localStorage.getItem('ownerSelect') || '',
        dealStage: this.currentSeriesName,
        date: this.currentData,
        type: localStorage.getItem('assetType') || ''
      }
      this.tableLoading = true
      this.$axios.post('/rental-forecasting/asset/dashboard/listDeal', params).then((res) => {
        this.tableData = res.data
        this.showTable = true
        this.tableLoading = false
      })
    },
    closeTable () {
      this.showTable = false
    },
    openLink (id) {
      const url = `${JSON.parse(localStorage.getItem('linkMap')).deal}${id}`
      window.open(url)
    },
    resizeFn () {
      return this.myChartDeal.resize()
    }
  }
}
</script>

<style scoped lang="less">
.deal-content {
  background: #0E0A1F;
  border-radius: 8px;
  position: relative;

  .deal-icon {
    position: absolute;
    right: 10px;
    top: 4px;
    i {
      color: #9A9DB4;
      font-size: 20px;
      cursor: pointer;
    }
    .icon-Line-chart {
      margin-left: 6px;
    }
  }
}
#deal {
  width: 100%;
  min-height: 330px;
  padding-bottom: 30px;
}
canvas {
  width: 100%;
  padding: 20px 10px!important;
}

.deal-list {
  border-top: 2px dashed rgba(154, 157, 180, 0.62);
  padding: 12px 0;
  margin: 0 24px;

  .title-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      color: #999999;
      font-size: 16px;
      font-weight: 700;
    }
    i {
      font-size: 24px;
      color: #DBDBDB;
      cursor: pointer;
    }
  }
}

.stages {
  text-decoration: underline;
  color: #DBDBDB;
  cursor: pointer;
}

</style>
