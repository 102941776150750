<template>
  <div class="add-product">
    <div class="product-content" v-if="productList.length > 0">
      <div v-for="(item, index) in productList" :key="index">
        <el-select
          @change="productChanges()"
          clearable
          filterable
          v-model="lineItemList[index].productId"
          :placeholder="index === 0 ? '*Please select' : 'Please select'">
          <el-option
              v-for="(option, optionIndex) in productListAll"
              :disabled="selectedProducts.includes(option.productId)"
              :key="optionIndex"
              :label="option.name"
              :value="option.productId"></el-option>
        </el-select>
        <el-input-number :disabled="lineItemList[index].productId === otherProductId" @change="numberChange" v-model="lineItemList[index].number" :min="1" label=""></el-input-number>
        <i class="el-icon-delete" @click="deleteProduct(index)"></i>
        <el-input @blur="blurChange" @change="otherChange" class="special-rental-product-request" v-if="lineItemList[index].productId === otherProductId" type="primary" placeholder="Didn’t find what you want? Let us know what you want here." v-model="specialRentalProductRequest"></el-input>
        <div class="tips" v-if="lineItemList[index].productId === otherProductId && showTip && !specialRentalProductRequest">Please complete this required field.</div>
      </div>
    </div>
    <el-button type="primary" @click="addProduct">+ New Product</el-button>
  </div>
</template>

<script>
import Bus from '../../common/vueBus'
export default {
  name: 'AddProduct',
  props: {
    productListAll: Array,
    otherProductId: String
  },
  data () {
    return {
      productList: [],
      lineItemList: [],
      selectedProducts: [],
      specialRentalProductRequest: '',
      showTip: false
    }
  },
  created () {
    Bus.$on('submitProduct', () => {
      this.showTip = true
    })
    Bus.$on('clearProduct', () => {
      this.productList = []
      this.lineItemList = []
      this.selectedProducts = []
    })
  },
  methods: {
    blurChange () {
      if (this.specialRentalProductRequest) {
        this.showTip = false
      } else {
        this.showTip = true
      }
    },
    otherChange () {
      this.$emit('otherChanges', this.specialRentalProductRequest)
    },
    addProduct () {
      this.productList.push({ number: 1 })
      this.lineItemList.push({ number: 1 })
      this.$emit('productChange', this.lineItemList)
    },
    deleteProduct (index) {
      if (this.lineItemList[index].productId === this.otherProductId) {
        this.specialRentalProductRequest = ''
        this.showTip = false
        this.$emit('otherChanges', this.specialRentalProductRequest)
      }
      this.productList.splice(index, 1)
      this.lineItemList.splice(index, 1)
      this.selectedProducts.splice(index, 1)
      this.$emit('productChange', this.lineItemList)
    },
    productChanges () {
      this.selectedProducts = this.lineItemList.map(item => item.productId)
      this.$emit('productChange', this.lineItemList)
    },
    numberChange () {
      this.$emit('productChange', this.lineItemList)
    }
  }
}
</script>

<style scoped lang="less">
.product-content {
  background-color: #F6F6F6;
  padding: 0 10px 10px;
  color: #333333;
  font-size: 14px;
  margin-bottom: 20px;

  div {
    margin-top: 10px;
  }
  /deep/.el-select {
    .el-input__inner {
      width: 270px;
    }
  }
  .el-input-number {
    margin: 0 35px;
  }

  .el-icon-delete {
    cursor: pointer;
    font-weight: 600;
  }

  .el-icon-delete:hover {
    color: #33AB4F;
  }
}
@media (max-width: 760px) {
  .product-content {
    .el-select {
      margin-right: 10px;
    }
    .el-input-number {
      margin-left: 0;
    }
  }
}
.special-rental-product-request {
  /deep/.el-input__inner {
    width: 486px!important;
  }
}
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #FFFFFF;
}
.tips {
  font-size: 12px;
  color: #FF0000;
  margin-top: 0!important;
}
</style>
