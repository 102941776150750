<template>
<div class="calendar">
  <header-search :activeTab="1"></header-search>
  <deal-stage-color></deal-stage-color>
  <FullCalendar v-loading="loading" :options="calendarOptions" ref="fullCalendar" />
<!--  <event-calendar-->
<!--    :showEvent="showEvent"-->
<!--    :clientX="clientX"-->
<!--    :clientY="clientY"-->
<!--    :startDate="startDate"-->
<!--    :dealStage="dealStage"-->
<!--    :bgColor="bgColor"-->
<!--    @changeEvent="changeEvent"-->
<!--    v-if="showEvent">-->
<!--  </event-calendar>-->
</div>
</template>

<script>
import HeaderSearch from '../../components/HeaderSearch'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import DealStageColor from './DealStageColor'
// import EventCalendar from './EventCalendar'
import Bus from '@/common/vueBus'
const moment = require('moment')

export default {
  name: 'RedCalendar',
  components: {
    HeaderSearch,
    FullCalendar,
    DealStageColor
    // EventCalendar
  },
  data () {
    return {
      // showEvent: false,
      // clientX: 0,
      // clientY: 0,
      // startDate: '',
      // dealStage: '',
      // bgColor: '',
      loading: false,
      statusList: {},
      status: [],
      type: '',
      assetOwner: '',
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: 'dayGridMonth', // 初始视图为日历月视图
        dayMaxEventRows: 6, // 多事件每日最多显示事件数量
        events: [],
        // eventClick: this.handleEventClick,
        customButtons: {
          prev: {
            text: 'Prev',
            click: () => {
              // 通过 getApi 获取日历实例
              const calendarApi = this.$refs.fullCalendar.getApi()
              calendarApi.prev()
              this.getCurrentViewDateRange()
            }
          },
          next: {
            text: 'Next',
            click: () => {
              // 通过 getApi 获取日历实例
              const calendarApi = this.$refs.fullCalendar.getApi()
              calendarApi.next()
              this.getCurrentViewDateRange()
            }
          },
          today: {
            text: 'Today',
            click: () => {
              // 通过 getApi 获取日历实例
              const calendarApi = this.$refs.fullCalendar.getApi()
              calendarApi.today()
              this.getCurrentViewDateRange()
            }
          },
          dayGridMonth: {
            text: 'Month',
            click: () => {
              // 通过 getApi 获取日历实例
              const calendarApi = this.$refs.fullCalendar.getApi()
              calendarApi.changeView('dayGridMonth', calendarApi.getDate())
              this.getCurrentViewDateRange()
            }
          },
          dayGridWeek: {
            text: 'Week',
            click: () => {
              // 通过 getApi 获取日历实例
              const calendarApi = this.$refs.fullCalendar.getApi()
              calendarApi.changeView('dayGridWeek', calendarApi.getDate())
              this.getCurrentViewDateRange()
            }
          },
          dayGridDay: {
            text: 'Day',
            click: () => {
              // 通过 getApi 获取日历实例
              const calendarApi = this.$refs.fullCalendar.getApi()
              calendarApi.changeView('dayGridDay', calendarApi.getDate())
              this.getCurrentViewDateRange()
            }
          }
        },
        headerToolbar: {
          left: '',
          center: '',
          right: 'prev title next today dayGridMonth,dayGridWeek,dayGridDay' // dayGridMonth
        }
      },
      elWidth: 0,
      elHeight: 0,
      resizeTimeout: null,
      dataList: []
    }
  },
  created () {
    this.statusList = JSON.parse(localStorage.getItem('dealStageColorMap'))
    Bus.$off('changeSearch')
    Bus.$on('changeSearch', val => {
      this.status = val.status
      this.type = val.type
      this.assetOwner = val.service
      setTimeout(() => {
        const element = document.getElementsByClassName('fc-daygrid-day-frame')[0]
        this.elWidth = element.offsetWidth
        this.elHeight = element.offsetHeight
        this.getCurrentViewDateRange()
      }, 100)
    })
    window.addEventListener('resize', this.resizeClient)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.resizeClient)
  },
  methods: {
    // handleEventClick (info) {
    //   this.showEvent = true
    //   this.startDate = info.event.startStr
    //   this.dealStage = Object.keys(this.statusList).find(k => this.statusList[k] === info.event.backgroundColor)
    //   this.bgColor = info.event.backgroundColor
    //   this.clientX = info.jsEvent.clientX + 'px'
    //   this.clientY = info.jsEvent.clientY + 'px'
    // },
    // changeEvent () {
    //   this.showEvent = false
    // },
    resizeClient () {
      clearTimeout(this.resizeTimeout)
      this.resizeTimeout = setTimeout(() => {
        const element = document.getElementsByClassName('fc-daygrid-day-frame')[0]
        this.elWidth = element.offsetWidth
        this.elHeight = element.offsetHeight
        this.setData(this.dataList)
      }, 200)
    },
    getCurrentViewDateRange () {
      if (!this.$refs.fullCalendar) return
      this.calendarOptions.events = []
      const calendarApi = this.$refs.fullCalendar.getApi()
      const start = calendarApi.view.activeStart
      const end = calendarApi.view.activeEnd
      end.setDate(end.getDate() - 1)
      // if (moment().valueOf() < moment(end).valueOf()) {
      //   end = new Date()
      // }
      this.loading = true
      this.$axios
        .post('/rental-forecasting/deal/calendar', {
          startDate: moment(start).format('YYYY-MM-DD'),
          endDate: moment(end).format('YYYY-MM-DD'),
          // assetStatus: this.status,
          type: this.type,
          assetOwner: this.assetOwner
        })
        .then(res => {
          this.dataList = res.data
          this.setData(this.dataList)
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          this.$message.error(error)
        })
    },
    setData (data) {
      let events = []
      events = data.map((item, index) => {
        return [
          {
            order: index,
            title: item[this.status[1]],
            start: item.date
          },
          {
            order: index,
            title: item[this.status[0]],
            start: item.date
          }
        ]
      }).flat()
      this.calendarOptions.events = events // 设置事件
      if (this.$refs.fullCalendar) this.$refs.fullCalendar.getApi().refetchEvents() // 让 FullCalendar 重新获取和渲染所有事件
      this.calendarOptions.eventOrder = 'order' // 事件排序
      // 动态设置宽高颜色
      setTimeout(() => {
        const eventsList = document.getElementsByClassName('fc-daygrid-day-events')
        for (let i = 0; i < eventsList.length; i++) {
          const statusWidth1 = eventsList[i].getElementsByClassName('fc-daygrid-event-harness')[0]
          const statusWidth2 = eventsList[i].getElementsByClassName('fc-daygrid-event-harness')[1]
          const width = this.elWidth - 8
          const height = this.elHeight - 40
          statusWidth1.style.cssText = `border-top: ${height}px solid ${this.statusList[this.status[0]]};border-right: ${width}px solid transparent;`
          statusWidth2.style.cssText = `border-bottom: ${height}px solid ${this.statusList[this.status[1]]};border-left: ${width}px solid transparent;margin-left: -${width - 4}px;`
        }
      }, 100)
    }
  }
}
</script>

<style scoped lang="less">
.fc-media-screen {
  width: calc(100% - 10px);
  background-color: #444444;
  margin-top: 12px;
  height: calc(100vh - 130px);
}
/deep/.fc .fc-daygrid-day-top {
  justify-content: center;
  height: 36px;
  line-height: 36px;
}
/deep/.fc-day-today .fc-daygrid-day-number {
  color: #333333;
  background-color: #69AD48;
  font-weight: 700;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  line-height: 16px;
  margin-top: 12px;
  text-align: center;
}
/deep/.fc-button-group {
  margin-left: 84px!important;
}

/deep/.fc-daygrid-day-events {
  display: flex;
  .fc-daygrid-event-harness:nth-child(1) {
    width: 0;
    height: 0;
    //border-top: 130px solid #33AB4F;
    //border-right: 256px solid transparent;
    .fc-event-title {
      position: absolute;
      top: -106px;
      left: 12px;
      width: 200px;
      max-width: 200px;
      font-size: 16px;
      font-weight: 700;
    }
  }
  .fc-daygrid-event-harness:nth-child(2) {
    width: 0;
    height: 0;
    //border-bottom: 130px solid #979797;
    //border-left: 256px solid transparent;
    //margin-left: -256px;
    .fc-event-title {
      position: absolute;
      top: 66px;
      left: -80px;
      width: 200px;
      max-width: 200px;
      font-size: 16px;
      font-weight: 700;
    }
  }
}
/deep/.fc-h-event {
  border: 0;
  cursor: pointer;
}
/deep/ .fc-h-event:hover {
  opacity: .8;
}
/deep/ .fc-day-today .fc-event, .fc-day-sat .fc-event {
  border-radius: 0;
}
/deep/.fc-daygrid-body tr {
  height: 170px;
}
/deep/.fc-theme-standard .fc-popover {
  width: 262px;
  background-color: #333339;
  border-color: #333339;
  .fc-popover-header {
    background-color: #333339;
    padding: 12px 10px 2px;
    .fc-popover-title {
      margin-left: 50px;
      font-size: 14px;
      color: #999999;
      font-weight: 700;
    }
    .fc-popover-close {
      font-size: 24px;
      color: #DBDBDB;
    }
  }
}
/deep/.fc .fc-scroller-liquid-absolute {
  overflow-y: scroll!important;
}
/deep/thead .fc-scroller {
  overflow: hidden!important;
}
</style>
