<template>
<div class="check">
  <div @click="goBack" class="back-box">
    <i class="el-icon-arrow-left"></i>
    <span>Check In</span>
  </div>
  <div class="check-box">
    <div class="title">Check Asset</div>
    <div class="check-input">
      <span>Check by:</span>
      <el-radio>Ticket ID</el-radio>
    </div>
    <el-input v-model="ticketId" clearable placeholder="Input Ticket ID"></el-input>
    <div style="text-align: right;">
      <el-button type="primary" @click="checkIn" :loading="loading">Check</el-button>
    </div>
  </div>
  <div
      v-loading="loading"
      class="assets-box"
      v-if="assetsList.length > 0">
    <div class="tick-info">
      <span>{{tickName}}</span>
      <span><span style="color: #DBDBDB">{{assetsList.length}}</span> Assets</span>
    </div>
    <div class="assets-content">
      <div
        class="assets-item"
        :class="{itemGreen: item.rentalStatus == 'QC pending', itemYellow: item.rentalStatus == 'In Rental' || item.rentalStatus == 'Rental Over, not in warehouse'}"
        v-for="(item, index) in assetsList"
        :key="index">
        <span class="name">{{item.peerId | formatPid}}</span>
        <span class="type">{{item.type}}</span>
        <span class="status">{{item.rentalStatus}}</span>
      </div>
    </div>
    <div class="button-reset" v-if="showReset">
      <el-button type="danger" @click="resetAssets">RED Rental Over Reset</el-button>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'CheckIn',
  data () {
    return {
      ticketId: '',
      tickName: '',
      assetsList: [],
      loading: false,
      showReset: false
    }
  },
  methods: {
    goBack () {
      this.$emit('goBack')
    },
    checkIn () {
      this.loading = true
      this.$axios
        .post('/rental-forecasting/hubSpot/ticket/get', {
          ticketId: this.ticketId
        })
        .then(res => {
          if (res.data.errorCode === '0x0') {
            this.tickName = res.data.result.ticket.ticketName
            this.assetsList = res.data.result.assetList
            if (res.data.result.ticket.pipeline === 'RED Pipeline' || res.data.result.ticket.pipeline === 'Global Rentals') {
              this.showReset = true
            }
          } else {
            this.$message.error(res.data.errorInfo)
          }
          this.loading = false
        })
    },
    resetAssets () {
      this.$confirm('The operation is irreversible.', 'Are you sure you want to perform the “RED Rental Over Reset” operation?', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        type: 'warning'
      }).then(() => {
        const peerIdList = []
        this.assetsList.forEach(item => {
          peerIdList.push(item.peerId)
        })
        this.$axios
          .post('/rental-forecasting/asset/resetAssociateCompany', {
            peerIds: peerIdList.join(',')
          })
          .then(res => {
            if (res.data.errorCode === '0x0') {
              this.$message({
                type: 'success',
                message: 'Reset success.'
              })
              this.assetsList = []
              this.ticketId = ''
              this.showReset = false
            } else {
              this.$message.error(res.data.errorInfo)
            }
          })
      }).catch(() => {
      })
    }
  }
}
</script>

<style scoped lang="less">
.check {
  width: 100%;
  padding: 24px 40px;
  color: #FFFFFF;
  .back-box {
    margin-bottom: 16px;
    cursor: pointer;
    font-size: 16px;
    span {
      margin-left: 10px;
    }
  }
  .check-box {
    padding: 16px 24px;
    background-color: #444444;
    border-radius: 4px;
    .title {
      font-size: 16px;
      color: #999999;
      font-weight: bold;
      margin-bottom: 16px;
    }
    .check-input {
      margin-bottom: 16px;
      span {
        font-size: 14px;
        color: #FFF;
        margin-right: 15px;
      }
    }
  }
  .assets-box {
    margin-top: 16px;
    background-color: #444;
    border-radius: 4px;
    padding: 16px 24px;
    .tick-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #999999;
      font-size: 14px;
      font-weight: 600;
    }
    .assets-content {
      max-height: 430px;
      overflow: auto;
      margin-top: 8px;
      border-radius: 8px;
      background: #333;
      padding: 12px 16px;
      .assets-item {
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 40px;
        line-height: 40px;
        padding-left: 20px;
        border-radius: 4px;
        color: #DBDBDB;
        margin-bottom: 8px;
        background: rgba(229, 100, 100, 0.72);
        .name {
          width: 45%;
        }
        .type {
          width: 30%;
        }
        .status {
          width: 25%;
        }
      }
      .itemGreen {
        background: rgba(105, 173, 72, 0.72);
      }
      .itemYellow {
        background: rgba(255, 181, 36, 0.72);
      }
    }
  }
  .button-reset {
    margin-top: 16px;
    text-align: right;
  }
}
/deep/.el-radio__label {
  color: #DBDBDB!important;
  padding-left: 8px;
}
/deep/.el-input {
  margin-bottom: 16px;
  .el-input__inner {
    background-color: #333333;
    border-color: #333333;
  }
  .el-input__inner::placeholder {
    color: #666666;
  }
}
/deep/.el-button--danger {
  background-color: #444444;
  border-color: #EC5050;
  color: #EC5050;
}
</style>
