<template>
<div class="index">
  <div class="title-box">
    <p class="title">Need to cover a story, but it seems out of your reach?</p>
    <p class="sec-title">TVU provides a complete range of services including equipment rentals, data services and professional production teams for hire to cover your live events successfully from over 180 locations across the globe.</p>
  </div>
  <div class="rent-content" v-if="showPage" ref="pdfContent">
    <div class="rent-title-box">
      <span class="rent-title">Let’s Rent!</span>
      <span class="cart-info" @click="eventNext" v-if="activeStep != 1">
        <span class="cart-num" v-if="cartNum > 0">{{cartNum}}</span>
        <i class="iconfont icon-gouwuche"></i>
        <span>Cart</span>
      </span>
    </div>
    <div class="step-box">
      <el-steps :active="activeStep" finish-status="success" :align-center="true">
        <el-step
            v-for="(item, index) in steps"
            :key="index"
            :title="item.title"
            @click.native.stop="handleStepClick(index)">
        </el-step>
      </el-steps>
    </div>
    <edit-event-info
        v-if="activeStep === 1"
        @eventNext="eventNext"
        :redBullEventNameList="redBullEventNameList">
    </edit-event-info>
    <choose-products
        v-if="activeStep === 2"
        @productNext="productNext">
    </choose-products>
    <confirm-address
        v-if="activeStep === 3"
        :locationList="locationList"
        @addressNext="addressNext">
    </confirm-address>
    <review-confirm
        v-if="activeStep === 4"
        @submitChange="submitChange">
    </review-confirm>
    <confirm-page
        v-if="showConfirm"
        @closeConfirm="closeConfirm"
        :showConfirm="showConfirm"
        :dealId="ticketId"
        :businessEmail="businessContactEmail"
        :shippingEmail="shippingContactEmail">
    </confirm-page>
  </div>
</div>
</template>

<script>
import EditEventInfo from './EditEventInfo'
import ChooseProducts from './ChooseProducts'
import ConfirmAddress from './ConfirmAddress'
import ReviewConfirm from './ReviewConfirm'
import ConfirmPage from '../SimpleRental/ConfirmPage'
import Bus from '../../common/vueBus'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
const moment = require('moment')
export default {
  name: 'Index',
  components: {
    EditEventInfo,
    ChooseProducts,
    ConfirmAddress,
    ReviewConfirm,
    ConfirmPage
  },
  data () {
    return {
      showPage: false,
      activeStep: 1,
      redBullEventNameList: [],
      eventParams: {}, // 第一步的参数
      chooseProductParams: {}, // 第二步的参数
      confirmAddressParams: {}, // 第三步的参数
      sameAsShippingContact: false,
      steps: [
        { title: 'Edit Event Info' },
        { title: 'Choose Products' },
        { title: 'Confirm address' },
        { title: 'Review and confirm' }
      ],
      locationList: [],
      showConfirm: false,
      ticketId: '',
      businessContactEmail: '',
      shippingContactEmail: '',
      cartNum: 0
    }
  },
  mounted () {
    this.getAllOwner()
    this.cartNumChange()
    Bus.$on('cartNumChange', () => {
      this.cartNumChange()
    })
  },
  methods: {
    cartNumChange () {
      const chooseProducts = JSON.parse(localStorage.getItem('chooseProducts') || '{}')
      this.cartNum = chooseProducts.checkedProduct1 + chooseProducts.checkedProduct2 + chooseProducts.checkedProduct3 || 0
    },
    submitChange () {
      this.eventParams = JSON.parse(localStorage.getItem('eventInfo'))
      this.chooseProductParams = JSON.parse(localStorage.getItem('chooseProducts'))
      const lineItemList = []
      if (this.chooseProductParams.checkedProduct1) {
        lineItemList.push({
          productName: 'TVU Pack, 1 Input',
          productId: 0,
          quantity: this.chooseProductParams.chooseNumber1
        })
      }
      if (this.chooseProductParams.checkedProduct2) {
        lineItemList.push({
          productName: 'TVU Pack, 4 Input',
          productId: 1,
          quantity: this.chooseProductParams.chooseNumber2
        })
      }
      if (this.chooseProductParams.checkedProduct3) {
        lineItemList.push({
          productName: 'TVU Receiver, 2 Channels',
          productId: 2,
          quantity: this.chooseProductParams.chooseNumber3
        })
      }
      if (this.chooseProductParams.checkedRequire) {
        lineItemList.push({
          productName: 'Others',
          productId: 2482368583,
          quantity: 1
        })
      }
      const params = { ...this.eventParams, ...this.confirmAddressParams }
      params.lineItemList = lineItemList
      params.sameAsShippingContact = this.sameAsShippingContact
      params.specialRentalProductRequest = this.chooseProductParams.specialRentalProductRequest
      params.internalNumber = this.confirmAddressParams.internalNumber
      if (this.eventParams.redBullEventName.includes('Other')) {
        params.redBullEventName = this.eventParams.eventInformation
      }
      // 删除多余字段
      delete params.eventInformation
      // this.uploadPdf('00099999')
      // return
      // console.log(params)
      this.$axios
        .post('/rental-forecasting/hubSpot/ticket/createRedBullRentalTicket', params)
        .then(res => {
          if (res.data.errorCode === '0x0') {
            this.$message.success('Successful')
            this.showConfirm = true
            this.businessContactEmail = params.businessContactEmail
            this.shippingContactEmail = params.shippingContactEmail
            // 拿到ticketId之后上传pdf文件
            this.ticketId = res.data.result.ticketId
            const name = this.ticketId + '-' + moment().format('YYYYMMDDHHmmss')
            this.uploadPdf(name)
          } else {
            this.$message.error(res.data.errorInfo)
          }
        })
        .catch((error) => {
          this.$message.error(error)
        })
    },
    uploadPdf (name) {
      html2canvas(this.$refs.pdfContent).then(canvas => {
        const pageData = canvas.toDataURL('image/jpeg', 1.0)
        // eslint-disable-next-line new-cap
        const pdf = new jsPDF({
          orientation: 'p',
          unit: 'pt',
          format: 'a4'
        })
        const imgWidth = canvas.width
        const imgHeight = canvas.height
        const a4Width = pdf.internal.pageSize.getWidth()
        const a4Height = pdf.internal.pageSize.getHeight()
        const ratio = Math.min(a4Width / imgWidth, a4Height / imgHeight)
        // 将图片绘制到PDF中
        const imgScaledWidth = imgWidth * ratio
        const imgScaledHeight = imgHeight * ratio
        pdf.addImage(pageData, 'JPEG', 0, 0, imgScaledWidth, imgScaledHeight)
        const pdfBlob = new Blob([pdf.output('blob')], { type: 'application/pdf' })
        const formData = new FormData()
        formData.append('file', pdfBlob, `${name}.pdf`)
        // pdf.save('content.pdf') // 下载pdf文件
        this.$axios
          .post('/rental-forecasting/public/hubSpot/file/upload', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(res => {
            if (res.data.result) {
              this.dealConnectPdf(res.data.result)
            } else {
              this.$message.error(res.data.errorInfo)
            }
          })
          .catch((error) => {
            this.$message.error(error)
          })
      })
    },
    // 关联dealID和pdf文件ID
    dealConnectPdf (id) {
      this.$axios
        .post('/rental-forecasting/public/hubSpot/note/create', {
          ticketId: this.ticketId,
          fileIds: id
        })
        .then(res => {
          if (res.data.result) {
            console.log(res.data.result)
          } else {
            this.$message.error(res.data.errorInfo)
          }
        })
        .catch((error) => {
          this.$message.error(error)
        })
    },
    closeConfirm (print) {
      const url = `eventInfo=${JSON.stringify(this.eventParams)}&chooseProducts=${JSON.stringify(this.chooseProductParams)}&confirmAddress=${JSON.stringify(this.confirmAddressParams)}&confirmAddressChecked=${this.sameAsShippingContact}`
      if (print) {
        window.open(location.origin + '/#/print?' + encodeURIComponent(url), '_blank')
      }
      this.clearInfo()
      this.showConfirm = false
    },
    clearInfo () {
      this.activeStep = 1
      this.eventParams = {}
      this.chooseProductParams = {}
      this.confirmAddressParams = {}
      this.sameAsShippingContact = false
      localStorage.removeItem('eventInfo')
      localStorage.removeItem('chooseProducts')
      localStorage.removeItem('confirmAddress')
      localStorage.removeItem('confirmAddressChecked')
    },
    eventNext () {
      // 第一步的参数
      this.activeStep = 2
    },
    productNext () {
      this.activeStep = 3
    },
    addressNext (params1, params2) {
      this.confirmAddressParams = JSON.parse(params1)
      this.sameAsShippingContact = params2
      this.activeStep = 4
    },
    handleStepClick (index) {
      // 不能切换后边的步骤
      if (index + 1 > this.activeStep) return
      this.activeStep = index + 1
    },
    getAllOwner () {
      this.$axios
        .get('/rental-forecasting/hubSpot/getAllOption')
        .then(res => {
          this.showPage = true
          this.redBullEventNameList = res.data.result.redBullEventNameList
          this.locationList = res.data.result.locationList
        })
        .catch((error) => {
          this.$message.error(error)
        })
    }
  }
}
</script>

<style scoped lang="less">
.index {
  width: 100%;
  min-height: 100%;
  background-image: url(../../assets/images/bgImg.png);
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;

  .title-box {
    width: 500px;
    color: #000;
    position: absolute;
    left: 388px;
    top: 260px;
    //left: 21%;
    //top: 26%;
    .title {
      font-size: 48px;
      line-height: 56px;
    }
    .sec-title {
      margin-top: 20px;
      width: 410px;
      font-size: 14px;
    }
  }

  .rent-content {
    width: 1200px;
    z-index: 999;
    position: absolute;
    background: #FFFFFF;
    border-radius: 6px;
    box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.20);
    padding: 24px 36px;
    top: 80px;
    left: 50%;
    margin-left: -600px;
  }

  .rent-title-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    .rent-title {
      color: #69AD48;
      font-size: 24px;
      font-weight: 500;
    }
    .cart-info {
      display: flex;
      align-items: center;
      position: relative;
      .cart-num {
        background-color: #69AD48;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        line-height: 18px;
        color: #fff;
        font-size: 16px;
        font-weight: 700;
        text-align: center;
        position: absolute;
        left: 2px;
        top: -9px;
      }
      .icon-gouwuche {
        font-size: 24px;
        color: #666666;
      }
      span {
        color: #666666;
        font-size: 14px;
        font-weight: 700;
        margin-left: 8px;
      }
    }
  }

  .step-box {
    width: 1128px;
    border-radius: 4px;
    border: 1px solid #DBDBDB;
    background: #FAFAFA;
    padding: 12px 0;
    margin-top: 24px;
    margin-bottom: 36px;
  }

  .flex-center {
    display: flex;
  }

  /deep/.el-button--success, /deep/.el-button--primary {
    background-color: #69AD48!important;
    border-color: #69AD48!important;
    padding: 12px 25px!important;
  }
  /deep/.el-input {
    .el-input__inner {
      background-color: #FFFFFF;
      border: 1px solid #DBDBDB;
      color: #333333;
      padding-left: 8px;
    }
    .el-input__inner::placeholder {
      color: #CCCCCC;
    }
  }
  /deep/.el-select .el-input .el-select__caret {
    color: #252525;
  }
  /deep/.el-form-item__label {
    color: #000000;
    line-height: 16px;
    margin-bottom: 6px;
  }
  /deep/.el-range-editor {
    background-color: #FFFFFF;
    border: 1px solid #DBDBDB;
  }
  /deep/.el-checkbox__inner {
    background: #FAFAFA!important;
    border-color: #C6C6C6;
  }
  /deep/.el-button.is-disabled {
    background: #8BC194!important;
    border-color: #8BC194!important;
  }
  /deep/.is-checked .el-checkbox__inner {
    background-color: #69AD48!important;
    border-color: #69AD48!important;
  }
  /deep/.el-step {
    cursor: pointer;
  }
}
</style>
