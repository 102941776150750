import Vue from 'vue'

// operation type
Vue.filter('operationType', (value, list) => {
  if (value) {
    for (const i in list) {
      if (value === Number(i)) {
        return list[i]
      }
    }
  } else {
    return ''
  }
})

Vue.filter('formatPid', (value) => {
  const upperCaseStr = value.toUpperCase()
  const formattedStr = upperCaseStr.replace(/(.{4})/g, '$1 ')
  return formattedStr
})
