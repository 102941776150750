<template>
<div class="dashboard-box">
  <Search></Search>
  <div class="dashboard-content" v-loading="loading">
    <div class="left">
      <Deal :dataInfo="dashboardData.deal"></Deal>
      <Ticket :dataInfo="dashboardData.ticket" class="mt-20"></Ticket>
    </div>
    <div class="right">
      <Asset :dataInfo="dashboardData.asset"></Asset>
    </div>
  </div>
</div>
</template>

<script>
import Search from './Search'
import Deal from './Deal'
import Ticket from './Ticket'
import Asset from './Asset'
import Bus from '@/common/vueBus'
export default {
  name: 'Index',
  data () {
    return {
      dashboardData: {},
      loading: false
    }
  },
  components: {
    Search,
    Deal,
    Ticket,
    Asset
  },
  created () {
    Bus.$off('changeSearch')
    Bus.$on('changeSearch', (val) => {
      this.getDashboardInfo(val)
    })
  },
  methods: {
    getDashboardInfo (val) {
      const params = {
        assetOwner: val.service,
        type: val.type,
        startDate: val.startTime,
        endDate: val.endTime
      }
      this.loading = true
      this.$axios
        .post('/rental-forecasting/asset/dashboard', params, {
          isCancelToken: true
        })
        .then(res => {
          if (res.data.errorInfo && res.data.errorInfo.includes('权限不足')) {
            this.$message.error(res.data.errorInfo)
            return
          }
          this.dashboardData = res.data
          this.loading = false
        })
        .catch((error) => {
          this.$message.error(error)
        })
    }
  }
}
</script>

<style scoped lang="less">
.dashboard-box {
  width: 100%;
  .dashboard-content {
    margin-top: 24px;
    display: flex;
    .left {
      width: 50%;
      margin-left: 24px;
      padding-right: 16px;
      border-right: 4px dashed #444;
    }
    .right {
      width: 50%;
      margin-left: 16px;
    }

  }
}
</style>
