<template>
<div class="menu-content">
  <el-menu
      ref="elMenu"
      :default-active="$route.path"
      class="el-menu-vertical-demo"
      @select="selectMenu"
      background-color="#1C1C1C"
      text-color="#FFFFFF"
      active-text-color="#FFFFFF">
    <el-menu-item
        v-for="(items, index) in navMenuList"
        :key="index"
        :index="items.page"
        :disabled="items.isDisabled">
      <i
          class="iconfont menu-icon"
          :class="items.icon"></i>
      <span slot="title">{{items.title}}</span>
    </el-menu-item>
  </el-menu>
</div>
</template>

<script>
export default {
  name: 'Menu',
  data () {
    return {
      navMenuList: [
        // { title: 'Asset&Deal', page: '/forecasting', isDisabled: false, icon: 'icon-list-ul' },
        // { title: 'Forecasting', page: '/redPool', isDisabled: false, icon: 'icon-RectangleCopy' },
        { title: 'Rental Dashboard', page: '/dashboard', isDisabled: false, icon: 'icon-RectangleCopy' },
        { title: 'Calendar', page: '/assets', isDisabled: false, icon: 'icon-calendar' }
        // { title: 'Tool', page: '/assetsTool', isDisabled: false, icon: 'icon-setting' }
      ]
    }
  },
  methods: {
    selectMenu (pathValue) {
      if (pathValue === this.$route.path) return
      this.$router.push({ path: pathValue })
    }
  }
}
</script>

<style scoped lang="less">
.menu-content {
  width: 74px;
  background: #1C1C1C;
  min-height: calc(100vh - 120px);
  padding: 200px 4px;
  .el-menu {
    border: 0;
  }
  .el-menu-item {
    height: 100px;
    line-height: 24px;
    text-align: center;
    padding: 0!important;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
      display: block;
      height: 24px;
      line-height: 24px;
      color: #999999;
      white-space: break-spaces;
      line-height: 18px;
    }
    .menu-icon {
      color: #FFFFFF;
      padding: 8px;
      font-size: 24px;
      color: #999999;
    }
  }
  .el-menu-item.is-active {
    .menu-icon {
      border-radius: 24px;
      background: #444;
      color: #FFFFFF;
    }
    span {
      font-weight: 700;
      color: #FFFFFF;
    }
  }
}
</style>
