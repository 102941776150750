<template>
<div class="header-search">
  <el-select
   clearable
   v-model="service"
   size="small"
   class="m_r_15"
   filterable
   @change="serviceChanges"
   style="width: 205px;margin-left: 24px;">
    <el-option
      v-for="(item, index) in serviceOptions"
      :key="index"
      :label="item"
      :value="item">
    </el-option>
  </el-select>
  <el-date-picker
      v-if="activeTab === 0 || searchType === 'assets0' || searchType === 'assets1'"
      size="small"
      class="date-picker m_r_15"
      :editable="false"
      :clearable="false"
      format="yyyy-MM-dd"
      value-format="yyyy-MM-dd"
      v-model="dateRange"
      type="datetimerange"
      range-separator="To"
      start-placeholder="Start date"
      end-placeholder="End date"
      @change="setDateTimeFromDateRange($event)"
  >
  </el-date-picker>
  <div v-if="activeTab === 1" style="display: flex; align-items: center">
    <div class="asset-type">
      <span>Status 1:</span>
      <el-select
          v-model="status1"
          size="small"
          placeholder="Device"
          @change="SearchData"
          class="m_r_15">
        <el-option
            v-for="(item, index) in statusList"
            :key="index"
            :label="item"
            :value="item"
            :disabled="item === status2">
        </el-option>
      </el-select>
      <span>Status 2:</span>
      <el-select
          v-model="status2"
          size="small"
          placeholder="Device"
          @change="SearchData"
          class="m_r_15">
        <el-option
            v-for="(item, index) in statusList"
            :key="index"
            :label="item"
            :value="item"
            :disabled="item === status1">
        </el-option>
      </el-select>
    </div>
  </div>
  <div v-if="activeTab !== 0">
    <span style="margin-right: 15px;">Asset Type:</span>
    <el-radio-group v-model="type" @change="SearchData">
      <el-radio label="T">Transmitter</el-radio>
      <el-radio label="R">Receiver</el-radio>
    </el-radio-group>
  </div>
  <div v-if="searchType === 'assets1'" style="margin-left: 24px;">
    <el-select
        clearable
        v-model="dealStage"
        size="small"
        class="m_r_15"
        filterable
        placeholder="Deal Stage"
        @change="dealChanges">
      <el-option
          v-for="(item, index) in dealStageList"
          :key="index"
          :label="item"
          :value="item">
      </el-option>
    </el-select>
  </div>
</div>
</template>

<script>
import Bus from '@/common/vueBus'
const moment = require('moment')
export default {
  name: 'HeaderSearch',
  props: {
    activeTab: Number,
    searchType: String
  },
  data () {
    return {
      service: '',
      serviceOptions: [],
      startTime: moment()
        .subtract(7, 'days')
        .format('YYYY-MM-DD'),
      endTime: moment().format('YYYY-MM-DD'), // HH:mm:ss
      dateRange: [],
      type: 'T',
      statusList: [],
      status1: 'Available',
      status2: 'RED Total',
      dealStageList: [],
      dealStage: ''
    }
  },
  created () {
    if (localStorage.getItem('status1')) this.status1 = localStorage.getItem('status1')
    if (localStorage.getItem('status2')) this.status2 = localStorage.getItem('status2')
    if (localStorage.getItem('assetType')) this.type = localStorage.getItem('assetType')
    if (localStorage.getItem('startTime') && localStorage.getItem('endTime')) {
      this.startTime = localStorage.getItem('startTime')
      this.endTime = localStorage.getItem('endTime')
    }
    this.dateRange = [this.startTime, this.endTime]
    this.getAllOwner()
  },
  methods: {
    dealChanges () {
      localStorage.setItem('dealStage', this.dealStage)
      this.SearchData(true)
    },
    serviceChanges () {
      localStorage.setItem('ownerSelect', this.service)
      this.SearchData(true)
    },
    getAllOwner () {
      this.$axios
        .get('/rental-forecasting/hubSpot/getAllOption')
        .then(res => {
          this.serviceOptions = res.data.result.ownerList
          this.dealStageList = res.data.result.dealStageList
          if (localStorage.getItem('dealStage')) {
            this.dealStage = localStorage.getItem('dealStage')
          } else {
            this.dealStage = res.data.result.dealStageList[0]
          }
          this.service = localStorage.getItem('ownerSelect') || ''
          localStorage.setItem('linkMap', JSON.stringify(res.data.result.linkMap))
          this.statusList = Object.keys(res.data.result.assetCustomStatusColorMap)
          localStorage.setItem('dealStageColorMap', JSON.stringify(res.data.result.assetCustomStatusColorMap))
          this.SearchData()
        })
        .catch((error) => {
          this.$message.error(error)
        })
    },
    setDateTimeFromDateRange (_dateRange) {
      this.startTime = _dateRange[0]
      this.endTime = _dateRange[1]
      localStorage.setItem('startTime', this.startTime)
      localStorage.setItem('endTime', this.endTime)
      this.SearchData(true)
    },
    SearchData () {
      localStorage.setItem('status1', this.status1)
      localStorage.setItem('status2', this.status2)
      localStorage.setItem('assetType', this.type)
      const val = {
        service: this.service,
        startTime: this.startTime,
        endTime: this.endTime,
        status: [this.status1, this.status2],
        type: this.type,
        dealStage: this.dealStage
      }
      Bus.$emit('changeSearch', val)
    }
  }
}
</script>

<style scoped lang="less">
.header-search {
  margin-top: 12px;
  display: flex;
  align-items: center;
  /deep/.el-select .el-input__inner {
    border-color: #444444;
    background: #444444;
    border-radius: 4px;
    color: #DBDBDB;
  }
  /deep/.el-input .el-input__inner {
    border-color: #444444;
    background-color: #444444;
  }
  .el-range-editor {
    border-color: #444444;
    background-color: #444444;
  }
  /deep/.el-tag.el-tag--info {
    background-color: #787878;
    border-color: #787878;
    color: #FFFFFF;
  }
  .asset-type {
    //margin-left: 45px;
    span {
      color: #999999;
      font-size: 14px;
      margin-right: 20px;
    }
    /deep/.el-radio__label {
      color: #DBDBDB;
      padding-left: 8px;
    }
  }
}
/deep/.el-range-editor .el-range-input {
  color: #FFFFFF;
}
</style>
