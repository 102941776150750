<template>
<div class="close-deal">
  <div class="back-btn">
    <i class="el-icon-back" @click="backPage"></i>
    <h4>{{stagesName}}</h4>
  </div>
  <el-table
      style="width: 100%;cursor: pointer;box-shadow:0px 2px 6px 0px rgba(0,0,0,0.2);"
      v-loading="loading"
      class="listTable dynamic_column"
      :data="tableData"
      element-loading-background="rgba(0, 0, 0, 0.6)"
      stripe
  >
    <el-table-column
        width="340"
        label="Deal Name"
        prop="dealName"
        show-overflow-tooltip
    >
      <template slot-scope="scope">
        <span
            class="stages"
            @click="openLink(scope.row.dealId)">{{scope.row.dealName}}</span>
      </template>
    </el-table-column>
    <el-table-column
        width="80"
        label="T Count"
        prop="transmitterCount"
        show-overflow-tooltip
    >
    </el-table-column>
    <el-table-column
        width="80"
        label="R Count"
        prop="receiverCount"
        show-overflow-tooltip
    >
    </el-table-column>
    <el-table-column
        label="Expected Out Date"
        prop="expectedOutDate"
        show-overflow-tooltip
    >
    </el-table-column>
    <el-table-column
        label="Delivery Date"
        prop="ticketOutDate"
        show-overflow-tooltip
    >
    </el-table-column>
    <el-table-column
        label="Rental End Date"
        prop="ticketInDate"
        show-overflow-tooltip
    >
    </el-table-column>
    <el-table-column
        label="Expected In Date"
        prop="expectedInDate"
        show-overflow-tooltip
    >
    </el-table-column>
  </el-table>
</div>
</template>

<script>
export default {
  name: 'ClosedDeal',
  props: {
    detailData: Array,
    stages: String
  },
  data () {
    return {
      tableData: this.detailData,
      loading: false,
      stagesName: this.stages
    }
  },
  watch: {
    detailData (newVal) {
      this.tableData = newVal
    },
    stagesName (newVal) {
      this.stagesName = newVal
    }
  },
  methods: {
    backPage () {
      this.$emit('changeDetail', null)
    },
    openLink (id) {
      // const url = `https://app.hubspot.com/contacts/19963008/deal/${id}`
      const url = `${JSON.parse(localStorage.getItem('linkMap')).deal}${id}`
      window.open(url)
    }
  }
}
</script>

<style scoped lang="less">
.close-deal {
  width: 60%;
}
.back-btn {
  display: flex;
  align-items: center;
  i {
    font-size: 16px;
    font-weight: 600;
    color: #5cbc72;
    cursor: pointer;
    margin-right: 10px;
  }
}
.stages {
  text-decoration: underline;
  color: #5cbc72;
}
</style>
