<template>
<div class="qc-status">
  <div v-if="!showSetting">
    <div @click="goBack" class="back-box">
      <i class="el-icon-arrow-left"></i>
      <span>QC Status</span>
    </div>
    <div class="item-box">
      <div class="item" style="margin-right: 88px;" @click="toStatus(1)">
        <i class="el-icon-success"></i>
        <span class="success-text">QC Pass</span>
      </div>
      <div class="item" @click="toStatus(2)">
        <i class="el-icon-error"></i>
        <span class="failed-text">QC Failed</span>
      </div>
    </div>
  </div>
  <status-set
      v-else
      :type="type"
      @backStatus="backStatus"
  ></status-set>
</div>
</template>

<script>
import StatusSet from './StatusSet'
export default {
  name: 'QcStatus',
  components: {
    StatusSet
  },
  data () {
    return {
      showSetting: false,
      type: null
    }
  },
  methods: {
    goBack () {
      this.$emit('goBack')
    },
    toStatus (type) {
      this.type = type
      this.showSetting = true
    },
    backStatus () {
      this.showSetting = false
    }
  }
}
</script>

<style scoped lang="less">
.qc-status {
  width: 100%;
  padding: 24px 40px;
  color: #FFFFFF;
  .back-box {
    margin-bottom: 16px;
    cursor: pointer;
    font-size: 16px;
    span {
      margin-left: 10px;
    }
  }
  .item-box {
    display: flex;
    justify-content: center;
    .item {
      margin-top: 56px;
      cursor: pointer;
      width: 196px;
      height: 196px;
      border-radius: 12px;
      border: 2px solid #444;
      background: #444;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      i {
        font-size: 48px;
      }
      .el-icon-success, .success-text {
        color: #33AB4F;
      }
      .el-icon-error, .failed-text {
        color: #EC5050;
      }
      span {
        font-size: 16px;
        color: #f0f0f0;
        margin-top: 16px;
      }
    }
  }
}
</style>
