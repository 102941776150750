import Moment from 'moment'
const tools = {
  formatTime (timeString) {
    timeString = timeString.replace(/-/g, '/') // 兼容safari浏览器，safari只支持2021/6/17格式，不支持2021-6-17格式
    const target = new Date(timeString).getTime() / 1000
    return target
  },
  // 时间戳格式化时间格式
  getFullTime (timeStamp) {
    if (!timeStamp) {
      return ''
    }
    const stamp = new Date(Number(timeStamp))
    const time = Moment(stamp).format('YYYY-MM-DD HH:mm:ss')
    return time
  },
  // 截取参数值,传入参数名就行了,针对vue项目，#hash值在search前面的情况也考虑到了
  getQueryString (name, str, symbol) {
    const search =
      window.location.search === ''
        ? window.location.hash
        : window.location.search
    str = str == null ? search : str
    symbol = symbol == null ? '&' : symbol
    const reg = new RegExp(
      '(\\?|&)' + name + '=([^' + symbol + ']*)(' + symbol + '|$)',
      'i'
    )
    const r = str.match(reg)
    if (r != null) return unescape(r[2])
    return null
  },
  transTimeByms (value) {
    if (Number.isNaN(value) || value === '0' || !value) return
    value = Number(value / 1000)
    const dayT = Math.floor(value / (60 * 60 * 24))
    const hours = Math.floor(value / (60 * 60) - (dayT * 24))
    const minutes = Math.floor((value / 60) % 60)
    const seconds = Math.floor((value % 60) % 60)
    const d = dayT ? `${dayT}Days ` : ''
    const h = hours ? `${hours}H ` : ''
    const m = minutes ? `${minutes}M ` : ''
    const s = seconds ? `${seconds}S` : ''
    return d + h + m + s
  },
  convertTimeByh (value) {
    if (Number.isNaN(value) || value === '0') return '---'
    value = Number(value)
    let hours = value / (60 * 60).toFixed(2)
    hours = hours.toFixed(2)
    const h = hours ? `${hours} ` : ''
    return h
  },
  getSession () {
    let session = ''
    const cookieArr = document.cookie.split(';')
    for (const v of cookieArr) {
      if (v.split('=')[0].toLowerCase().trim() === 'sid') {
        session = v.split('=')[1]
        break
      }
    }
    window.localStorage.setItem('session', session)
    return session
  },
  convertMSByh (value) {
    if (Number.isNaN(value) || value === '0') return '0.00'
    value = Number(value)
    const hours = value / (1000 * 60 * 60)
    // const h = hours ? `${hours.toFixed(2)} ` : hours
    return hours.toFixed(2)
  },
  getMonthDayYear (timeStamp) {
    if (!timeStamp) {
      return ''
    }
    const stamp = new Date(Number(timeStamp))
    const time = Moment(stamp).format('MMM-DD-YYYY')
    return time
  }
}
export default tools
