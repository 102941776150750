<template>
<div class="index">
  <el-form
      disabled
      ref="formRental"
      class="form-rental">
    <div class="title-first" style="display: flex; justify-content: space-between; padding-right: 22px;">
      Business Contact Information
      <el-button :disabled="false" type="primary" @click="goPrint">Print</el-button>
    </div>
    <div class="flex-center">
      <el-form-item prop="businessContactFirstName">
        <el-input v-model="formRental.businessContactFirstName" placeholder="First Name"></el-input>
      </el-form-item>
      <el-form-item prop="businessContactLastName">
        <el-input v-model="formRental.businessContactLastName" placeholder="Last Name"></el-input>
      </el-form-item>
    </div>
    <div class="flex-center">
      <el-form-item prop="businessContactCompany">
        <el-input v-model="formRental.businessContactCompany" placeholder="companyName"></el-input>
      </el-form-item>
      <el-form-item prop="businessContactEmail">
        <el-input v-model="formRental.businessContactEmail" placeholder="*Email Address"></el-input>
      </el-form-item>
    </div>
    <div class="flex-center">
      <el-form-item>
        <el-input v-model="formRental.businessContactPhone" placeholder="Phone Number"></el-input>
      </el-form-item>
      <el-form-item>
        <el-input v-model="formRental.businessContactText" placeholder="other communication text"></el-input>
      </el-form-item>
    </div>
    <div class="flex-center">
      <el-form-item prop="businessContactCountry">
        <el-input v-model="formRental.businessContactCountry" placeholder="*Country/Region"></el-input>
      </el-form-item>
      <el-form-item prop="businessContactState">
        <el-input v-model="formRental.businessContactState" placeholder="*State/Province"></el-input>
      </el-form-item>
    </div>

    <div style="display: flex; justify-content: space-between;">
      <div class="title">Shipping Contact Details</div>
      <span style="font-weight: normal; font-size: 14px;">
        <el-checkbox v-model="checked"></el-checkbox>
        <span style="margin-left: 5px;color: #000000;">Same as business Contact</span>
      </span>
    </div>
    <div class="flex-center">
      <el-form-item prop="shippingContactFirstName">
        <el-input v-model="formRental.shippingContactFirstName" placeholder="First Name"></el-input>
      </el-form-item>
      <el-form-item prop="shippingContactLastName">
        <el-input v-model="formRental.shippingContactLastName" placeholder="Last Name"></el-input>
      </el-form-item>
    </div>
    <div class="flex-center">
      <el-form-item prop="shippingContactCompany">
        <el-input v-model="formRental.shippingContactCompany" placeholder="companyName"></el-input>
      </el-form-item>
      <el-form-item prop="shippingContactEmail">
        <el-input v-model="formRental.shippingContactEmail" placeholder="*Email Address"></el-input>
      </el-form-item>
    </div>
    <div class="flex-center">
      <el-form-item>
        <el-input v-model="formRental.shippingContactPhone" placeholder="Phone Number"></el-input>
      </el-form-item>
      <el-form-item>
        <el-input v-model="formRental.shippingContactText" placeholder="other communication text"></el-input>
      </el-form-item>
    </div>
    <div class="flex-center">
      <el-form-item prop="shippingContactCountry">
        <el-input v-model="formRental.shippingContactCountry" placeholder="*Country/Region"></el-input>
      </el-form-item>
      <el-form-item prop="shippingContactState">
        <el-input v-model="formRental.shippingContactState" placeholder="*State/Province"></el-input>
      </el-form-item>
    </div>

    <div class="title-first">Shipping Information</div>
    <div class="title">Shipping Address</div>
    <el-form-item prop="shippingAddress1">
      <el-input v-model="formRental.shippingAddress1" placeholder="Shipping Address Line 1"></el-input>
    </el-form-item>
    <div class="flex-center">
      <el-form-item prop="shippingAddress2">
        <el-input v-model="formRental.shippingAddress2" placeholder="Shipping Address Line 2(Optional)"></el-input>
      </el-form-item>
      <el-form-item prop="buildingName">
        <el-input v-model="formRental.buildingName" placeholder="Building Name"></el-input>
      </el-form-item>
    </div>
    <div class="flex-center">
      <el-form-item prop="shippingCountry">
        <el-input v-model="formRental.shippingCountry" placeholder="*Country/Region"></el-input>
      </el-form-item>
      <el-form-item prop="shippingState">
        <el-input v-model="formRental.shippingState" placeholder="*State/Province"></el-input>
      </el-form-item>
    </div>
    <div class="flex-center">
      <el-form-item prop="shippingCity">
        <el-input v-model="formRental.shippingCity" placeholder="*City"></el-input>
      </el-form-item>
      <el-form-item prop="shippingZipCode">
        <el-input v-model="formRental.shippingZipCode" placeholder="Postal Code"></el-input>
      </el-form-item>
    </div>
    <div class="title">Delivery Date > Return Date</div>
    <el-form-item prop="formDate" required>
      <el-date-picker
          v-model="formRental.formDate"
          type="daterange"
          range-separator=""
          start-placeholder="*Date Selection Window"
          end-placeholder="">
      </el-date-picker>
    </el-form-item>
    <el-form-item prop="deliveryService">
      <el-input v-model="formRental.deliveryService" placeholder="Delivery Service"></el-input>
    </el-form-item>
    <el-form-item prop="customizedShippingDetails" v-if="formRental.deliveryService === 'Customized'">
      <el-input v-model="formRental.customizedShippingDetails " placeholder="Please Specify"></el-input>
    </el-form-item>
    <el-form-item prop="eventInformation">
      <el-input v-model="formRental.eventInformation" placeholder="Event Information"></el-input>
    </el-form-item>
    <div class="product-content">
      <div v-for="(item, index) in productSelectList" :key="index">
        <span class="name">{{item.name}}</span>
        <el-input-number v-model="item.number" :min="1" label=""></el-input-number>
        <span style="margin: 0 20px;" v-if="item.price">${{item.price}}</span>
        <el-input class="special-rental-product-request" v-if="item.productId === otherProductId" type="primary" v-model="specialRentalProductRequest"></el-input>
      </div>
    </div>
    <div class="flex-center">
      <span style="color: #000000;font-size: 14px; margin-right: 10px;line-height: 40px;height: 40px;">Referral Code</span>
      <el-form-item>
        <el-input v-model="referralCode" placeholder=""></el-input>
      </el-form-item>
    </div>
  </el-form>
</div>
</template>

<script>
export default {
  name: 'PrintPage',
  data () {
    return {
      formRental: {},
      checked: false,
      productList: [],
      referralCode: '',
      lineItemList: [],
      productSelectList: [],
      specialRentalProductRequest: '',
      otherProductId: ''
    }
  },
  created () {
    // this.productList = JSON.parse(localStorage.getItem('productList'))
    // this.lineItemList = JSON.parse(localStorage.getItem('lineItemList'))
    this.productSelectList = JSON.parse(localStorage.getItem('productSelectList'))
    this.formRental = JSON.parse(localStorage.getItem('formRental'))
    this.otherProductId = JSON.parse(localStorage.getItem('otherProductId'))
    this.specialRentalProductRequest = localStorage.getItem('specialRentalProductRequest')
    this.checked = localStorage.getItem('checked') === 'true'
    this.referralCode = localStorage.getItem('referralCode')
  },
  methods: {
    goPrint () {
      window.print()
    }
  }
}
</script>

<style scoped lang="less">
.index {
  width: 100%;
  min-height: 100vh;
  background: #F4F8FA;
  padding: 30px 0;
  .form-rental {
    background: #FFF;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.22);
    width: 720px;
    margin: 0 auto;
    padding: 40px;
    /deep/.el-form-item {
      margin-right: 20px;
      .el-input {
        .el-input__inner {
          border-radius: 4px;
          background: #F6F6F6;
          border-color: #F6F6F6;
          //width: 300px;
          color: #333333;
        }
        .el-input__inner::placeholder {
          color: #cccccc;
        }
      }
      .event-input .el-input__inner,.event-input .el-textarea__inner {
        background: #F6F6F6;
        border-color: #F6F6F6;
        width: 620px;
      }
    }
    /deep/.el-date-editor {
      width: 620px;
      background: #F6F6F6;
      border-color: #F6F6F6;
      .el-range-editor .el-range-input{
        color: #333333;
      }
      .el-range-separator {
        color: #444444;
      }
    }
    .title-first {
      color: #000000;
      font-weight: 500;
      font-size: 20px;
      margin-bottom: 20px;
    }
    .title {
      width: 50%;
      display: inline-block;
      color: #000000;
      font-weight: 500;
      font-size: 14px;
      margin-bottom: 10px;
      .red {
        color: #F00;
      }
    }
    /deep/.el-button--primary {
      background: #69AD48;
      border-color: #69AD48;
    }
    /deep/.el-select .el-input .el-select__caret {
      color: #999999;
      font-weight: 700;
    }
    .error {
      color: #F56C6C;
      font-size: 12px;
      line-height: 12px;
      margin-top: 2px;
    }
  }
}
.flex-center {
  display: flex;
  /deep/.el-input__inner {
    width: 300px;
  }
}
/deep/.el-checkbox__inner {
  background: #FFFFFF!important;
}
/deep/.el-checkbox .el-checkbox__input.is-checked .el-checkbox__inner {
  background: #0C6!important;
}
.product-form {
  /deep/.el-input__inner {
    width: 300px;
  }
}
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #FFFFFF!important;
  color: #333333;
}
.agree-content {
  display: flex;
  color: #000;
  font-size: 15px;
  justify-content: center;
  padding: 20px 0;
  span {
    margin-left: 10px;
  }
}
.name-code {
  color: #33AB4F;
  margin-left: 4px;
}

.product-content {
  background-color: #F6F6F6;
  padding: 20px 10px;
  color: #333333;
  font-size: 14px;
  margin-bottom: 20px;
  div {
    margin-bottom: 10px;
  }
  .name {
    margin-right: 20px;
    display: inline-block;
    width: 270px;
  }
}
@media (max-width: 760px) {
  .index {
    padding: 10px 0;
    .form-rental {
      width: 90%;
      padding: 20px;
      /deep/.el-date-editor {
        width: 100%;
      }
    }
  }
  .flex-center {
    display: block;
    /deep/.el-input__inner, /deep/.el-select {
      width: 100%;
    }
  }
  .product-content {
    .name {
      margin-bottom: 2px;
    }
    .price {
      margin: 0 12px;
    }
  }
}
</style>
