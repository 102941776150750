<template>
<div class="index">
  <div v-if="!showCheck && !showQc" class="item" style="margin-right: 88px;" @click="toCheck">
    <img src="../../assets/images/checkIn.png" alt="">
    <span>Check In</span>
  </div>
  <div v-if="!showCheck && !showQc" class="item" @click="toStatus">
    <img src="../../assets/images/setting.png" alt="">
    <span>QC Status</span>
  </div>
  <check-in v-if="showCheck" @goBack="goBack"></check-in>
  <qc-status v-if="showQc" @goBack="goBack"></qc-status>
</div>
</template>

<script>
import CheckIn from './CheckIn'
import QcStatus from './QcStatus'
export default {
  name: 'Index',
  components: {
    CheckIn,
    QcStatus
  },
  data () {
    return {
      showCheck: false,
      showQc: false
    }
  },
  methods: {
    toCheck () {
      this.showCheck = true
    },
    toStatus () {
      this.showQc = true
    },
    goBack () {
      this.showCheck = false
      this.showQc = false
    }
  }
}
</script>

<style scoped lang="less">
.index {
  margin: 0 auto;
  width: 60%;
  display: flex;
  background-color: #333333;
  justify-content: center;
  .item {
    margin-top: 56px;
    cursor: pointer;
    width: 196px;
    height: 196px;
    border-radius: 12px;
    border: 2px solid #444;
    background: #444;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      width: 48px;
      height: 48px;
    }
    span {
      font-size: 16px;
      color: #f0f0f0;
      margin-top: 16px;
    }
  }

}
</style>
