<template>
<div class="asset-summary">
  <h4>RED Pool Asset Summary</h4>
  <el-table
      style="width: 100%;cursor: pointer;box-shadow:0px 2px 6px 0px rgba(0,0,0,0.2);"
      class="listTable dynamic_column"
      :data="tableData"
      element-loading-background="rgba(0, 0, 0, 0.6)"
      stripe
  >
    <el-table-column
        label="T Total"
        prop="totalTCount"
        show-overflow-tooltip
    >
    </el-table-column>
    <el-table-column
        label="R Total"
        prop="totalRCount"
        show-overflow-tooltip
    >
    </el-table-column>
  </el-table>
</div>
</template>

<script>
export default {
  name: 'AssetSummary',
  props: {
    summaryData: Array
  },
  data () {
    return {
      tableData: this.summaryData
    }
  },
  watch: {
    summaryData (newVal) {
      this.tableData = newVal
    }
  }
}
</script>

<style scoped lang="less">
.asset-summary {
  width: 40%;
  margin-left: 50px;
}
</style>
