<template>
<div class="red-pool">
  <div class="red-pool-search">
    <header-search :activeTab="0"></header-search>
    <el-button
        v-if="false"
        disabled
        class="export-btn"
        size="small"
        type="primary"
        @click="excelBasicData()"
        :loading="excelBasicLoading"
    >Export</el-button>
  </div>
  <div
      v-loading="loading"
      class="table-content"
      v-if="optionData.length > 0">
    <h-s-deal
      @changeDetail="changeDetail"
      v-if="!showDetail"
      :optionData="optionData"></h-s-deal>
    <closed-deal
      @changeDetail="changeDetail"
      v-if="showDetail"
      :stages="stages"
      :detailData="detailData"></closed-deal>
    <asset-summary :summaryData="summaryData"></asset-summary>
  </div>
</div>
</template>

<script>
import Bus from '@/common/vueBus'
import HeaderSearch from '../../components/HeaderSearch'
import HSDeal from './HSDeal'
import AssetSummary from './AssetSummary'
import ClosedDeal from './ClosedDeal'
export default {
  name: 'RedList',
  components: { HeaderSearch, HSDeal, AssetSummary, ClosedDeal },
  data () {
    return {
      excelBasicLoading: false,
      optionData: [],
      summaryData: [],
      loading: false,
      showDetail: false,
      detailData: [],
      stages: '',
      startTime: '',
      endTime: '',
      service: ''
    }
  },
  created () {
    Bus.$off('changeSearch')
    Bus.$on('changeSearch', (val) => {
      this.startTime = val.startTime
      this.endTime = val.endTime
      this.service = encodeURIComponent(val.service)
      this.getData()
      if (this.showDetail) this.changeDetail(null)
    })
  },
  methods: {
    getData () {
      const url = `/rental-forecasting/deal/statistic?assetOwner=${this.service}&startDate=${this.startTime}&endDate=${this.endTime}`
      this.loading = true
      this.$axios
        .get(url)
        .then(res => {
          this.summaryData = [{ totalTCount: res.data.summary.totalTCount, totalRCount: res.data.summary.totalRCount }]
          const newArray = Object.entries(res.data).map(([stages, data]) => {
            // eslint-disable-next-line array-callback-return
            if (stages === 'summary') return
            return { stages, ...data }
          }).filter(Boolean)
          this.optionData = newArray
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          this.$message.error(error)
        })
    },
    changeDetail (val) {
      if (val) {
        this.detailData = val.dealList
        this.stages = val.stages
      }
      this.showDetail = !this.showDetail
    },
    excelBasicData () {}
  }
}
</script>

<style scoped lang="less">
.red-pool {
  width: 100%;
}
.export-btn {
  margin: 10px 0;
}
.red-pool-search {
  padding: 0 24px;
}
.table-content {
  display: flex;
  padding: 24px;
}
</style>
