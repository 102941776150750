<template>
<div class="review-confirm" :class="{'print-page': isPrint}">
  <div class="information">
    <div class="title">Event Information</div>
    <div class="info-content">
      <div class="info-box">
        <p>
          <span class="name">Receive and Return Date: </span>
          <span class="info">{{eventInfo.startDate}} - {{eventInfo.endDate}}</span>
          <span class="name">Event Name: </span>
          <span class="info">{{eventInfo.redBullEventName}}</span>
          <span class="name" v-if="eventInfo.redBullEventName == 'Other'">Specify Event Name: </span>
          <span class="info" v-if="eventInfo.redBullEventName == 'Other'">{{eventInfo.eventInformation}}</span>
        </p>
      </div>
      <div class="info-box">
        <p>
          <span class="name">Internal Number: </span>
          <span class="info">{{confirmAddress.internalNumber || '---'}}</span>
        </p>
      </div>
    </div>
  </div>
  <div class="information">
    <div class="title">Business Contact and Shipping information</div>
    <div class="info-content">
      <div class="info-box">
        <div class="info-title">Business Contact</div>
        <p>
          <span class="name">First Name: </span>
          <span class="info">{{confirmAddress.businessContactFirstName}}</span>
          <span class="name">Last Name: </span>
          <span class="info">{{confirmAddress.businessContactLastName}}</span>
        </p>
        <p>
          <span class="name">Company Name: </span>
          <span class="info">{{confirmAddress.businessContactCompany || '---'}}</span>
        </p>
        <p>
          <span class="name">Email Address: </span>
          <span class="info">{{confirmAddress.businessContactEmail}}</span>
        </p>
        <p>
          <span class="name">Phone Number: </span>
          <span class="info">{{confirmAddress.businessContactPhone}}</span>
        </p>
        <p>
          <span class="name">Other Communication Method: </span>
          <span class="info">{{confirmAddress.businessContactCommunicationType || '---'}} - {{confirmAddress.businessOtherCommunicationMethods || '---'}}</span>
        </p>
        <p>
          <span class="name">Country/Region: </span>
          <span class="info">{{confirmAddress.businessContactCountry}}</span>
          <span class="name">State/Province: </span>
          <span class="info">{{confirmAddress.businessContactState || '---'}}</span>
        </p>
      </div>
      <div class="info-box">
        <div class="info-title">Shipping Information</div>
        <p>
          <span class="name">First Name: </span>
          <span class="info">{{confirmAddress.shippingContactFirstName}}</span>
          <span class="name">Last Name: </span>
          <span class="info">{{confirmAddress.shippingContactLastName}}</span>
        </p>
        <p>
          <span class="name">Company Name: </span>
          <span class="info">{{confirmAddress.shippingContactCompany || '---'}}</span>
        </p>
        <p>
          <span class="name">Email Address: </span>
          <span class="info">{{confirmAddress.shippingContactEmail}}</span>
        </p>
        <p>
          <span class="name">Phone Number: </span>
          <span class="info">{{confirmAddress.shippingContactPhone}}</span>
        </p>
        <p>
          <span class="name">Other Communication Method: </span>
          <span class="info">{{confirmAddress.shippingContactCommunicationType || '---'}} - {{confirmAddress.shippingOtherCommunicationMethods || '---'}}</span>
        </p>
        <p>
          <span class="name">Shipping Address: </span>
          <span class="info">{{confirmAddress.shippingAddress1 || '---'}} {{confirmAddress.shippingAddress2 || '---'}}</span>
        </p>
        <p>
          <span class="name">Building Name: </span>
          <span class="info">{{confirmAddress.buildingName || '---'}}</span>
        </p>
        <p>
          <span class="name">Country/Region: </span>
          <span class="info">{{confirmAddress.shippingCountry}}</span>
          <span class="name">State/Province: </span>
          <span class="info">{{confirmAddress.shippingState || '---'}}</span>
        </p>
        <p>
          <span class="name">City: </span>
          <span class="info">{{confirmAddress.shippingCity}}</span>
          <span class="name">Postal Code: </span>
          <span class="info">{{confirmAddress.shippingZipCode || '---'}}</span>
        </p>
      </div>
    </div>
  </div>
  <div class="cart-info">
    <span class="title">Shopping Cart</span>
    <div class="date-box">
      <span>Rental Period:</span>
      <el-date-picker
          @change="changeDate"
          size="medium"
          style="width: 100%;"
          v-model="formDate"
          :picker-options="pickerOptions"
          type="daterange"
          :editable="false"
          range-separator="-"
          start-placeholder="Receive and Return Date"
          end-placeholder="">
      </el-date-picker>
    </div>
  </div>
  <div class="product">
    <div class="title-box">
      <span>Equipment  Info</span>
      <span>Quantity</span>
      <span style="padding-left: 40px;">Operation</span>
    </div>
    <div class="product-item" v-if="checkedProduct1">
      <span class="product-name">
        <img src="../../assets/images/TVUPack1.png" alt="">
        <span>TVU Pack, 1 Input</span>
      </span>
      <el-input-number v-model="chooseNumber1" size="medium" @change="handleChange" :min="1"></el-input-number>
      <span class="operation" @click="remove('1')">Remove</span>
    </div>
    <div class="product-item" v-if="checkedProduct2">
      <span class="product-name">
        <img src="../../assets/images/TVUPack1.png" alt="">
        <span>TVU Pack, 4 Input</span>
      </span>
      <el-input-number v-model="chooseNumber2" size="medium" @change="handleChange" :min="1"></el-input-number>
      <span class="operation" @click="remove('2')">Remove</span>
    </div>
    <div class="product-item" v-if="checkedProduct3">
      <span class="product-name">
        <img src="../../assets/images/receiver.png" alt="">
        <span>TVU Receiver, 2 Channels</span>
      </span>
      <el-input-number v-model="chooseNumber3" size="medium" @change="handleChange" :min="1"></el-input-number>
      <span class="operation" @click="remove('3')">Remove</span>
    </div>
    <div class="product-item" v-if="checkedRequire">
      <span class="product-name">
        <img src="../../assets/images/new-logo.png" alt="">
        <span>Additional Product Requirements</span>
      </span>
      <span class="request-text" v-if="!showEdit" @click="showEditChanges">{{specialRentalProductRequest}}</span>
      <el-input ref="textareaInput" v-if="showEdit" type="textarea" :rows="4" v-model="specialRentalProductRequest" @blur="showEdit = !showEdit" @change="handleChange" placeholder="Enter your additional product requirements"></el-input>
      <span class="operation" @click="remove('4')">Remove</span>
    </div>
  </div>
  <div class="text_right">
    <el-button type="primary" @click="onSubmit">Submit</el-button>
  </div>
</div>
</template>

<script>
import Bus from '../../common/vueBus'
import { getDecodeUrlParam } from '@/assets/js/utils'
const moment = require('moment')

export default {
  name: 'ReviewConfirm',
  data () {
    return {
      formDate: [],
      pickerOptions: {
        disabledDate (time) {
          // 获取今天凌晨的时间戳
          // const today = new Date(new Date().toLocaleDateString()).getTime()
          const today = moment().startOf('day').valueOf()
          // console.log(today, time.getTime(), time.getTime() < today)
          return time.getTime() < today
        }
      },
      address: '',
      checkedProduct1: false,
      checkedProduct2: false,
      checkedProduct3: false,
      checkedRequire: false,
      chooseNumber1: 1,
      chooseNumber2: 1,
      chooseNumber3: 1,
      specialRentalProductRequest: '',
      chooseProducts: {},
      eventInfo: {},
      confirmAddress: '',
      showEdit: false,
      isPrint: false
    }
  },
  mounted () {
    if (this.$route.path === '/print') {
      this.isPrint = true
      this.eventInfo = JSON.parse(getDecodeUrlParam('eventInfo') || '{}')
      this.chooseProducts = JSON.parse(getDecodeUrlParam('chooseProducts') || '{}')
      this.confirmAddress = JSON.parse(getDecodeUrlParam('confirmAddress') || '{}')
    } else {
      this.isPrint = false
      this.eventInfo = JSON.parse(localStorage.getItem('eventInfo') || '{}')
      this.chooseProducts = JSON.parse(localStorage.getItem('chooseProducts') || '{}')
      this.confirmAddress = JSON.parse(localStorage.getItem('confirmAddress') || '{}')
    }
    this.formDate = [this.eventInfo.startDate, this.eventInfo.endDate]
    const {
      checkedProduct1,
      checkedProduct2,
      checkedProduct3,
      checkedRequire,
      specialRentalProductRequest,
      chooseNumber1,
      chooseNumber2,
      chooseNumber3
    } = this.chooseProducts
    this.checkedProduct1 = checkedProduct1 || this.checkedProduct1
    this.checkedProduct2 = checkedProduct2 || this.checkedProduct2
    this.checkedProduct3 = checkedProduct3 || this.checkedProduct3
    this.checkedRequire = checkedRequire || this.checkedRequire
    this.chooseNumber1 = chooseNumber1 || this.chooseNumber1
    this.chooseNumber2 = chooseNumber2 || this.chooseNumber2
    this.chooseNumber3 = chooseNumber3 || this.chooseNumber3
    this.specialRentalProductRequest = specialRentalProductRequest || this.specialRentalProductRequest
  },
  methods: {
    showEditChanges () {
      this.showEdit = !this.showEdit
      // 使input自动获取焦点
      this.$nextTick(() => {
        this.$refs.textareaInput.focus()
      })
    },
    handleChange () {
      if (this.checkedProduct1) {
        this.chooseProducts.chooseNumber1 = this.chooseNumber1
      }
      if (this.checkedProduct2) {
        this.chooseProducts.chooseNumber2 = this.chooseNumber2
      }
      if (this.checkedProduct3) {
        this.chooseProducts.chooseNumber3 = this.chooseNumber3
      }
      if (this.checkedRequire) {
        this.chooseProducts.specialRentalProductRequest = this.specialRentalProductRequest
      }
      localStorage.setItem('chooseProducts', JSON.stringify(this.chooseProducts))
    },
    remove (type) {
      if (type === '1') {
        this.checkedProduct1 = false
      } else if (type === '2') {
        this.checkedProduct2 = false
      } else if (type === '3') {
        this.checkedProduct3 = false
      } else if (type === '4') {
        this.checkedRequire = false
      }
      this.chooseProducts.checkedProduct1 = type === '1' ? false : this.checkedProduct1
      this.chooseProducts.checkedProduct2 = type === '2' ? false : this.checkedProduct2
      this.chooseProducts.checkedProduct3 = type === '3' ? false : this.checkedProduct3
      this.chooseProducts.checkedRequire = type === '3' ? false : this.checkedRequire
      localStorage.setItem('chooseProducts', JSON.stringify(this.chooseProducts))
      Bus.$emit('cartNumChange')
    },
    changeDate () {
      console.log(this.formDate)
      const start = moment(this.formDate[0]).format('YYYY-MM-DD')
      const end = moment(this.formDate[1]).format('YYYY-MM-DD')
      this.eventInfo.startDate = start
      this.eventInfo.endDate = end
      // 第一步的数据存储
      localStorage.setItem('eventInfo', JSON.stringify(this.eventInfo))
    },
    onSubmit () {
      if (!this.checkedProduct1 && !this.checkedProduct2 && !this.checkedProduct3) {
        this.$message.error('Please select at least one product')
      } else {
        this.$confirm('', 'Confirm Rental Request', {
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          customClass: 'review-confirm-box',
          type: 'warning'
        }).then(() => {
          this.$emit('submitChange')
        }).catch(() => {
        })
      }
    }
  }
}
</script>

<style scoped lang="less">
.information {
  border-radius: 4px;
  border: 1px solid #DBDBDB;
  background: #FAFAFA;
  margin-bottom: 10px;
  .title {
    border-radius: 4px 4px 0 0;
    background: #F0F0F0;
    padding: 8px 24px;
    color: #666666;
    font-size: 14px;
    font-weight: 700;
  }
  .info-content {
    display: flex;
    justify-content: flex-start;
  }
  .info-box {
    width: 400px;
    display: inline-block;
    padding: 16px 24px;
    border-radius: 4px;
    border: 1px solid #69AD48;
    margin: 24px 0 24px 24px;

    .info-title {
      color: #69AD48;
      font-weight: 700;
      font-size: 14px;
      margin-bottom: 8px;
    }

    p {
      font-size: 14px;
      line-height: 24px;

      .name {
        color: #666666;
        display: inline-block;
      }

      .info {
        color: #333333;
        font-weight: 700;
        margin-right: 20px;
        margin-left: 4px;
      }
    }
  }
}

.cart-info {
  display: flex;
  justify-content: space-between;
  margin-top: 36px;
  margin-bottom: 12px;
  .title {
    color: #69AD48;
    font-size: 16px;
    font-weight: 700;
  }
  .date-box {
    display: flex;
    align-items: center;
    span {
      color: #444;
      font-size: 14px;
      font-weight: 700;
      margin-right: 12px;
    }
  }
}

.product {
  border-radius: 4px;
  border: 1px solid #DBDBDB;
  background: #FAFAFA;
  margin-bottom: 40px;
}
.title-box {
  display: flex;
  padding: 8px 24px;
  background: #F0F0F0;
  span {
    width: 33.33%;
    color: #666666;
    font-weight: 700;
    font-size: 14px;
  }
}

.product-item {
  display: flex;
  padding: 8px 24px;
  align-items: center;
  border-bottom: dashed 1px #DBDBDB;
  font-size: 14px;
  .product-name {
    width: 33.33%;
    display: flex;
    align-items: center;
    span {
      color: #333333;
    }
    img {
      width: 26px;
      margin-right: 10px;
    }
  }
  .operation {
    width: 33.33%;
    color: #666666;
    cursor: pointer;
    padding-left: 40px;
  }
  /deep/.el-input-number--medium {
    width: 160px;
    margin-right: 200px;
  }
  .request-text {
    color: #333;
    display: inline-block;
    width: 33.33%;
    cursor: pointer;
  }
}
.product-item:last-child {
  border-bottom: none;
}

/deep/.el-date-editor {
  width: 242px!important;
}

/deep/.el-textarea {
  width: 33.33%;
}

.print-page {
  .product-item {
    /deep/.el-input-number--medium {
      width: 33.3% !important;
      margin-right: 0!important;
    }
  }
}
</style>
