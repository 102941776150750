import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import axios from './common/axiosExtend.js'
import Utils from '@/common/utils'
import '@/assets/css/common.less'
import './common/fliter.js'
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import '@/assets/iconfont/iconfont.css'
import '@/assets/iconfont/iconfont.js'
import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import VueApollo from 'vue-apollo'
import { ApolloLink } from 'apollo-link'
import utils from './common/utils'
import { ServiceJumpCollect } from 'tvu-common-ui'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
import './assets/js/buildVersion.js'
Vue.use(VXETable)
// 创建一个空内容渲染
VXETable.renderer.add('NotData', {
  // 空内容模板
  renderEmpty (h, renderOpts) {
    return <span>No Data</span>
  }
})
Vue.use(ServiceJumpCollect)
Vue.prototype.$TVU = {
  http: axios,
  serverName: 'Rental Forecasting',
  session: utils.getSession()
}

const session = utils.getSession() || ''
localStorage.setItem('usageSession', session)
const httpLink = createHttpLink({
  uri: '/usage-service/graphql?session=' + session // 请求路径
})
const middlewareLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      sid: session
    }
  })
  return forward(operation).map(response => {
    return response
  })
})
// Set request not cached
const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  },
  mutate: {
    errorPolicy: 'all'
  }
}
const apolloClient = new ApolloClient({
  link: middlewareLink.concat(httpLink), // 如果不添加请求头直接放路径
  cache: new InMemoryCache(),
  connectToDevTools: true,
  defaultOptions
})
const apolloProvider = new VueApollo({
  // clients: {
  //   api: apolloClient, // 需要添加请求头
  //   base: baseClient // 不需要添加请求头
  // },
  defaultClient: apolloClient // 默认请求路径，如果只有一个请求就使用这个就行
})
Vue.use(VueApollo)

Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.prototype.$utils = Utils
new Vue({
  router,
  store,
  apolloProvider,
  render: h => h(App)
}).$mount('#app')
