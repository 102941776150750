<template>
<div class="setting">
  <div @click="goBack" class="back-box">
    <i class="el-icon-arrow-left"></i>
    <span>QC Status</span>
    /<span style="color: #999999">{{type == 1 ? 'QC Pass' : 'QC Failed'}}</span>
  </div>
  <div class="check-box">
    <div class="title">Add Asset</div>
    <el-input v-model="assetsId" clearable placeholder="Input Asset PID"></el-input>
    <div style="text-align: right;">
      <el-button type="primary" @click="AddIn" :loading="loading">Add</el-button>
    </div>
  </div>
  <div
      class="assets-box"
      v-if="assetsList.length > 0">
    <div class="tick-info">
      <span><span style="color: #999999">Assets</span>({{assetsList.length}})</span>
      <span @click="clearAssets('all')" class="clear-box"><i class="el-icon-delete"></i> Clear</span>
    </div>
    <div class="assets-content">
      <div
          class="assets-item"
          v-for="(item, index) in assetsList"
          :key="index">
        <span class="name">{{item}}</span>
        <i class="el-icon-delete" @click="clearAssets('one', index)"></i>
      </div>
    </div>
    <div class="button-reset">
      <el-button type="primary" @click="markAssets">Mark as QC {{type == '1' ? 'Pass' : 'Failed'}}</el-button>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'StatusSet',
  props: {
    type: Number
  },
  data () {
    return {
      assetsId: '',
      loading: false,
      assetsList: []
    }
  },
  methods: {
    goBack () {
      this.$emit('backStatus')
    },
    AddIn () {
      this.assetsList.unshift(this.assetsId)
      this.assetsId = ''
    },
    markAssets () {
      this.$confirm('The operation is irreversible.', `Are you sure that you want to mark these assets as “QC ${this.type === 1 ? 'Pass' : 'Failed'} ” ? `, {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        type: 'warning'
      }).then(() => {
        this.$axios
          .post('/rental-forecasting/hubSpot/asset/updateRentalStatus', {
            peerIds: this.assetsList.join(','),
            rentalStatus: this.type === 1 ? 'Available' : 'QC failed'
          })
          .then(res => {
            if (res.data.errorCode === '0x0') {
              this.$message({
                type: 'success',
                message: 'Mark success.'
              })
              this.assetsList = []
              this.goBack()
            } else {
              this.$message({
                type: 'error',
                message: res.data.errorInfo
              })
            }
          })
      }).catch(() => {})
    },
    clearAssets (type, index) {
      if (type === 'all') {
        this.assetsList = []
      } else {
        this.assetsList.splice(index, 1)
      }
    }
  }
}
</script>

<style scoped lang="less">
.setting {
  .back-box {
    margin-bottom: 16px;
    cursor: pointer;
    font-size: 16px;
    span {
      margin-left: 10px;
    }
  }
  .check-box {
    padding: 16px 24px;
    background-color: #444444;
    border-radius: 4px;
    .title {
      font-size: 16px;
      color: #999999;
      font-weight: bold;
      margin-bottom: 16px;
    }
  }
  .assets-box {
    margin-top: 16px;
    background-color: #444;
    border-radius: 4px;
    padding: 16px 24px;
    .tick-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #DBDBDB;
      font-size: 14px;
      font-weight: 600;
      .clear-box {
        cursor: pointer;
      }
    }
    .assets-content {
      max-height: 430px;
      overflow: auto;
      margin-top: 8px;
      border-radius: 8px;
      background: #333;
      padding: 12px 16px;
      .assets-item {
        cursor: pointer;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 40px;
        line-height: 40px;
        padding: 0 20px;
        border-radius: 4px;
        color: #DBDBDB;
        margin-bottom: 8px;
        background: #444444;
      }
    }
  }
  .button-reset {
    margin-top: 16px;
    text-align: right;
  }
  /deep/.el-input {
    margin-bottom: 16px;
    .el-input__inner {
      background-color: #333333;
      border-color: #333333;
    }
    .el-input__inner::placeholder {
      color: #666666;
    }
  }
}
</style>
