<template>
<div class="print">
  <div class="print-btn">
    <el-button type="primary" @click="goPrint">Print</el-button>
  </div>
  <div class="print-box">
    <review-confirm></review-confirm>
  </div>
</div>
</template>

<script>
import ReviewConfirm from './ReviewConfirm'
export default {
  name: 'Print',
  components: {
    ReviewConfirm
  },
  created () {
  },
  methods: {
    goPrint () {
      window.print()
    }
  }
}
</script>

<style scoped lang="less">
.print {
  width: 100%;
}
.print-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: #F4F8FA;
  padding: 30px 0;
  pointer-events: none;
}

/deep/.el-input {
  .el-input__inner {
    background-color: #FFFFFF;
    border: 1px solid #DBDBDB;
    color: #333333;
    padding-left: 8px;
  }
  .el-input__inner::placeholder {
    color: #CCCCCC;
  }
}
/deep/.el-select .el-input .el-select__caret {
  color: #252525;
}
/deep/.el-form-item__label {
  color: #000000;
  line-height: 16px;
  margin-bottom: 6px;
}
/deep/.el-range-editor {
  background-color: #FFFFFF;
  border: 1px solid #DBDBDB;
}
/deep/.el-checkbox__inner {
  background: #FAFAFA!important;
  border-color: #C6C6C6;
}
/deep/.is-checked .el-checkbox__inner {
  background-color: #69AD48!important;
  border-color: #69AD48!important;
}
.print-btn {
  position: absolute;
  top: 20px;
  right: 100px;
}
.edit-event-info {
  //width: 1135px;
  margin-top: 0;
}
</style>
