<template>
<div class="index">
  <div class="tab-icon">
    <i
      class="iconfont icon-list-ul"
      :class="{'active-icon': activeIndex === 0}"
      @click="changeTab(0)"></i>
    <i
        class="iconfont icon-calendar"
        :class="{'active-icon': activeIndex === 1}"
        @click="changeTab(1)"></i>
  </div>
  <red-list v-if="activeIndex === 0"></red-list>
  <red-calendar v-if="activeIndex === 1"></red-calendar>
</div>
</template>

<script>
import RedCalendar from './RedCalendar'
import RedList from './RedList'
export default {
  name: 'Index',
  components: {
    RedCalendar,
    RedList
  },
  data () {
    return {
      activeIndex: 1
    }
  },
  methods: {
    changeTab (index) {
      this.activeIndex = index
    }
  }
}
</script>

<style scoped lang="less">
.index {
  width: 100%;
}
.tab-icon {
  position: absolute;
  right: 24px;
  padding: 16px 0;
  i {
    font-size: 25px;
    cursor: pointer;
  }
  .icon-list-ul {
    font-size: 22px;
    margin-right: 20px;
  }
  .active-icon {
    color: #69AD48;
  }
}
</style>
