import Vue from 'vue'
import VueRouter from 'vue-router'
import InfoView from '../views/RedPool/Index.vue'
import Assets from '../views/Assets/Index.vue'
import SimpleRental from '../views/SimpleRental/Index.vue'
import PrintPage from '../views/SimpleRental/PrintPage.vue'
import AssetsTool from '../views/AssetsTool/Index.vue'
import Forecasting from '../views/Forecasting/Index.vue'
import Dashboard from '../views/Dashboard/Index.vue'
import RedBullRental from '../views/RedBullRental/Index.vue'
import Print from '../views/RedBullRental/Print'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/dashboard'
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard
  },
  {
    path: '/redPool',
    name: 'redPool',
    component: InfoView
  },
  {
    path: '/assets',
    name: 'Assets',
    component: Assets
  },
  {
    path: '/simpleRental',
    name: 'SimpleRental',
    component: SimpleRental
  },
  {
    path: '/simpleRentalCPQ',
    name: 'SimpleRental',
    component: SimpleRental
  },
  {
    path: '/printPage',
    name: 'PrintPage',
    component: PrintPage
  },
  {
    path: '/assetsTool',
    name: 'AssetsTool',
    component: AssetsTool
  },
  {
    path: '/forecasting',
    name: 'Forecasting',
    component: Forecasting
  },
  {
    path: '/redBullRental',
    name: 'RedBullRental',
    component: RedBullRental
  },
  {
    path: '/print',
    name: 'Print',
    component: Print
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  const redirectRouter = sessionStorage.getItem('redirectRouter')
  sessionStorage.setItem('redirectRouter', '')
  if (to.name === 'dashboard') {
    if (redirectRouter) {
      next({ path: '/' + redirectRouter })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
