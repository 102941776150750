<template>
<div class="header-search">
  <el-date-picker
      size="small"
      class="date-picker"
      :editable="false"
      :clearable="false"
      format="yyyy-MM-dd"
      value-format="yyyy-MM-dd"
      v-model="dateRange"
      type="datetimerange"
      range-separator="-"
      start-placeholder="Start date"
      end-placeholder="End date"
      @change="setDateTimeFromDateRange($event)"
  >
  </el-date-picker>
  <el-select
   clearable
   v-model="service"
   size="small"
   filterable
   @change="serviceChanges"
   style="width: 205px;margin: 0 32px;">
    <el-option
      v-for="(item, index) in serviceOptions"
      :key="index"
      :label="item"
      :value="item">
    </el-option>
  </el-select>
  <el-select
      v-model="type"
      size="small"
      multiple
      clearable
      collapse-tags
      placeholder="Asset Type"
      @change="SearchData">
    <el-option
        v-for="(item, index) in typeList"
        :key="index"
        :label="item.label"
        :value="item.value">
    </el-option>
  </el-select>
</div>
</template>

<script>
import Bus from '@/common/vueBus'
const moment = require('moment')
export default {
  name: 'HeaderSearch',
  props: {
    activeTab: Number,
    searchType: String
  },
  data () {
    return {
      service: '',
      serviceOptions: [],
      startTime: moment()
        .subtract(7, 'days')
        .format('YYYY-MM-DD'),
      endTime: moment().format('YYYY-MM-DD'), // HH:mm:ss
      dateRange: [],
      typeList: [{ value: 'T', label: 'Transmitter' }, { value: 'R', label: 'Receiver' }],
      type: []
    }
  },
  created () {
    if (localStorage.getItem('assetType')) this.type = localStorage.getItem('assetType').split(',')
    if (localStorage.getItem('startTime') && localStorage.getItem('endTime')) {
      this.startTime = localStorage.getItem('startTime')
      this.endTime = localStorage.getItem('endTime')
    }
    this.dateRange = [this.startTime, this.endTime]
    this.getAllOwner()
  },
  methods: {
    serviceChanges () {
      localStorage.setItem('ownerSelect', this.service)
      this.SearchData(true)
    },
    getAllOwner () {
      this.$axios
        .get('/rental-forecasting/hubSpot/getAllOption')
        .then(res => {
          this.serviceOptions = res.data.result.ownerList
          this.service = localStorage.getItem('ownerSelect') || ''
          localStorage.setItem('linkMap', JSON.stringify(res.data.result.linkMap))
          this.SearchData()
        })
        .catch((error) => {
          this.$message.error(error)
        })
    },
    setDateTimeFromDateRange (_dateRange) {
      this.startTime = _dateRange[0]
      this.endTime = _dateRange[1]
      localStorage.setItem('startTime', this.startTime)
      localStorage.setItem('endTime', this.endTime)
      this.SearchData(true)
    },
    SearchData () {
      // console.log(this.type, '99')
      localStorage.setItem('assetType', this.type.join(','))
      const val = {
        service: this.service,
        startTime: this.startTime,
        endTime: this.endTime,
        type: this.type.length === 2 ? '' : this.type.join(',')
      }
      Bus.$emit('changeSearch', val)
    }
  }
}
</script>

<style scoped lang="less">
.header-search {
  margin-top: 12px;
  margin-left: 24px;
  display: flex;
  align-items: center;
  /deep/.el-date-editor--datetimerange.el-input__inner {
    width: 240px;
  }
  /deep/.el-select .el-input__inner {
    border-color: #444444;
    background: #252525;
    border-radius: 4px;
    color: #DBDBDB;
  }
  /deep/.el-input .el-input__inner {
    border-color: #444444;
    background-color: #252525;
  }
  .el-range-editor {
    border-color: #444444;
    background-color: #252525;
  }
  /deep/.el-tag.el-tag--info {
    background-color: #787878;
    border-color: #787878;
    color: #FFFFFF;
  }
  .asset-type {
    //margin-left: 45px;
    span {
      color: #999999;
      font-size: 14px;
      margin-right: 20px;
    }
    /deep/.el-radio__label {
      color: #DBDBDB;
      padding-left: 8px;
    }
  }
}
/deep/.el-range-editor .el-range-input {
  color: #FFFFFF;
}
</style>
