<template>
  <div>
    <header-search searchType="assets0"></header-search>
    <div class="index" v-loading="loading">
      <vxe-grid
          size="small"
          align="center"
          class="reverse-table mytable-scrollbar"
          :cell-style="cellStyle"
          :row-config="{isCurrent: true, isHover: true}"
          :empty-render="{name: 'NotData'}"
          v-bind="gridOptions">
      </vxe-grid>
      <el-popover
          v-if="myData.length > 0"
          style="background-color: #FFFFFF"
          popper-class="table-pop"
          placement="bottom"
          width="260"
          trigger="click">
        <el-checkbox-group
            @change="checkHeaderChange"
            v-model="checkList"
            v-for="(item, index) in myColumns"
            :key="index">
          <el-checkbox :label="item.title"></el-checkbox>
        </el-checkbox-group>
        <i slot="reference" class="el-icon-more"></i>
      </el-popover>
    </div>
  </div>
</template>

<script>
import Bus from '@/common/vueBus'
import HeaderSearch from '../../components/HeaderSearch'
export default {
  name: 'Assets',
  components: {
    HeaderSearch
  },
  data () {
    return {
      loading: false,
      gridOptions: {
        border: true,
        showOverflow: true,
        // height: 820,
        showHeader: false,
        columns: [],
        data: []
      },
      myColumns: [
        { field: 'RED Pool Total', title: 'RED Pool Total' },
        { field: 'Rental Over', title: 'Rental Over' },
        { field: 'QC Failed', title: 'QC Failed' },
        { field: 'Pending Prep', title: 'Pending Prep' },
        { field: 'On Going', title: 'On Going' },
        { field: 'Return Date Due or Past', title: 'Return Date Due or Past' },
        { field: 'Pending Follow Up', title: 'Pending Follow Up' },
        { field: 'Closed', title: 'Closed' },
        { field: 'Held By', title: 'Held By' },
        { field: 'Closed Won', title: 'Closed Won' },
        { field: 'Selected / Reaching Agreement', title: 'Selected / Reaching Agreement' },
        { field: 'Negotiating', title: 'Negotiating' },
        { field: 'Scoping and Pricing', title: 'Scoping and Pricing' },
        { field: 'Eval / POC Confirmed', title: 'Eval / POC Confirmed' },
        { field: 'Discovery', title: 'Discovery' },
        { field: 'Qualification', title: 'Qualification' },
        { field: 'Closed Duplicate', title: 'Closed Duplicate' },
        { field: 'Closed Lost', title: 'Closed Lost' },
        { field: 'date', title: 'Status / Date' }
      ],
      myData: [],
      checkList: []
    }
  },
  created () {
    this.initHeader()
    Bus.$off('changeSearch')
    Bus.$on('changeSearch', (val) => {
      this.getData(val)
    })
  },
  methods: {
    initHeader () {
      this.checkList = []
      const checkHeaderList = localStorage.getItem('checkHeaderList')
      if (checkHeaderList) {
        this.checkList = JSON.parse(checkHeaderList)
        return
      }
      this.myColumns.forEach(item => {
        this.checkList.push(item.title)
      })
    },
    // 动态显示表头
    checkHeaderChange () {
      localStorage.setItem('checkHeaderList', JSON.stringify(this.checkList))
      this.reverseTable()
    },
    // 设置表头颜色
    cellStyle ({ row, column }) {
      if (column.field === 'col0') {
        if (row.col0 === 'RED Pool Total' || row.col0 === 'Rental Over' || row.col0 === 'QC Failed') {
          return {
            backgroundColor: '#FFFBB5'
          }
        }
        if (row.col0 === 'Pending Prep' || row.col0 === 'On Going' || row.col0 === 'Return Date Due or Past' || row.col0 === 'Pending Follow Up' || row.col0 === 'Closed' || row.col0 === 'Held By') {
          return {
            backgroundColor: '#FAB5A8'
          }
        }
        if (row.col0 === 'Closed Won' || row.col0 === 'Selected / Reaching Agreement' || row.col0 === 'Negotiating' || row.col0 === 'Scoping and Pricing' || row.col0 === 'Eval / POC Confirmed' || row.col0 === 'Discovery' || row.col0 === 'Qualification' || row.col0 === 'Closed Duplicate' || row.col0 === 'Closed Lost') {
          return {
            backgroundColor: '#FFDC9B'
          }
        }
      }
    },
    // 反转表头
    reverseTable () {
      const headerColumns = this.myColumns.filter(column => this.checkList.includes(column.title))
      const buildData = headerColumns.map(column => {
        const item = { col0: column.title }
        this.myData.forEach((row, index) => {
          item[`col${index + 1}`] = row[column.field]
        })
        return item
      })
      const buildColumns = [{
        field: 'col0',
        fixed: 'left',
        width: 210
      }]
      this.myData.forEach((item, index) => {
        buildColumns.push({
          field: `col${index + 1}`,
          minWidth: 90
        })
      })
      this.gridOptions.data = buildData
      this.gridOptions.columns = buildColumns
    },
    getData (val) {
      const params = {
        startDate: val.startTime,
        endDate: val.endTime,
        assetOwner: val.service,
        type: val.type
      }
      this.loading = true
      this.$axios
        .post('/rental-forecasting/asset/rentalDailyStatic', params)
        .then(res => {
          if (res.data) {
            this.myData = res.data
            this.reverseTable()
          } else {
            this.$message.error('Error')
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped lang="less">
.index {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;

  .reverse-table {
    width: 100%;
  }

  .blue {
    color: red;
  }

  .el-icon-more {
    padding: 0 10px;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
    color: #252525;
    transform: rotate(90deg);
    margin-top: 5px;
  }

  /deep/.vxe-table--render-default {
    font-family: '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif';
  }

  /deep/.vxe-table--render-default.border--full .vxe-body--column, .vxe-table--render-default.border--full .vxe-footer--column, .vxe-table--render-default.border--full .vxe-header--column {
    background-image: linear-gradient(#BBBBBB,#BBBBBB),linear-gradient(#BBBBBB,#BBBBBB);
  }

  /deep/.el-range-editor {
    border-color: #444444;
    background-color: #444444;
    .el-range-input {
      color: #fff;
    }
  }
}
</style>
<style lang="less">
.table-pop {
  background-color: #f0f0f0!important;
  .el-checkbox .el-checkbox__input:not(.is-checked) .el-checkbox__inner {
    background-color: #FFFFFF!important;
    border-color: #C7CBCE !important;
  }
  .el-checkbox__label {
    color: #627F8C;
  }
  .el-checkbox .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #627F8C;
    padding-left: 10px;
  }
}

.mytable-scrollbar ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
/*滚动条的轨道*/
.mytable-scrollbar ::-webkit-scrollbar-track {
  background-color: #FFFFFF;
}
/*滚动条里面的小方块，能向上向下移动*/
.mytable-scrollbar ::-webkit-scrollbar-thumb {
  background-color: #bfbfbf;
  border-radius: 5px;
  border: 1px solid #F1F1F1;
  box-shadow: inset 0 0 6px rgba(0,0,0,.3);
}
.mytable-scrollbar ::-webkit-scrollbar-thumb:hover {
  background-color: #A8A8A8;
}
.mytable-scrollbar ::-webkit-scrollbar-thumb:active {
  background-color: #787878;
}
/*边角，即两个滚动条的交汇处*/
.mytable-scrollbar ::-webkit-scrollbar-corner {
  background-color: #FFFFFF;
}
</style>
