<template>
  <div class="index">
    <div class="tab-icon">
      <span :class="{'active': activeIndex === 0}" @click="changeTab(0)">AssetCount</span>
      <span :class="{'active': activeIndex === 1}" @click="changeTab(1)">Deal</span>
    </div>
    <Assets v-if="activeIndex === 0"></Assets>
    <Deal v-if="activeIndex === 1"></Deal>
  </div>
</template>

<script>
import Assets from './Assets'
import Deal from './Deal'

export default {
  name: 'Index',
  components: {
    Assets,
    Deal
  },
  data () {
    return {
      activeIndex: 0
    }
  },
  created () {
    if (localStorage.getItem('activeIndex')) {
      this.activeIndex = Number(localStorage.getItem('activeIndex'))
    }
  },
  methods: {
    changeTab (index) {
      localStorage.setItem('activeIndex', index)
      this.activeIndex = index
    }
  }
}
</script>
<style scoped lang="less">
.index {
  width: calc(100% - 105px);
  .tab-icon {
    position: absolute;
    right: 30px;
    top: 80px;
    font-size: 16px;
    cursor: pointer;
    span {
      margin-left: 15px;
    }
  }
  .active {
    color: #69AD48;
    font-weight: 700;
  }
}
</style>
