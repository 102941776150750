<template>
<div class="confirm-page">
  <el-dialog
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
    <div class="content">
      <p>We have successfully received your rental request</p>
      <p>Your rental confirmation ID is</p>
      <p class="fw mb-20">{{dealId}}</p>
      <p>Email confirmations will be sent to the</p>
      <p>business contact email</p>
      <p class="fw">{{businessEmail}}</p>
      <p>and</p>
      <p>shipping contact email</p>
      <p class="fw">{{shippingEmail}}</p>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handleClose(true)">Print Receipt</el-button>
    </span>
  </el-dialog>

</div>
</template>

<script>
export default {
  name: 'ConfirmPage',
  props: {
    dealId: String,
    businessEmail: String,
    shippingEmail: String,
    showConfirm: Boolean
  },
  data () {
    return {
      dialogVisible: false
    }
  },
  created () {
    this.dialogVisible = this.showConfirm
  },
  methods: {
    handleClose (print) {
      if (print === true) {
        this.$emit('closeConfirm', true)
      } else {
        this.$emit('closeConfirm', false)
      }
    }
  }
}
</script>

<style scoped lang="less">
/deep/.el-dialog {
  background: #FFFFFF!important;
}
/deep/.el-dialog__wrapper .el-dialog .el-dialog__header .el-dialog__headerbtn .el-icon-close:before {
  color: #555;
  font-weight: 600;
  font-size: 20px;
}
.content {
  color: #666666;
  text-align: center;
  p {
    line-height: 24px;
  }
  .fw {
    color: #333333;
    font-weight: 500;
    font-size: 16px;
  }
  .mb-20 {
    margin-bottom: 20px;
  }
}
</style>
