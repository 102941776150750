<template>
<div class="asset-content">
  <div id="asset"></div>
  <div class="asset-icon">
    <el-tooltip class="item" effect="dark" content="Switch to Stacked Aera Chart" placement="top">
      <i class="iconfont icon-a-statisticalviewlinechart-fill" :class="{'active': isShowArea}" @click="switchChart"></i>
    </el-tooltip>
    <el-tooltip class="item" effect="dark" content="Switch to Line Chart" placement="top">
      <i class="iconfont icon-Line-chart" :class="{'active': !isShowArea}" @click="switchChart"></i>
    </el-tooltip>
  </div>
  <div class="asset-list" v-if="showTable">
    <div class="title-box">
      <span>Asset List ({{currentSeriesName}}, {{currentData}})</span>
      <i class="iconfont icon-close" @click="closeTable"></i>
    </div>
    <el-table
        :data="tableData"
        v-loading="tableLoading"
        border
        max-height="780"
        style="width: 98%">
      <el-table-column
          type="index"
          label="#"
          width="50">
      </el-table-column>
      <el-table-column
          prop="peerId"
          label="Asset PID"
          sortable
          show-overflow-tooltip>
        <template slot-scope="scope">
          <span
              class="stages"
              @click="openLink(scope.row.assetId, 'asset')">{{scope.row.peerId}}</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="rentalStatus"
          show-overflow-tooltip
          sortable
          min-width="120"
          label="Rental Asset Status">
      </el-table-column>
      <el-table-column
          prop="platform"
          show-overflow-tooltip
          sortable
          label="Platform">
      </el-table-column>
      <el-table-column
          width="250"
          prop="ticketName"
          sortable
          show-overflow-tooltip
          label="Last RED Pipeline Ticket Name">
        <template slot-scope="scope">
          <span
              class="stages"
              @click="openLink(scope.row.ticketId, 'ticket')">{{scope.row.ticketName}}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</div>
</template>

<script>
import * as echarts from 'echarts'
import moment from 'moment'
export default {
  name: 'Asset',
  props: {
    dataInfo: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  watch: {
    dataInfo: {
      handler () {
        this.initOptions()
        this.option && this.myChartAsset.setOption(this.option)
      },
      deep: true
    }
  },
  data () {
    return {
      tableData: [],
      tableLoading: false,
      showTable: false,
      currentData: '',
      currentSeriesName: '',
      isShowArea: true,
      myChartAsset: null,
      xAxisData: [],
      option: {
        color: ['#70A819', '#C115B6', '#44A4FD', '#C81B28', '#42A98B', '#9B51E0', '#FF9A24', '#F1CD87'],
        title: {
          left: 40,
          top: 14,
          text: 'Asset',
          textStyle: {
            fontsize: 20,
            color: '#fff'
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          },
          backgroundColor: '#0B0E32',
          textStyle: {
            color: '#fff'
          },
          borderColor: '#0B0E32'
        },
        legend: {
          data: ['Inventory', 'Assigned to DO', 'Rental Period', 'Overdue', 'Equipment Received', 'Inbound QC Success', 'Inbound QC Failed', 'Others'],
          bottom: '0',
          textStyle: {
            color: '#fff'
          },
          itemWidth: 12,
          icon: 'circle'
        },
        grid: {
          left: 20,
          right: 32,
          bottom: 32,
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: [],
            // x轴线的颜色设置
            axisLine: {
              lineStyle: {
                color: '#072264'
              }
            },
            axisLabel: {
              color: '#fff'
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            min: 0, // 保持 y 轴的起始点在 0
            splitLine: {
              lineStyle: {
                type: 'dashed',
                color: '#072264',
                width: '2'
              }
            },
            axisLabel: {
              color: '#fff'
            }
          }
        ]
      },
      nameData: ['Inventory', 'Assigned to DO', 'Rental Period', 'Overdue', 'Equipment Received', 'Inbound QC Success', 'Inbound QC Failed', 'Others'],
      nameColor: [{ color1: '#70A819', color2: '#4F7611' }, { color1: '#C115B6', color2: '#790D72' }, { color1: '#44A4FD', color2: '#2B5BA7' }, { color1: '#C81B28', color2: '#80121A' }, { color1: '#42A98B', color2: '#266B57' }, { color1: '#9B51E0', color2: '#683598' }, { color1: '#FF9A24', color2: '#8A5415' }, { color1: '#F1CD87', color2: '#8C7549' }],
      areaSeries: [],
      lineChartSeries: []
    }
  },
  beforeDestroy () {
    // 离开页面必须进行移除，否则会造成内存泄漏，导致卡顿
    window.removeEventListener('resize', this.resizeFn)
  },
  mounted () {
    this.setInitSeries()
    const chartDom = document.getElementById('asset')
    this.myChartAsset = echarts.init(chartDom)
    this.myChartAsset.showLoading('default', {
      text: '',
      textColor: '#DBDBDB',
      maskColor: 'rgba(0, 0, 0, 0.8)',
      color: '#69AD48',
      spinnerRadius: 20,
      lineWidth: 3
    })
    // this.initOptions()
    // this.option && this.myChartAsset.setOption(this.option)
    // 图表重置
    setTimeout(() => {
      window.addEventListener('resize', () => {
        this.resizeFn()
      })
    }, 100)
    this.myChartAsset.on('click', (params) => {
      // console.log(this, params)
      this.currentData = params.name
      this.currentSeriesName = params.seriesName
      this.getTickeList()
    })
    this.myChartAsset.on('legendselectchanged', function (params) {
      // 将选中状态存储在 localStorage 里
      localStorage.setItem('assetLegends', JSON.stringify(params.selected))
    })
  },
  methods: {
    setInitSeries () {
      this.areaSeries = []
      this.lineChartSeries = []
      this.nameData.forEach((item, index) => {
        this.areaSeries.push({
          name: item,
          type: 'line',
          stack: 'Total',
          lineStyle: {
            width: 0
          },
          areaStyle: {
            opacity: 1,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: this.nameColor[index].color1
              },
              {
                offset: 1,
                color: this.nameColor[index].color2
              }
            ])
          },
          data: [],
          showSymbol: index === this.nameData.length - 1,
          label: {
            normal: {
              show: index === this.nameData.length - 1,
              position: 'top',
              color: '#DBDBDB',
              fontSize: 16,
              formatter: (params) => {
                // const { data, dataIndex, seriesIndex, seriesName, componentSubType } = params
                const { dataIndex, componentSubType } = params
                let sum = 0
                if (componentSubType === 'line') {
                  // 遍历所有系列并累加其数据，此处假设所有系列按顺序排列，有相同的data长度
                  this.option.series.forEach((series) => {
                    sum += series.data[dataIndex]
                  })
                  // 返回总和
                  return sum
                }
                return ''
              }
            }
          }
        })
        this.lineChartSeries.push({
          name: item,
          type: 'line',
          data: [],
          label: {
            normal: {
              show: true,
              position: 'top',
              color: '#DBDBDB',
              fontSize: 16,
              formatter: function (params) {
                const todayDate = moment().format('YYYY-MM-DD')
                // 仅当 xAxis 是周三时显示标签
                if (params.name === todayDate) {
                  return params.value
                }
                return ''
              }
            }
          }
        })
      })
    },
    initOptions () {
      this.myChartAsset.clear()
      // 获取缓存里图例选择的状态
      let selectedLegends = {}
      if (localStorage.getItem('assetLegends')) {
        selectedLegends = JSON.parse(localStorage.getItem('assetLegends'))
      }
      // 从缓存里拿图例选中的状态，设置图例
      if (JSON.stringify(selectedLegends) !== '{}') this.option.legend.selected = selectedLegends
      this.xAxisData = []
      this.option.series = []
      this.areaSeries.forEach(item => {
        item.data = []
      })
      this.lineChartSeries.forEach((item, index) => {
        item.data = []
      })
      this.option.series = this.isShowArea ? this.areaSeries : this.lineChartSeries
      this.dataInfo.forEach((item) => {
        this.xAxisData.push(item.date)
        this.option.series[0].data.push(item.inventory)
        this.option.series[1].data.push(item.assignedToDO)
        this.option.series[2].data.push(item.rentalPeriod)
        this.option.series[3].data.push(item.overdue)
        this.option.series[4].data.push(item.equipmentReceived)
        this.option.series[5].data.push(item.inboundQCSuccess)
        this.option.series[6].data.push(item.inboundQCFailed)
        this.option.series[7].data.push(item.other)
      })
      this.option.xAxis[0].data = this.xAxisData
      this.myChartAsset.hideLoading()
    },
    // 切换图表类型
    switchChart () {
      this.isShowArea = !this.isShowArea
      this.initOptions()
      this.myChartAsset.setOption(this.option)
    },
    // 获取Ticke列表数据
    getTickeList () {
      const params = {
        assetOwner: localStorage.getItem('ownerSelect') || '',
        rentalStatus: this.currentSeriesName === 'Others' ? 'other' : this.currentSeriesName,
        date: this.currentData,
        type: localStorage.getItem('assetType') || ''
      }
      this.tableLoading = true
      this.$axios.post('/rental-forecasting/asset/dashboard/listAsset', params).then((res) => {
        this.tableData = res.data
        this.showTable = true
        this.tableLoading = false
      })
    },
    closeTable () {
      this.showTable = false
    },
    openLink (id, type) {
      const url = `${JSON.parse(localStorage.getItem('linkMap'))[type]}${id}`
      window.open(url)
    },
    resizeFn () {
      return this.myChartAsset.resize()
    }
  }
}
</script>

<style scoped lang="less">
.asset-content {
  background: #0E0A1F;
  border-radius: 8px;
  position: relative;
  margin-right: 20px;

  .asset-icon {
    position: absolute;
    right: 10px;
    top: 4px;
    i {
      color: #9A9DB4;
      font-size: 20px;
      cursor: pointer;
    }
    .icon-Line-chart {
      margin-left: 6px;
    }
  }
}
#asset {
  width: 100%;
  min-height: 680px;
  padding-bottom: 30px;
}
canvas {
  width: 100%;
  padding: 20px 10px!important;
}

.asset-list {
  border-top: 2px dashed rgba(154, 157, 180, 0.62);
  padding: 12px 0;
  margin: 0 24px;

  .title-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      color: #999999;
      font-size: 16px;
      font-weight: 700;
    }
    i {
      font-size: 24px;
      color: #DBDBDB;
      cursor: pointer;
    }
  }
}

.stages {
  text-decoration: underline;
  color: #DBDBDB;
  cursor: pointer;
}

</style>
