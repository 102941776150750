<template>
<div class="device-right">
  <el-date-picker
      class="date-pick-full"
      ref="datepick"
      @change="datepickChange"
      v-model="dateValue"
      :type="dateType === 'dayGridWeek' ? 'week' : 'date'"
      format="yyyy-MM-dd"
      placeholder="">
  </el-date-picker>
  <div class="device-all">All Device: {{assetsData.length}}</div>
  <FullCalendar :options="calendarOptions" ref="fullCalendar" />
</div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import Bus from '@/common/vueBus'
const moment = require('moment')

export default {
  name: 'DeviceRight',
  components: {
    FullCalendar // 注册组件
  },
  props: {
    assetsData: Array
  },
  data () {
    return {
      dateValue: '',
      dateType: 'dayGridWeek',
      calendarOptions: {
        plugins: [dayGridPlugin],
        initialView: 'dayGridWeek', // 初始视图为日历月视图
        events: [
          // 可以遵循这种格式来添加事件
          // { title: 'Event 1', start: '2023-08-15', end: '2023-08-17 12:00:00' }
        ],
        customButtons: {
          prev: {
            text: 'Prev',
            click: () => {
              // 通过 getApi 获取日历实例
              const calendarApi = this.$refs.fullCalendar.getApi()
              calendarApi.prev()
              this.getCurrentViewDateRange()
            }
          },
          next: {
            text: 'Next',
            click: () => {
              // 通过 getApi 获取日历实例
              const calendarApi = this.$refs.fullCalendar.getApi()
              calendarApi.next()
              this.getCurrentViewDateRange()
            }
          },
          today: {
            text: 'Today',
            click: () => {
              // 通过 getApi 获取日历实例
              const calendarApi = this.$refs.fullCalendar.getApi()
              calendarApi.today()
              this.getCurrentViewDateRange()
            }
          },
          dayGridWeek: {
            text: 'Week',
            click: () => {
              // 通过 getApi 获取日历实例
              const calendarApi = this.$refs.fullCalendar.getApi()
              calendarApi.changeView('dayGridWeek', calendarApi.getDate())
              this.getCurrentViewDateRange()
            }
          },
          dayGridDay: {
            text: 'Day',
            click: () => {
              // 通过 getApi 获取日历实例
              const calendarApi = this.$refs.fullCalendar.getApi()
              calendarApi.changeView('dayGridDay', calendarApi.getDate())
              this.getCurrentViewDateRange()
            }
          }
        },
        headerToolbar: {
          left: '',
          center: 'prev title next today',
          right: 'dayGridWeek,dayGridDay' // dayGridMonth
        }
      },
      statusList: {}
    }
  },
  mounted () {
    Bus.$on('updateView', () => {
      setTimeout(() => {
        this.initData()
      }, 0)
    })
    Bus.$on('scrollChangesToRight', val => {
      const elementScroll = document.getElementsByClassName('fc-daygrid-body')[0]
      if (elementScroll) {
        elementScroll.scrollTop = val
      }
    })
    this.statusList = JSON.parse(localStorage.getItem('statusList'))
    window.addEventListener('resize', this.handleResize)
    this.addClickEventListenerToCalendarHeader()
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  updated () {
    this.handleResize()
  },
  methods: {
    addClickEventListenerToCalendarHeader () {
      this.$nextTick(() => {
        const titleEl = document.querySelector('.fc-toolbar-title')
        if (titleEl) {
          titleEl.addEventListener('click', this.handleTitleClick)
        }
        // 设置左右滚动条
        const elementScroll = document.getElementsByClassName('fc-daygrid-body')[0]
        elementScroll.onscroll = function () {
          Bus.$emit('scrollChanges', elementScroll.scrollTop)
        }
      })
    },
    handleTitleClick () {
      this.$refs.datepick.focus()
    },
    datepickChange () {
      if (!this.dateValue) return
      this.$refs.fullCalendar.getApi().gotoDate(moment(this.dateValue).format('YYYY-MM-DD'))
      const currentDate = new Date(this.dateValue)
      if (this.dateType === 'dayGridWeek') {
        let weekStart = new Date(currentDate.getTime())
        weekStart.setDate(weekStart.getDate() - weekStart.getDay()) // weekStart的日期现在是周日
        let weekEnd = new Date(weekStart.getTime())
        weekEnd.setDate(weekEnd.getDate() + 6) // weekEnd的日期现在是周六
        weekStart = moment(weekStart).format('YYYY-MM-DD')
        weekEnd = moment(weekEnd).format('YYYY-MM-DD')
        this.getCurrentViewDateRange(true, weekStart, weekEnd)
      } else {
        const dayStart = moment(currentDate).format('YYYY-MM-DD')
        this.getCurrentViewDateRange(true, dayStart, dayStart)
      }
    },
    handleResize () {
      this.$nextTick(() => {
        const height = this.assetsData.length * 60 + 110
        // 动态设置日历高度
        // const element = document.getElementsByClassName('fc-view-harness')[0]
        const element = document.getElementsByClassName('fc-scrollgrid-sync-table')[0]
        if (element) {
          element.style.height = `${height}px`
        }
      })
    },
    getCurrentViewDateRange (val, weekStart, weekEnd) {
      const calendarApi = this.$refs.fullCalendar.getApi()
      this.dateType = calendarApi.currentData.currentViewType
      if (val) {
        Bus.$emit('dateChange', {
          startDate: weekStart,
          endDate: weekEnd
        })
      } else {
        const start = calendarApi.view.activeStart
        const end = calendarApi.view.activeEnd
        end.setDate(end.getDate() - 1)
        Bus.$emit('dateChange', {
          startDate: moment(start).format('YYYY-MM-DD'),
          endDate: moment(end).format('YYYY-MM-DD')
        })
      }
      // if (moment().valueOf() < moment(end).valueOf()) {
      //   end = new Date()
      // }
    },
    convertArray (data) {
      const newData = []
      let currentStatus = null
      let currentStart = null
      let currentEnd = null

      for (let i = 0; i < data.length; i++) {
        const item = data[i]
        // 两个日期之差等于86400000表示连续的日期
        if ((new Date(currentEnd) - new Date(data[i].date) === 86400000) && item.rentalStatus === currentStatus) {
          currentEnd = item.date
        } else {
          if (currentStatus !== null) {
            newData.push({
              start: currentEnd,
              end: currentStart + ' 24:00:00',
              title: currentStatus
            })
          }

          currentStatus = item.rentalStatus
          currentStart = item.date
          currentEnd = item.date
        }
      }
      newData.push({
        start: currentEnd,
        end: currentStart + ' 24:00:00',
        title: currentStatus
      })

      return newData
    },
    initData () {
      let events = []
      this.assetsData.forEach(item => {
        item.dailyStatus = item.dailyStatus.reverse()
        item.dailyStatus.forEach(items => {
          if (!items.rentalStatus) {
            items.rentalStatus = 'No Record'
          }
          // events.push({ title: items.rentalStatus, start: items.date })
        })
        events = events.concat(this.convertArray(item.dailyStatus))
      })
      events = events.map((item, index) => ({
        allDay: true,
        order: index,
        color: item.title === 'No Record' ? '#787878' : this.statusList[item.title],
        ...item
      }))
      this.calendarOptions.events = events // 设置事件
      if (this.$refs.fullCalendar) this.$refs.fullCalendar.getApi().refetchEvents() // 让 FullCalendar 重新获取和渲染所有事件
      this.calendarOptions.eventOrder = 'order' // 事件排序
    }
  }
}
</script>

<style scoped lang="less">
.device-right {
  width: calc(100% - 330px);
  background-color: #444444;
  .device-all {
    position: absolute;
    padding: 24px;
    font-size: 14px;
  }
}
</style>
