<template>
  <div class="index">
    <div class="form-rental header-box">
      <el-popover
          popper-class="rental-pop"
          placement="bottom-start"
          width="350"
          trigger="hover">
        <div>
          <p>Frontend: {{ rentalFrontVersion }}</p>
          <p>Backend: {{ rentalBackendVersion }}</p>
        </div>
        <div class="header" slot="reference">
          <img src="../../assets/images/new-logo.png" alt="" width="32">
          <span>Rental Management Center</span>
        </div>
      </el-popover>
    </div>
    <div ref="pdfContent" class="form-rental">
      <el-form
          v-show="firstStage"
          :rules="rules"
          ref="formRental"
          class=""
          :model="formRental">
        <div class="title-first" style="display: flex; justify-content: space-between; padding-right: 22px;">
          Business Contact Information
        </div>
        <div class="flex-center">
          <el-form-item prop="businessContactFirstName">
            <el-input @change="sameChecked" v-model="formRental.businessContactFirstName"
                      placeholder="First Name"></el-input>
          </el-form-item>
          <el-form-item prop="businessContactLastName">
            <el-input @change="sameChecked" v-model="formRental.businessContactLastName"
                      placeholder="Last Name"></el-input>
          </el-form-item>
        </div>
        <div class="flex-center">
          <el-form-item prop="businessContactCompany">
            <el-input @change="sameChecked" v-model="formRental.businessContactCompany"
                      placeholder="companyName"></el-input>
          </el-form-item>
          <el-form-item prop="businessContactEmail">
            <el-input @change="sameChecked" v-model="formRental.businessContactEmail"
                      placeholder="*Email Address"></el-input>
          </el-form-item>
        </div>
        <div class="flex-center">
          <el-form-item prop="businessContactPhone">
            <el-input @change="sameChecked" v-model="formRental.businessContactPhone"
                      placeholder="*Phone Number"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input prop="businessContactText" @change="sameChecked" v-model="formRental.businessContactText"
                      placeholder="other communication text"></el-input>
          </el-form-item>
        </div>
        <div class="flex-center">
          <el-form-item prop="businessContactCountry">
            <el-select
                clearable
                filterable
                @change="sameChecked('busRegion')"
                v-model="formRental.businessContactCountry"
                placeholder="*Country/Region">
              <el-option
                  v-for="(item, index) in locationList"
                  :key="index"
                  :label="item.country"
                  :value="item.country"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="businessContactState">
            <el-select
                clearable
                filterable
                @change="sameChecked"
                v-model="formRental.businessContactState"
                placeholder="*State/Province">
              <el-option
                  v-for="(item, index) in businessStateList"
                  :key="index"
                  :label="item"
                  :value="item"></el-option>
            </el-select>
          </el-form-item>
        </div>

        <div style="display: flex; justify-content: space-between;">
          <div class="title">Shipping Contact Details</div>
          <span style="font-weight: normal; font-size: 14px;">
        <el-checkbox v-model="checked" @change="sameChecked"></el-checkbox>
        <span style="margin-left: 5px;color: #000000;">Same as business Contact</span>
      </span>
        </div>
        <div class="flex-center">
          <el-form-item prop="shippingContactFirstName">
            <el-input :disabled="!canInput" v-model="formRental.shippingContactFirstName"
                      placeholder="First Name"></el-input>
          </el-form-item>
          <el-form-item prop="shippingContactLastName">
            <el-input :disabled="!canInput" v-model="formRental.shippingContactLastName"
                      placeholder="Last Name"></el-input>
          </el-form-item>
        </div>
        <div class="flex-center">
          <el-form-item prop="shippingContactCompany">
            <el-input :disabled="!canInput" v-model="formRental.shippingContactCompany"
                      placeholder="companyName"></el-input>
          </el-form-item>
          <el-form-item prop="shippingContactEmail">
            <el-input :disabled="!canInput" v-model="formRental.shippingContactEmail"
                      placeholder="*Email Address"></el-input>
          </el-form-item>
        </div>
        <div class="flex-center">
          <el-form-item prop="shippingContactPhone">
            <el-input :disabled="!canInput" v-model="formRental.shippingContactPhone"
                      placeholder="*Phone Number"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input prop="shippingContactText" :disabled="!canInput" v-model="formRental.shippingContactText"
                      placeholder="other communication text"></el-input>
          </el-form-item>
        </div>
        <div class="flex-center">
          <el-form-item prop="shippingContactCountry">
            <el-select
                clearable
                filterable
                :disabled="!canInput"
                v-model="formRental.shippingContactCountry"
                @change="shippingContactCountryChange"
                placeholder="*Country/Region">
              <el-option
                  v-for="(item, index) in locationList"
                  :key="index"
                  :label="item.country"
                  :value="item.country"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="shippingContactState">
            <el-select
                clearable
                filterable
                :disabled="!canInput"
                v-model="formRental.shippingContactState"
                placeholder="*State/Province">
              <el-option
                  v-for="(item, index) in shippingStateList"
                  :key="index"
                  :label="item"
                  :value="item"></el-option>
            </el-select>
          </el-form-item>
        </div>

        <div class="title-first">Shipping Information</div>
        <div class="title">Shipping Address</div>
        <el-form-item prop="shippingAddress1">
          <el-input v-model="formRental.shippingAddress1" placeholder="Shipping Address Line 1"></el-input>
        </el-form-item>
        <div class="flex-center">
          <el-form-item prop="shippingAddress2">
            <el-input v-model="formRental.shippingAddress2" placeholder="Shipping Address Line 2(Optional)"></el-input>
          </el-form-item>
          <el-form-item prop="buildingName">
            <el-input v-model="formRental.buildingName" placeholder="Building Name"></el-input>
          </el-form-item>
        </div>
        <div class="flex-center">
          <el-form-item prop="shippingCountry">
            <el-select
                style="width: 100%;"
                clearable
                filterable
                v-model="formRental.shippingCountry"
                @change="shippingCountryChange"
                placeholder="*Country/Region">
              <el-option
                  v-for="(item, index) in locationList"
                  :key="index"
                  :label="item.country"
                  :value="item.country"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="shippingState">
            <el-select
                clearable
                filterable
                v-model="formRental.shippingState"
                placeholder="*State/Province">
              <el-option
                  v-for="(item, index) in deliveryStateList"
                  :key="index"
                  :label="item"
                  :value="item"></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="flex-center">
          <el-form-item prop="shippingCity">
            <el-input v-model="formRental.shippingCity" placeholder="*City"></el-input>
          </el-form-item>
          <el-form-item prop="shippingZipCode">
            <el-input v-model="formRental.shippingZipCode" placeholder="Postal Code"></el-input>
          </el-form-item>
        </div>
        <div class="title">Delivery Date > Return Date</div>
        <el-form-item prop="formDate" required>
          <el-date-picker
              v-model="formRental.formDate"
              :picker-options="pickerOptions"
              type="daterange"
              :editable="false"
              range-separator=""
              start-placeholder="*Date Selection Window"
              end-placeholder="">
          </el-date-picker>
        </el-form-item>
        <el-form-item prop="deliveryService">
          <el-select
              style="width: 100%;"
              clearable
              v-model="formRental.deliveryService"
              @change="deliveryServiceChange"
              placeholder="Delivery Service">
            <el-option
                v-for="(item, index) in deliveryServiceList"
                :key="index"
                :label="item.label"
                :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="customizedShippingDetails" v-if="formRental.deliveryService === 'Customized'">
          <el-input v-model="formRental.customizedShippingDetails " placeholder="Please Specify"></el-input>
        </el-form-item>
        <el-form-item prop="eventInformation">
          <el-input v-model="formRental.eventInformation" placeholder="Event Information"></el-input>
        </el-form-item>
        <el-form-item style="text-align: right">
          <el-button type="primary" @click="onNext">Next</el-button>
        </el-form-item>
      </el-form>
      <el-form
          v-show="!firstStage"
          :rules="rules"
          ref="formProduct"
          :inline="true"
          class="product-form"
          :model="formProduct">
        <div class="product-content" v-if="productSelectList.length > 0 && showProduceCPQ">
          <div v-for="(item, index) in productSelectList" :key="index">
            <span class="name">{{ item.name }}</span>
            <el-input-number v-model="item.number" :min="1" label=""></el-input-number>
            <span class="price">${{ item.price }}</span>
            <i class="el-icon-delete" @click="deleteProduct(index)"></i>
          </div>
        </div>
        <add-product
            v-if="!showProduceCPQ"
            @productChange="productChange"
            @otherChanges="otherChanges"
            :otherProductId="otherProductId"
            :productListAll="productList">
        </add-product>
        <add-product-c-p-q
            v-if="accessoryList.length > 0 && showProduceCPQ"
            :accessoryList="accessoryList"
            @addProduct="addProduct">
        </add-product-c-p-q>
        <div class="flex-center" style="margin-top: 30px;" v-if="showReferral">
        <span
            style="color: #000000;font-size: 14px; margin-right: 10px;line-height: 40px;height: 40px;">Referral Code</span>
          <el-form-item>
            <el-input @change="codeActive = false" v-model="referralCode" placeholder=""></el-input>
            <div class="name-code" v-if="codeActive">{{ referralName }}</div>
          </el-form-item>
          <div>
            <el-button @click="checkOwner" type="primary">Verify</el-button>
          </div>
        </div>
        <div style="display: flex; align-items: center; justify-content: space-between;margin-top: 20px;">
          <el-form-item>
            <el-button type="danger" plain @click="backFirstStage">Back</el-button>
          </el-form-item>
          <el-form-item>
            <el-button :loading="loading" type="primary" @click="onSubmit">Submit</el-button>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <confirm-page
        v-if="showConfirm"
        @closeConfirm="closeConfirm"
        :showConfirm="showConfirm"
        :dealId="dealId"
        :businessEmail="formRental.businessContactEmail"
        :shippingEmail="formRental.shippingContactEmail">
    </confirm-page>
  </div>
</template>

<script>
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import country from '../../common/country.js'
import ConfirmPage from './ConfirmPage'
import AddProductCPQ from './AddProductCPQ'
import AddProduct from './AddProduct'
import utils from '../../common/utils'
import Bus from '../../common/vueBus'
const moment = require('moment')
export default {
  name: 'Index',
  components: { ConfirmPage, AddProductCPQ, AddProduct },
  data () {
    return {
      rentalFrontVersion: '',
      rentalBackendVersion: '',
      formRental: {
        shippingAddress1: '',
        shippingAddress2: '',
        buildingName: '',
        shippingCity: '',
        shippingZipCode: '',
        shippingState: '',
        shippingCountry: '',
        formDate: [],
        deliveryService: '',
        eventInformation: '',
        shippingContactFirstName: '',
        shippingContactLastName: '',
        shippingContactCompany: '',
        shippingContactState: '',
        shippingContactCountry: '',
        shippingContactEmail: '',
        shippingContactPhone: '',
        businessContactFirstName: '',
        businessContactLastName: '',
        businessContactCompany: '',
        businessContactState: '',
        businessContactCountry: '',
        businessContactEmail: '',
        businessContactPhone: '',
        shippingContactText: '',
        businessContactText: '',
        customizedShippingDetails: ''
      },
      productList: [],
      lineItemList: [],
      countryList: country.countryList,
      // contactStateList: [],
      loading: false,
      showError1: false,
      showError2: false,
      checked: false,
      firstStage: true,
      // checkAgree: false,
      params: {},
      canInput: true,
      pickerOptions: {
        disabledDate (time) {
          // 获取今天凌晨的时间戳
          // const today = new Date(new Date().toLocaleDateString()).getTime()
          const today = moment().startOf('day').valueOf()
          // console.log(today, time.getTime(), time.getTime() < today)
          return time.getTime() < today
        }
      },
      deliveryServiceList: [],
      referralCode: '',
      codeActive: false,
      formProduct: {},
      selectedProducts: [],
      referralName: '',
      firstImage: '',
      dealId: '',
      showConfirm: false,
      accessoryList: [],
      productSelectList: [],
      showProduceCPQ: false,
      showReferral: true,
      optionsInfo: {}, // 配置信息
      otherProductId: '',
      specialRentalProductRequest: '',
      locationList: [],
      businessStateList: [],
      shippingStateList: [],
      deliveryStateList: [],
      firstImageWidth: 0,
      firstImageHeight: 0
    }
  },
  computed: {
    rules () {
      return {
        formDate: [
          { type: 'array', required: true, message: 'Please complete this required field.', trigger: 'change' }
        ],
        shippingCity: [
          { required: true, message: 'Please complete this required field.', trigger: 'blur' }
        ],
        shippingState: [
          { required: true, message: 'Please complete this required field.', trigger: 'change' }
        ],
        shippingCountry: [
          { required: true, message: 'Please complete this required field.', trigger: 'change' }
        ],
        shippingContactState: [
          { required: !this.checked, message: 'Please complete this required field.', trigger: 'change' }
        ],
        shippingContactCountry: [
          { required: !this.checked, message: 'Please complete this required field.', trigger: 'change' }
        ],
        shippingContactEmail: [
          { required: !this.checked, message: 'Please complete this required field.', trigger: 'blur' },
          { pattern: /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/, message: 'Invalid Email Formats!', trigger: 'blur' }
        ],
        businessContactState: [
          { required: true, message: 'Please complete this required field.', trigger: 'change' }
        ],
        businessContactCountry: [
          { required: true, message: 'Please complete this required field.', trigger: 'change' }
        ],
        businessContactEmail: [
          { required: true, message: 'Please complete this required field.', trigger: 'blur' },
          { pattern: /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/, message: 'Invalid Email Formats!', trigger: 'blur' }
        ],
        deliveryService: [
          { required: true, message: 'Please complete this required field.', trigger: 'change' }
        ],
        customizedShippingDetails: [
          { required: this.formRental.deliveryService === 'Customized', message: 'Please complete this required field.', trigger: 'change' }
        ],
        businessContactPhone: [
          { required: true, message: 'Please complete this required field.', trigger: 'change' }
        ],
        shippingContactPhone: [
          { required: true, message: 'Please complete this required field.', trigger: 'change' }
        ]
      }
    }
  },
  created () {
    if (utils.getQueryString('referralCode')) {
      this.showReferral = false
      this.codeActive = true
      this.referralCode = utils.getQueryString('referralCode')
    }
    if (this.$route.path === '/simpleRentalCPQ') {
      this.showProduceCPQ = true
    }
    this.getAllOwner()
    this.userView()
    this.rentalBackendVersion = localStorage.getItem('rentalBackendVersion')
    this.rentalFrontVersion = localStorage.getItem('rentalFrontVersion')
  },
  methods: {
    backFirstStage () {
      this.firstStage = true
      Bus.$emit('clearProduct')
    },
    deliveryServiceChange () {
      this.formRental.customizedShippingDetails = ''
    },
    productChange (value) {
      this.productSelectList = value
    },
    otherChanges (value) {
      this.specialRentalProductRequest = value
      localStorage.setItem('specialRentalProductRequest', value)
    },
    addProduct (value) {
      value.number = 1
      this.productSelectList.push(value)
    },
    deleteProduct (index) {
      this.productSelectList.splice(index, 1)
    },
    closeConfirm (print) {
      if (print) {
        localStorage.setItem('formRental', JSON.stringify(this.formRental))
        localStorage.setItem('productSelectList', JSON.stringify(this.productSelectList))
        localStorage.setItem('checked', this.checked)
        localStorage.setItem('referralCode', this.referralCode)
        window.open(location.origin + '/#/printPage')
      }
      this.clearInfo()
      this.showConfirm = false
    },
    getImage () {
      html2canvas(this.$refs.pdfContent).then(canvas => {
        this.firstImageWidth = canvas.width
        this.firstImageHeight = canvas.height
        this.firstImage = canvas.toDataURL('image/jpeg', 1.0)
      })
    },
    uploadPdf (name) {
      html2canvas(this.$refs.pdfContent).then(canvas => {
        const pageData = canvas.toDataURL('image/jpeg', 1.0)
        // eslint-disable-next-line new-cap
        const pdf = new jsPDF({
          orientation: 'p',
          unit: 'pt',
          format: 'a4'
        })
        // 第一页
        const imgWidth = this.firstImageWidth
        const imgHeight = this.firstImageHeight
        const a4Width = pdf.internal.pageSize.getWidth()
        const a4Height = pdf.internal.pageSize.getHeight()
        const ratio = Math.min(a4Width / imgWidth, a4Height / imgHeight)
        // 将图片绘制到PDF中
        const imgScaledWidth = imgWidth * ratio
        const imgScaledHeight = imgHeight * ratio
        pdf.addImage(this.firstImage, 'JPEG', 0, 0, imgScaledWidth, imgScaledHeight)
        pdf.addPage()
        // 第二页
        const imgWidth2 = canvas.width
        const imgHeight2 = canvas.height
        const a4Width2 = pdf.internal.pageSize.getWidth()
        const a4Height2 = pdf.internal.pageSize.getHeight()
        const ratio2 = Math.min(a4Width2 / imgWidth2, a4Height2 / imgHeight2)
        // 将图片绘制到PDF中
        const imgScaledWidth2 = imgWidth2 * ratio2
        const imgScaledHeight2 = imgHeight2 * ratio2
        pdf.addImage(pageData, 'JPEG', 0, 0, imgScaledWidth2, imgScaledHeight2)

        const pdfBlob = new Blob([pdf.output('blob')], { type: 'application/pdf' })
        const formData = new FormData()
        formData.append('file', pdfBlob, `${name}.pdf`)
        this.$axios
          .post('/rental-forecasting/public/hubSpot/file/upload', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(res => {
            if (res.data.result) {
              this.dealConnectPdf(res.data.result)
            } else {
              this.$message.error(res.data.errorInfo)
            }
          })
          .catch((error) => {
            this.$message.error(error)
          })
        // pdf.save('content.pdf') // 下载pdf文件
      })
    },
    // 关联dealID和pdf文件ID
    dealConnectPdf (id) {
      this.$axios
        .post('/rental-forecasting/public/hubSpot/note/create', {
          dealId: this.dealId,
          fileIds: id
        })
        .then(res => {
          if (res.data.result) {
            console.log(res.data.result)
          } else {
            this.$message.error(res.data.errorInfo)
          }
        })
        .catch((error) => {
          this.$message.error(error)
        })
    },
    checkOwner () {
      if (!this.referralCode) return
      this.$axios
        .post('/rental-forecasting/public/hubSpot/getOwner', {
          ownerId: this.referralCode
        })
        .then(res => {
          if (res.data.errorCode === '0x0' && res.data.result) {
            this.referralName = res.data.result.label
            this.codeActive = true
            this.$message.success('Verification successful')
          } else {
            this.codeActive = false
            this.$message.error(res.data.errorInfo || 'Verification failed')
          }
          this.loading = false
        })
        .catch((error) => {
          this.codeActive = false
          this.$message.error(error)
        })
    },
    shippingCountryChange () {
      const finItem = this.locationList.find(item => item.country === this.formRental.shippingCountry)
      this.deliveryStateList = finItem ? finItem.stateList : []
      this.formRental.shippingState = ''
    },
    shippingContactCountryChange () {
      const finItem = this.locationList.find(item => item.country === this.formRental.shippingContactCountry)
      this.shippingStateList = finItem ? finItem.stateList : []
      this.formRental.shippingContactState = ''
    },
    sameChecked (type) {
      if (type === 'busRegion') {
        const finItem = this.locationList.find(item => item.country === this.formRental.businessContactCountry)
        this.businessStateList = finItem ? finItem.stateList : []
        this.formRental.businessContactState = ''
      }
      if (this.checked) {
        const {
          businessContactFirstName,
          businessContactLastName,
          businessContactCompany,
          businessContactState,
          businessContactCountry,
          businessContactEmail,
          businessContactPhone,
          businessContactText
        } = this.formRental
        this.formRental.shippingContactFirstName = businessContactFirstName
        this.formRental.shippingContactLastName = businessContactLastName
        this.formRental.shippingContactCompany = businessContactCompany
        this.formRental.shippingContactState = businessContactState
        this.formRental.shippingContactCountry = businessContactCountry
        this.formRental.shippingContactEmail = businessContactEmail
        this.formRental.shippingContactPhone = businessContactPhone
        this.formRental.shippingContactText = businessContactText
        this.canInput = false
      } else {
        this.formRental.shippingContactFirstName = ''
        this.formRental.shippingContactLastName = ''
        this.formRental.shippingContactCompany = ''
        this.formRental.shippingContactState = ''
        this.formRental.shippingContactCountry = ''
        this.formRental.shippingContactEmail = ''
        this.formRental.shippingContactPhone = ''
        this.formRental.shippingContactText = ''
        this.canInput = true
      }
    },
    onNext () {
      if (this.formRental.businessContactCountry === 'United States' && this.formRental.shippingCountry === 'United States') {
        // 美国本土的产品列表
        this.productList = this.optionsInfo.productListDomestic
      } else {
        // 全球的产品列表
        this.productList = this.optionsInfo.productListGlobal
      }
      localStorage.setItem('productList', JSON.stringify(this.productList))
      this.$refs.formRental.validate(valid => {
        if (valid) {
          // 获取第一页的图片
          this.getImage()
          const start = moment(this.formRental.formDate[0]).format('YYYY-MM-DD')
          const end = moment(this.formRental.formDate[1]).format('YYYY-MM-DD')
          const params = { ...this.formRental }
          params.startDate = start
          params.endDate = end
          params.sameAsShippingContact = this.checked
          delete params.formDate
          this.params = params
          this.firstStage = false
          // this.$refs.formRental.resetFields()
        } else {
          return false
        }
      })
    },
    clearInfo () {
      this.lineItemList = []
      this.productSelectList = []
      this.selectedProducts = []
      if (this.showReferral) this.referralCode = ''
      this.params = {}
      this.firstStage = true
      this.checked = false
      this.codeActive = false
      // this.checkAgree = false
      this.$refs.formRental.resetFields()
    },
    onSubmit () {
      // if (!this.checkAgree) return this.$message.error('Please agree to the TVU Networks Rental Plan Terms of Service')
      this.lineItemList = []
      if (this.productSelectList.length < 1) {
        return this.$message.error('Please add product')
      }
      // CPQ需要的lineItemList
      if (this.showProduceCPQ) {
        this.productSelectList.forEach(item => {
          this.lineItemList.push({ productName: item.name, productId: item.productId, quantity: item.number })
        })
      } else {
        this.productList.forEach(item => {
          this.productSelectList.forEach(items => {
            if (!items.productId) return this.$message.error('Please select an option from the dropdown menu.')
            if (item.productId === items.productId) {
              items.name = item.name
              this.lineItemList.push({ productName: item.name, productId: items.productId, quantity: items.number })
            }
          })
        })
      }
      if (this.lineItemList.find(item => item.productId === this.otherProductId) && !this.specialRentalProductRequest) {
        Bus.$emit('submitProduct')
        return
      }
      if (this.lineItemList.length < 1) return
      if (this.specialRentalProductRequest) this.params.specialRentalProductRequest = this.specialRentalProductRequest
      this.$refs.formProduct.validate(valid => {
        if (valid) {
          if (this.loading) return
          // url上不携带referralCode需要用户手动输入，需要验证是都有效
          if (this.referralCode && !this.codeActive && this.showReferral) return this.$message.error('Please verify the Referral Code')
          this.params.lineItemList = this.lineItemList
          this.params.referralCode = this.referralCode
          this.loading = true
          this.$axios
            .post('/rental-forecasting/public/hubSpot/deal/create', this.params)
            .then(res => {
              if (res.data.errorCode === '0x0') {
                this.$message.success('Successful')
                this.dealId = res.data.result.dealId
                this.showConfirm = true
                // 拿到dealId之后上传pdf文件
                const name = res.data.result.dealId + '-' + moment().format('YYYYMMDDHHmmss')
                this.uploadPdf(name)
                // 清除已经选择的product
                Bus.$emit('clearProduct')
              } else {
                this.$message.error(res.data.errorInfo)
              }
              this.loading = false
            })
            .catch((error) => {
              this.loading = false
              this.codeActive = false
              this.$message.error(error)
            })
        } else {
          return false
        }
      })
    },
    getAllOwner () {
      this.$axios
        .get('/rental-forecasting/public/hubSpot/getAllOption')
        .then(res => {
          this.optionsInfo = res.data.result
          this.otherProductId = res.data.result.otherProductId
          localStorage.setItem('otherProductId', this.otherProductId)
          this.accessoryList = res.data.result.accessoryList
          // this.contactStateList = res.data.result.contactStateList
          this.deliveryServiceList = res.data.result.deliveryService
          this.locationList = res.data.result.locationList
        })
        .catch((error) => {
          this.$message.error(error)
        })
    },
    userView () {
      // 记录用户访问次数的接口
      this.$axios
        .get('/rental-forecasting/public/hubSpot/accessRRF')
        .then(res => {
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }
}
</script>

<style scoped lang="less">
.index {
  width: 100%;
  min-height: 100vh;
  background: #F4F8FA;
  padding: 30px 0;

  .form-rental {
    background: #FFF;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.22);
    width: 720px;
    margin: 0 auto;
    //padding: 40px;

    /deep/ .el-form-item {
      margin-right: 20px;

      .el-input {
        .el-input__inner {
          border-radius: 4px;
          background: #F6F6F6;
          border-color: #F6F6F6;
          //width: 300px;
          color: #333333;
        }

        .el-input__inner::placeholder {
          color: #cccccc;
        }
      }

      .event-input .el-input__inner, .event-input .el-textarea__inner {
        background: #F6F6F6;
        border-color: #F6F6F6;
        width: 620px;
      }
    }

    /deep/ .el-date-editor {
      width: 620px;
      background: #F6F6F6;
      border-color: #F6F6F6;

      .el-range-editor .el-range-input {
        color: #333333;
      }

      .el-range-separator {
        color: #444444;
      }
    }

    .title-first {
      color: #000000;
      font-weight: 500;
      font-size: 20px;
      margin-bottom: 20px;
    }

    .title {
      width: 50%;
      display: inline-block;
      color: #000000;
      font-weight: 500;
      font-size: 14px;
      margin-bottom: 10px;

      .red {
        color: #F00;
      }
    }

    /deep/ .el-button--primary {
      background: #69AD48;
      border-color: #69AD48;
    }

    /deep/ .el-select .el-input .el-select__caret {
      color: #999999;
      font-weight: 700;
    }

    .error {
      color: #F56C6C;
      font-size: 12px;
      line-height: 12px;
      margin-top: 2px;
    }
  }

  .header-box {
    padding: 15px 40px;
    .header {
      cursor: pointer;
      display: flex;
      align-items: center;
      span {
        color: #333333;
        margin-left: 10px;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
}

.flex-center {
  display: flex;

  /deep/ .el-input__inner {
    width: 300px;
  }
}

/deep/ .el-checkbox__inner {
  background: #FFFFFF !important;
}

/deep/ .el-checkbox .el-checkbox__input.is-checked .el-checkbox__inner {
  background: #0C6 !important;
}

.product-form {
  /deep/ .el-input__inner {
    width: 180px;
    background-color: #FFFFFF;
    color: #333333;
  }
}

.agree-content {
  display: flex;
  color: #000;
  font-size: 15px;
  justify-content: center;
  padding: 20px 0;

  span {
    margin-left: 10px;
  }
}

.name-code {
  color: #33AB4F;
  margin-left: 4px;
}

.product-content {
  background-color: #F6F6F6;
  padding: 20px 10px;
  color: #333333;
  font-size: 14px;
  margin-bottom: 20px;

  div {
    margin-bottom: 10px;
  }

  .name {
    margin-right: 20px;
    display: inline-block;
    width: 270px;
  }

  .price {
    margin: 0 35px;
  }

  .el-icon-delete {
    cursor: pointer;
    font-weight: 600;
  }

  .el-icon-delete:hover {
    color: #33AB4F;
  }
}

@media (max-width: 760px) {
  .index {
    padding: 10px 0;

    .form-rental {
      width: 90%;
      padding: 20px;

      /deep/ .el-date-editor {
        width: 100%;
      }
    }
  }

  .flex-center {
    display: block;

    /deep/ .el-input__inner, /deep/ .el-select {
      width: 100%;
    }
  }

  .product-content {
    .name {
      margin-bottom: 2px;
    }

    .price {
      margin: 0 12px;
    }
  }
}
/deep/.el-form {
  background: #FFFFFF;
  padding: 40px;
}
</style>
