<template>
<div class="confirm-address" ref="pdfAddress">
  <el-form
      :rules="rules"
      ref="formRental"
      class="form-rental"
      size="medium"
      :model="formRental">
    <div class="form-box">
      <div class="title">Business Contacts Information</div>
      <div class="flex-center">
        <el-form-item prop="businessContactFirstName" label="First Name">
          <el-input @change="sameChecked" v-model="formRental.businessContactFirstName"></el-input>
        </el-form-item>
        <el-form-item prop="businessContactLastName" label="Last Name">
          <el-input @change="sameChecked" v-model="formRental.businessContactLastName"></el-input>
        </el-form-item>
      </div>
      <div class="flex-center">
        <el-form-item prop="businessContactCompany" label="Company Name">
          <el-input @change="sameChecked" v-model="formRental.businessContactCompany"></el-input>
        </el-form-item>
        <el-form-item prop="businessContactEmail" label="Email Address">
          <el-input @change="sameChecked" v-model="formRental.businessContactEmail"></el-input>
        </el-form-item>
      </div>
      <div class="flex-center">
        <el-form-item prop="businessContactPhone" label="Mobile Phone Number">
          <el-input @change="sameChecked" v-model="formRental.businessContactPhone"></el-input>
        </el-form-item>
        <el-form-item label="Other Communication Methods">
          <el-select
              style="width: 40%;"
              clearable
              @change="sameChecked"
              v-model="formRental.businessContactCommunicationType"
              placeholder="Select">
            <el-option
                v-for="(item, index) in communicationTypeList"
                :key="index"
                :label="item"
                :value="item"></el-option>
          </el-select>
          <el-input class="otherInput" prop="businessOtherCommunicationMethods" @change="sameChecked" v-model="formRental.businessOtherCommunicationMethods"></el-input>
        </el-form-item>
      </div>
      <div class="flex-center">
        <el-form-item prop="businessContactCountry" label="Country/Region">
          <el-select
              clearable
              filterable
              @change="sameChecked('busRegion')"
              v-model="formRental.businessContactCountry"
              placeholder="Country/Region">
            <el-option
                v-for="(item, index) in locationList"
                :key="index"
                :label="item.country"
                :value="item.country"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="businessContactState" label="State/Province">
          <el-select
              clearable
              filterable
              @change="sameChecked"
              v-model="formRental.businessContactState"
              placeholder="State/Province">
            <el-option
                v-for="(item, index) in businessStateList"
                :key="index"
                :label="item"
                :value="item"></el-option>
          </el-select>
        </el-form-item>
      </div>
    </div>
    <div class="line"></div>
    <div class="form-box">
      <div style="display: flex; justify-content: space-between;">
        <div class="title">Shipping Contacts information</div>
        <span style="font-weight: normal; font-size: 14px;">
          <el-checkbox v-model="checked" @change="sameChecked"></el-checkbox>
          <span style="margin-left: 5px;color: #000000;">Same as business Contact</span>
        </span>
      </div>
      <div class="flex-center">
        <el-form-item prop="shippingContactFirstName" label="First Name">
          <el-input :disabled="!canInput" v-model="formRental.shippingContactFirstName"></el-input>
        </el-form-item>
        <el-form-item prop="shippingContactLastName" label="Last Name">
          <el-input :disabled="!canInput" v-model="formRental.shippingContactLastName"></el-input>
        </el-form-item>
      </div>
      <div class="flex-center">
        <el-form-item prop="shippingContactCompany" label="Company Name">
          <el-input :disabled="!canInput" v-model="formRental.shippingContactCompany"></el-input>
        </el-form-item>
        <el-form-item prop="shippingContactEmail" label="Email Address">
          <el-input :disabled="!canInput" v-model="formRental.shippingContactEmail"></el-input>
        </el-form-item>
      </div>
      <div class="flex-center">
        <el-form-item prop="shippingContactPhone" label="Mobile Phone Number">
          <el-input :disabled="!canInput" v-model="formRental.shippingContactPhone"></el-input>
        </el-form-item>
        <el-form-item label="Other Communication Methods">
          <el-select
              style="width: 40%;"
              clearable
              v-model="formRental.shippingContactCommunicationType"
              placeholder="Select">
            <el-option
                v-for="(item, index) in communicationTypeList"
                :key="index"
                :label="item"
                :value="item"></el-option>
          </el-select>
          <el-input class="otherInput" prop="shippingOtherCommunicationMethods" :disabled="!canInput" v-model="formRental.shippingOtherCommunicationMethods"></el-input>
        </el-form-item>
      </div>
      <div class="title-shipping"><span class="red">*</span>Shipping Address</div>
      <el-form-item prop="shippingAddress1">
        <el-input v-model="formRental.shippingAddress1" placeholder="Shipping Address Line 1(Optional)"></el-input>
      </el-form-item>
      <div class="flex-center">
        <el-form-item prop="shippingAddress2">
          <el-input v-model="formRental.shippingAddress2" placeholder="Shipping Address Line 2(Optional)"></el-input>
        </el-form-item>
        <el-form-item prop="buildingName">
          <el-input v-model="formRental.buildingName" placeholder="Building Name(Optional)"></el-input>
        </el-form-item>
      </div>
      <div class="flex-center">
        <el-form-item prop="shippingCountry">
          <el-select
              style="width: 100%;"
              clearable
              filterable
              v-model="formRental.shippingCountry"
              @change="shippingCountryChange"
              placeholder="Country/Region">
            <el-option
                v-for="(item, index) in locationList"
                :key="index"
                :label="item.country"
                :value="item.country"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="shippingState">
          <el-select
              clearable
              filterable
              v-model="formRental.shippingState"
              placeholder="State/Province(Optional)">
            <el-option
                v-for="(item, index) in deliveryStateList"
                :key="index"
                :label="item"
                :value="item"></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="flex-center">
        <el-form-item prop="shippingCity">
          <el-input v-model="formRental.shippingCity" placeholder="City"></el-input>
        </el-form-item>
        <el-form-item prop="shippingZipCode">
          <el-input v-model="formRental.shippingZipCode" placeholder="Postal Code(Optional)"></el-input>
        </el-form-item>
      </div>
    </div>
  </el-form>
  <div class="next-box">
    <div class="number-box">
      <span>Internal Number:</span>
      <el-input size="small" v-model="formRental.internalNumber" placeholder="Optional"></el-input>
    </div>
    <el-button size="small" type="primary" @click="onNext">Next</el-button>
  </div>
</div>
</template>

<script>
import { getUrlParam } from '@/assets/js/utils'
export default {
  name: 'ConfirmAddress',
  props: {
    locationList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      formRental: {
        // Business Contacts Information
        businessContactFirstName: '',
        businessContactLastName: '',
        businessContactCompany: '',
        businessContactEmail: '',
        businessContactPhone: '',
        businessContactCommunicationType: '',
        businessOtherCommunicationMethods: '',
        businessContactCountry: '',
        businessContactState: '',
        // Shipping Contacts information
        shippingContactFirstName: '',
        shippingContactLastName: '',
        shippingContactCompany: '',
        shippingContactEmail: '',
        shippingContactPhone: '',
        shippingOtherCommunicationMethods: '',
        shippingContactCommunicationType: '',
        // Shipping Address
        shippingAddress1: '',
        shippingAddress2: '',
        buildingName: '',
        shippingCountry: '',
        shippingState: '',
        shippingCity: '',
        shippingZipCode: '',
        internalNumber: ''
      },
      checked: false,
      canInput: true,
      businessStateList: [],
      deliveryStateList: [],
      communicationTypeList: ['WhatsApp', 'Skype', 'WeChat', 'Slack']
    }
  },
  computed: {
    rules () {
      return {
        businessContactFirstName: [
          { required: true, message: 'Please complete this required field.', trigger: 'blur' }
        ],
        businessContactLastName: [
          { required: true, message: 'Please complete this required field.', trigger: 'blur' }
        ],
        businessContactEmail: [
          { required: true, message: 'Please complete this required field.', trigger: 'blur' },
          { pattern: /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/, message: 'Invalid Email Formats!', trigger: 'blur' }
        ],
        businessContactPhone: [
          { required: true, message: 'Please complete this required field.', trigger: 'change' }
        ],
        businessContactCountry: [
          { required: true, message: 'Please complete this required field.', trigger: 'change' }
        ],
        shippingContactFirstName: [
          { required: true, message: 'Please complete this required field.', trigger: 'change' }
        ],
        shippingContactLastName: [
          { required: true, message: 'Please complete this required field.', trigger: 'change' }
        ],
        shippingContactEmail: [
          { required: true, message: 'Please complete this required field.', trigger: 'blur' },
          { pattern: /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/, message: 'Invalid Email Formats!', trigger: 'blur' }
        ],
        shippingContactPhone: [
          { required: true, message: 'Please complete this required field.', trigger: 'change' }
        ],
        // shippingAddress1: [
        //   { required: true, message: 'Please complete this required field.', trigger: 'change' }
        // ],
        // shippingAddress2: [
        //   { required: true, message: 'Please complete this required field.', trigger: 'change' }
        // ],
        // buildingName: [
        //   { required: true, message: 'Please complete this required field.', trigger: 'change' }
        // ],
        shippingCountry: [
          { required: true, message: 'Please complete this required field.', trigger: 'change' }
        ],
        // shippingState: [
        //   { required: true, message: 'Please complete this required field.', trigger: 'change' }
        // ],
        shippingCity: [
          { required: true, message: 'Please complete this required field.', trigger: 'blur' }
        ]
        // shippingZipCode: [
        //   { required: true, message: 'Please complete this required field.', trigger: 'blur' }
        // ]
      }
    }
  },
  mounted () {
    let confirmAddress
    let confirmAddressChecked = false
    if (this.$route.path === '/print') {
      confirmAddress = JSON.parse(getUrlParam('confirmAddress') || '{}')
      console.log(getUrlParam('confirmAddressChecked'))
      confirmAddressChecked = getUrlParam('confirmAddressChecked')
    } else {
      if (localStorage.getItem('confirmAddress')) {
        confirmAddress = JSON.parse(localStorage.getItem('confirmAddress'))
        confirmAddressChecked = localStorage.getItem('confirmAddressChecked')
      }
    }
    if (confirmAddress) {
      this.formRental = confirmAddress
      this.checked = confirmAddressChecked === 'true'
    }
  },
  methods: {
    onNext () {
      this.$refs.formRental.validate(valid => {
        if (valid) {
          localStorage.setItem('confirmAddress', JSON.stringify(this.formRental))
          localStorage.setItem('confirmAddressChecked', this.checked)
          this.$emit('addressNext', JSON.stringify(this.formRental), this.checked)
        } else {
          return false
        }
      })
    },
    shippingCountryChange () {
      const finItem = this.locationList.find(item => item.country === this.formRental.shippingCountry)
      this.deliveryStateList = finItem ? finItem.stateList : []
      this.formRental.shippingState = ''
    },
    sameChecked (type) {
      if (type === 'busRegion') {
        const finItem = this.locationList.find(item => item.country === this.formRental.businessContactCountry)
        this.businessStateList = finItem ? finItem.stateList : []
        this.formRental.businessContactState = ''
      }
      if (this.checked) {
        const {
          businessContactFirstName,
          businessContactLastName,
          businessContactCompany,
          businessContactEmail,
          businessContactPhone,
          businessOtherCommunicationMethods,
          businessContactCommunicationType
        } = this.formRental
        this.formRental.shippingContactFirstName = businessContactFirstName
        this.formRental.shippingContactLastName = businessContactLastName
        this.formRental.shippingContactCompany = businessContactCompany
        this.formRental.shippingContactEmail = businessContactEmail
        this.formRental.shippingContactPhone = businessContactPhone
        this.formRental.shippingOtherCommunicationMethods = businessOtherCommunicationMethods
        this.formRental.shippingContactCommunicationType = businessContactCommunicationType
        this.canInput = false
      } else {
        this.formRental.shippingContactFirstName = ''
        this.formRental.shippingContactLastName = ''
        this.formRental.shippingContactCompany = ''
        this.formRental.shippingContactState = ''
        this.formRental.shippingContactEmail = ''
        this.formRental.shippingContactPhone = ''
        this.formRental.shippingOtherCommunicationMethods = ''
        this.formRental.shippingContactCommunicationType = ''
        this.canInput = true
      }
    }
  }
}
</script>

<style scoped lang="less">
.form-rental {
  display: flex;
  margin-bottom: 16px;

  .form-box {
    width: 538px;
    border-radius: 4px;
    border: 2px solid #DBDBDB;
    background: #FAFAFA;
    padding: 24px 12px 8px 12px;
    .title {
      color: #69AD48;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 18px;
    }
  }

  .line {
    border-right: dashed 2px #DBDBDB;
    height: 536px;
    margin: 0 24px;
  }

  .title-shipping {
    font-size: 14px;
    color: #000000;
    margin: 6px 0;
    .red {
      color: #EC5050;
    }
  }

  /deep/.el-input__inner {
    border-color: #DBDBDB;
    background: #FAFAFA!important;
  }

  .flex-center {
    display: flex;
    /deep/.el-input__inner {
      width: 247px;
      border-color: #DBDBDB;
      background: #FAFAFA!important;
    }
    /deep/.el-input__inner::placeholder {
      color: #DBDBDB;
    }
    :nth-child(2).el-form-item {
      margin-left: 16px;
    }
  }
  .otherInput {
    width: 55%;
    /deep/.el-input__inner {
      width: 150px;
    }
  }
  .marginL-16 {
    margin-left: 16px;
  }
  .el-form-item {
    margin-bottom: 16px;
  }
  /deep/.el-select .el-input.is-focus .el-input__inner {
    border-color: #DBDBDB;
  }
}

.next-box {
  display: flex;
  justify-content: flex-end;
}
.number-box {
  display: flex;
  align-items: center;
  margin-right: 24px;
  color: #000000;
  font-size: 14px;
  span {
    margin-right: 6px;
  }
  /deep/.el-input {
    width: 160px;
  }
}
</style>
