<template>
<div class="edit-event-info" ref="pdfEvent">
  <el-form
      :rules="rules"
      ref="eventForm"
      class="product-form"
      size="medium"
      :model="eventForm">
    <el-form-item prop="formDate" label="Receive and Return Date" class="date-item">
      <el-date-picker
          style="width: 100%;"
          v-model="eventForm.formDate"
          :picker-options="pickerOptions"
          type="daterange"
          :editable="false"
          range-separator=""
          start-placeholder="Receive and Return Date"
          end-placeholder="">
      </el-date-picker>
    </el-form-item>
    <el-form-item prop="redBullEventName" label="Red Bull Event Name">
      <el-select
          style="width: 100%;"
          clearable
          v-model="eventForm.redBullEventName"
          @change="redBullEventNameChange"
          placeholder="Delivery Service">
        <el-option
            v-for="(item, index) in redBullEventNameList"
            :key="index"
            :label="item"
            :value="item"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item prop="eventInformation" label="Please Specify" v-if="eventForm.redBullEventName == 'Other'">
      <el-input v-model="eventForm.eventInformation" placeholder="Please Specify Event Name"></el-input>
    </el-form-item>
    <el-form-item class="text_right">
      <el-button type="primary" @click="onNext">Next</el-button>
    </el-form-item>
  </el-form>
</div>
</template>

<script>
import { getUrlParam } from '@/assets/js/utils'
const moment = require('moment')

export default {
  name: 'EditEventInfo',
  props: {
    redBullEventNameList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      eventForm: {
        formDate: [],
        redBullEventName: '',
        eventInformation: ''
      },
      rules: {
        formDate: [
          { type: 'array', required: true, message: 'Please complete this required field.', trigger: 'change' }
        ],
        redBullEventName: [
          { required: true, message: 'Please complete this required field.', trigger: 'change' }
        ],
        eventInformation: [
          { required: true, message: 'Please complete this required field.', trigger: 'change' }
        ]
      },
      pickerOptions: {
        disabledDate (time) {
          // 获取今天凌晨的时间戳
          // const today = new Date(new Date().toLocaleDateString()).getTime()
          const today = moment().startOf('day').valueOf()
          return time.getTime() < today
        }
      }
    }
  },
  mounted () {
    let eventInfo
    if (this.$route.path === '/print') {
      eventInfo = JSON.parse(getUrlParam('eventInfo') || '{}')
    } else {
      if (localStorage.getItem('eventInfo')) {
        eventInfo = JSON.parse(localStorage.getItem('eventInfo'))
      }
    }
    if (eventInfo) {
      this.eventForm.formDate = [eventInfo.startDate, eventInfo.endDate]
      this.eventForm.redBullEventName = eventInfo.redBullEventName
      this.eventForm.eventInformation = eventInfo.eventInformation
    }
  },
  methods: {
    onNext () {
      this.$refs.eventForm.validate(valid => {
        if (valid) {
          const start = moment(this.eventForm.formDate[0]).format('YYYY-MM-DD')
          const end = moment(this.eventForm.formDate[1]).format('YYYY-MM-DD')
          const params = { ...this.eventForm }
          params.startDate = start
          params.endDate = end
          delete params.formDate
          // 第一步的数据存储
          localStorage.setItem('eventInfo', JSON.stringify(params))
          this.$emit('eventNext')
        } else {
          return false
        }
      })
    },
    redBullEventNameChange () {
      this.eventForm.eventInformation = ''
    }
  }
}
</script>

<style scoped lang="less">
.edit-event-info {
  background: #FAFAFA;
  border: solid 2px #DBDBDB;
  border-radius: 6px;
  .product-form {
    padding-top: 36px;
    width: 504px;
    margin: 0 auto;
  }
  /deep/.el-form-item {
    margin-bottom: 29px;
  }
}
</style>
