<template>
<div class="device-left">
  <div class="with-ticket">
    <div class="title">Physical Assets</div>
    <el-radio
        v-for="option in radioOptions"
        :key="option.label"
        @change="withTicketChange"
        v-model="withTicket"
        :label="option.value"
    >
      {{ option.label }}
    </el-radio>
  </div>
  <div class="assets-title"></div>
  <div class="item-box" ref="itemBox">
    <div
        class="assets-item"
        :class="item.activeDevice ? 'assets-active' : ''"
        v-for="item in assetsData"
        :key="item.peerId + item.assetId">
      <div class="device-info">
        <img v-if="item.type == 'T'" src="../../assets/images/T_device.png" width="32" alt="">
        <img v-if="item.type == 'R'" src="../../assets/images/R_device.png" width="32" alt="">
        <div>
          <p class="device-peerId" @click="goHubspot(item.assetId)">{{item.peerId.toUpperCase()}}</p>
          <p class="device-name">{{item.peerName}}</p>
        </div>
      </div>
      <el-tooltip
          v-if="item.ticketId"
          class="item"
          effect="dark"
          content="Devices with the same ticket"
          placement="top">
        <div
            @click="deviceChange(item)"
            :class="item.activeDevice ? 'device-active' : ''"
            class="device-num">
          <span>{{item.ticketAssetCount}}</span>
          <img src="../../assets/images/Link.png" alt="" width="24">
        </div>
      </el-tooltip>
    </div>
  </div>
</div>
</template>

<script>
import Bus from '@/common/vueBus'

export default {
  name: 'DeviceLeft',
  props: {
    assetsData: Array
  },
  data () {
    return {
      peerId: '',
      withTicket: '0',
      radioOptions: [
        { label: 'All', value: '0' },
        { label: 'With ticket', value: '1' },
        { label: 'Without ticket', value: '2' }
      ]
    }
  },
  created () {
    this.peerId = localStorage.getItem('peerId')
    const findItem = this.assetsData.find(item => item.peerId === this.peerId)
    if (findItem) {
      this.assetsData.forEach(item => {
        item.activeDevice = true
      })
      // this.$set(findItem, 'activeDevice', !findItem.activeDevice)
      localStorage.setItem('peerId', null)
    }
  },
  mounted () {
    Bus.$on('scrollChanges', val => {
      if (this.$refs.itemBox) {
        this.$refs.itemBox.scrollTop = val
      }
    })
    this.withTicket = localStorage.getItem('withTicket')
    // const withTicket = localStorage.getItem('withTicket')
    // if (withTicket === 'true') {
    //   this.withTicket = true
    // } else if (withTicket === 'false') {
    //   this.withTicket = false
    // } else {
    //   this.withTicket = ''
    // }
    this.addClickEventListenerToCalendarHeader()
  },
  methods: {
    addClickEventListenerToCalendarHeader () {
      this.$nextTick(() => {
        // 设置左右滚动条
        const elementScroll = document.getElementsByClassName('item-box')[0]
        elementScroll.onscroll = function () {
          Bus.$emit('scrollChangesToRight', elementScroll.scrollTop)
        }
      })
    },
    withTicketChange () {
      localStorage.setItem('withTicket', this.withTicket)
      Bus.$emit('ticketChange', this.withTicket)
    },
    deviceChange (item) {
      if (item.activeDevice) {
        this.backAssets()
        return
      }
      if (!item.ticketId) return
      localStorage.setItem('peerId', item.peerId)
      Bus.$emit('changesSearch', { ticketId: item.ticketId })
    },
    backAssets () {
      localStorage.setItem('peerId', null)
      Bus.$emit('changesSearch', { ticketId: '' })
    },
    goHubspot (id) {
      const url = `${JSON.parse(localStorage.getItem('linkMap')).asset}${id}`
      window.open(url)
    }
  }
}
</script>

<style scoped lang="less">
.device-left {
  width: 324px;
  background-color: #333333;
  border-radius: 4px 0px 0px 4px;
  padding: 10px 12px 0 12px;
  h4 {
    font-size: 16px;
    padding: 16px 0;
  }
}
.assets-title {
  border-bottom: 2px solid #444;
  padding-top: 12px;
}
.item-box {
  height: calc(100vh - 210px);
  overflow-y: scroll;
  padding-bottom: 120px;
}
.item-box::-webkit-scrollbar {
  display: none;
}
.assets-item {
  height: 54px;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px dashed #444;
  padding: 8px 6px;
  margin-top: 6px;
  .device-info, .device-num {
    display: flex;
    align-items: center;
  }
  .device-info {
    img {
      margin-right: 12px;
    }
    .device-name {
      color: #999;
      margin-top: 4px;
    }
    .device-peerId {
      cursor: pointer;
      text-decoration: underline;
    }
    .device-peerId:hover {
      color: #33AB4F;
    }
  }
  .device-num {
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid #666;
    background: #444;
    height: 24px;
    line-height: 24px;
    padding: 0 6px;
    img {
      margin-left: 4px;
    }
  }
  .device-active {
    border-radius: 4px;
    border: 1px solid var(--gray-666666, #666);
    background: var(--glass-green, #69AD48);
  }
}
.assets-item:hover {
  background-color: #444444;
}
.assets-active {
  background-color: #3F5D46!important;
}
.with-ticket {
  z-index: 9;
  //position: absolute;
  margin-left: 6px;
  margin-top: 32px;
  .title {
    color: #CCC;
    font-size: 16px;
    font-weight: 700;
    margin: 16px 0;
  }
  /deep/.el-radio {
    margin-right: 18px;
    .el-radio__label {
      padding-left: 2px;
    }
  }
  /deep/.el-checkbox__inner {
    width: 16px;
    height: 16px;
  }
  /deep/.el-checkbox .el-checkbox__inner::after {
    border-color: #000000!important;
    left: 5px;
  }
}
</style>
